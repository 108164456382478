import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NavController, ToastController } from "@ionic/angular";
import { IUser } from "src/app/interfaces/user.interface";
import { IUserlogin } from "src/app/interfaces/userlogin.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { ValidationService } from "src/app/services/validation.service";
import { delay, objectifyQueryParams } from "src/app/util/util";

const AUTOFILL_TEST_DATA = true;

@Component({
  selector: "registration",
  templateUrl: "registration.page.html",
  styleUrls: ["registration.page.scss"],
})
export class RegistrationPage implements OnInit {
  partiallyRegisteredUser: IUser;
  public regForm = this.fb.group({
    firstname: ["", Validators.required],
    lastname: ["", Validators.required],
    email: [
      "",
      {
        validators: [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), // Validator.email allows a@b
        ],
        asyncValidators: [this.validationService.emailAvailability()],
        updateOn: "change",
      },
    ],
    phone: [
      "",
      [
        Validators.required,
        Validators.pattern(/\d{10}/),
        Validators.maxLength(10),
      ],
      // only execute the validator IF the number is input... though it may need to simply be enforced
      // (value) => (value ? Validators.pattern(/^\d{10}$/)(value) : null),
    ],
    brokerage: [""],
    password: ["", [Validators.required]],
    tier: ["LAUNCH", [Validators.required]],
    coupon: [null],
    // not sure if i want these here.. but it works
    account_lead: [true, [Validators.required]],
    brokerage_code: [null],
  });
  public stripe: stripe.Stripe;
  public card: stripe.elements.Element;
  public validCC = false;
  public loading = false;
  public user: IUser;
  public complete = false;
  public changingActivationEmail = false;
  public sendingActivationEmail = false;
  public changeEmailForm = this.fb.group({
    email: [
      "",
      {
        validators: [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), // Validator.email allows a@b
        ],
        asyncValidators: [this.validationService.emailAvailability()],
        updateOn: "change",
      },
    ],
  });

  constructor(
    public fb: FormBuilder,
    public validationService: ValidationService,
    public auth: AuthService,
    public userService: UserAdminService,
    public toastCtl: ToastController,
    public nav: NavController
  ) {}

  async ngOnInit() {
    // setup the radio
    await delay(50); // needed for initial label mount
    $(`label[for="launchopt"]`).toggleClass("active");
    $("#paymentopts label").on("click", function () {
      $("label.active").removeClass("active");
      $(this).addClass("active");
    });
    await this.mountCardInput();

    this.regForm.get("email").valueChanges.subscribe((email) => {
      this.regForm
        .get("email")
        .setValue(email.toLowerCase(), { emitEvent: false });
    });
    this.changeEmailForm.get("email").valueChanges.subscribe((email) => {
      this.changeEmailForm
        .get("email")
        .setValue(email.toLowerCase(), { emitEvent: false });
    });

    const { id } = objectifyQueryParams();
    if (id) {
      await this.loadUser(id);
    } else if (
      !window.location.href.includes("valuclick.co") &&
      AUTOFILL_TEST_DATA
    ) {
      const label = "Dev";
      const idx = Math.round(new Date().getTime() / 1000);
      this.regForm.patchValue({
        firstname: label,
        lastname: `${idx}`, // must be a string
        email: `${label}-${idx}@jacobscreative.io`,
        phone: "9513159860",
        brokerage: `${label} ${idx} Inc.`,
        password: "Password123!",
      });
      // this.regForm.valueChanges.subscribe((v) => console.log(v));
    }
  }

  private async showToast(message) {
    const toast = await this.toastCtl.create({
      message,
      duration: 3000,
      position: "top",
    });
    toast.present();
  }

  // ---------- Page 1
  async loadUser(id) {
    try {
      this.partiallyRegisteredUser = await this.userService
        .getUserInvite(id, null)
        .toPromise();
      this.regForm.get("email").clearAsyncValidators();
      $('.vc-input-v2 input[formControlName="email"]').prop("disabled", true);
      this.regForm.patchValue({
        firstname: this.partiallyRegisteredUser.firstname,
        lastname: this.partiallyRegisteredUser.lastname,
        email: this.partiallyRegisteredUser.local.email,
        phone: this.partiallyRegisteredUser.profile.phone,
        brokerage: this.partiallyRegisteredUser.profile.brokerage,
      });
      if (!this.partiallyRegisteredUser.requireCreditCard) {
        $("#paymentplan").remove();
        $("#regform").css({
          gridTemplateColumns: ".5fr",
          justifyContent: "center",
        });
      }
    } catch (e) {
      console.error("Error loading registration:", e);
      await this.showToast("Error loading registration");
    }
  }

  async mountCardInput() {
    this.stripe = Stripe(
      await this.auth
        .getStripeKey()
        .toPromise()
        .then(({ key }) => key)
    );
    this.card = this.stripe.elements().create("card", {
      style: {
        base: {
          fontSize: "16px",
          backgroundColor: "white",
          "::placeholder": {
            fontSize: "14px",
            color: "#777",
          },
        },
      },
    });
    this.card.mount("#cc");

    this.card.on("change", (e) => {
      this.validCC = e.complete;
      // display errors
      const errorCtr = $("#cc-errors");
      if (e.error) {
        errorCtr.text(e.error.message);
        errorCtr.addClass("active");
      } else {
        errorCtr.text("");
        errorCtr.removeClass("active");
      }
    });
  }

  public togglePassword() {
    const type = $("#passwordInput").attr("type");
    $("#passwordInput").attr("type", type === "password" ? "text" : "password");
  }

  get submitFormValid() {
    if (this.loading) {
      return false;
    } else if (
      this.partiallyRegisteredUser &&
      !this.partiallyRegisteredUser.requireCreditCard
    ) {
      return this.regForm.valid;
    }
    return this.regForm.valid && this.validCC;
  }

  async handleSubmit() {
    // register user
    if (!this.user) {
      await this.register();
      console.log("--- this.user = ", this.user);
      if (
        this.user &&
        !this.user.inactive &&
        !this.partiallyRegisteredUser.requireCreditCard
      ) {
        await this.showToast("Congratulations! Welcome to Valuclick.");
        this.nav.navigateRoot("/home");
        return;
      }
    }
    // save payment
    this.complete = await this.savePayment(this.user._id);
    if (this.complete && this.user.inactive) {
      await this.sendActivationEmail();
    } else if (this.complete && !this.user.inactive) {
      await this.showToast("Cogratulations! Welcome to Valuclick.");
      this.nav.navigateRoot("/home");
    }
  }

  async register() {
    this.loading = true;
    try {
      const { user } = await this.auth
        .signUp(
          this.regForm.value.email,
          this.regForm.value.password,
          this.regForm.value.firstname,
          this.regForm.value.lastname,
          this.regForm.value.phone,
          this.regForm.value.brokerage,
          this.regForm.value.tier,
          this.regForm.value.brokerage_code,
          this.regForm.value.account_lead,
          this.regForm.value.coupon,
          !!this.partiallyRegisteredUser
        )
        .toPromise();
      this.user = user;
    } catch (e) {
      console.error("Error registering user: ", e);
      alert("Error registering user.");
    }
    this.loading = false;
  }

  async savePayment(id: string) {
    this.loading = true;
    let success = false;
    try {
      const intent = await this.userService.getStripeIntent(id);
      const res = await this.stripe.confirmCardSetup(intent.client_secret, {
        payment_method: {
          card: this.card,
        },
      });
      if (!res.setupIntent) {
        alert(
          "Error setting up card. Please review credit card input and try again."
        );
      } else {
        // await this.userService.setSub(this.user._id, this.regForm.value.teir);
        await this.userService.syncPayment(this.user);
        success = true;
      }
    } catch (e) {
      console.error("Error saving payment: ", e);
      alert("Error saving payment.");
    }
    this.loading = false;
    return success;
  }

  async sendActivationEmail() {
    this.sendingActivationEmail = true;
    try {
      await this.auth.sendActivationEmail_2(this.regForm.value.email);
      this.changeEmailForm.setValue({ email: this.regForm.value.email });
      await this.showToast("Successfully sent activation email");
    } catch (e) {
      console.log("Error sending activation email: ", e);
      await this.showToast("Error sending activation email");
    }
    this.sendingActivationEmail = false;
  }

  get buttonText() {
    let text = "Submit";
    if (!this.regForm.valid || !this.validCC) {
      text = "Complete Form To Continue";
    } else if (this.loading) {
      text = "Submitting...";
    }
    return text;
  }

  // ---------- Page 2
  // note - starts to get messy here
  // also note - we should still include a way to type in activation link

  toggleActivationEmailInput() {
    // if we're canceling a change, reset the form val
    if (this.changingActivationEmail) {
      this.changeEmailForm.setValue({ email: this.regForm.value.email });
    }
    this.changingActivationEmail = !this.changingActivationEmail;
  }

  async sendEmailChange() {
    this.sendingActivationEmail = true;
    try {
      const user = await this.auth.updateActivationEmail(
        this.regForm.value.email,
        this.changeEmailForm.value.email
      );
      this.regForm.patchValue({ email: user.local.email });
      this.toggleActivationEmailInput();
    } catch (e) {
      console.error("Error ", e);
      alert("Error changing email");
    }
    $("#submit-email-change").prop("disabled", false);
    $("#cancel-email-change").prop("disabled", false);
    this.sendingActivationEmail = false;
  }
}
