import { Component, NgModule, OnInit } from "@angular/core";
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { LoginPage } from "../login/login";
import { HomePage } from "../home/home";
import { AuthService } from "../../services/auth.service";
import { AppService } from "../../services/appservice.service";
import { RegistrationCompleteModalComponent } from "../modal/registration-complete-modal/registration-complete-modal.component";
import { environment } from '../../../environments/environment';
import { UserAdminService } from "src/app/services/user.service";
import { VideoModal } from "../video-modal/video-modal";
import { AppInfoService } from "src/app/services/appinfo.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IUser } from "src/app/interfaces/user.interface";
import { objectifyQueryParams } from "src/app/util/util";
import { TermsOfUseModal } from "../modal/terms-of-use-modal/terms-of-use-modal.component";
import { StripeCheckoutModal } from "../stripe-checkout-modal/stripe-checkout-modal";
import * as moment from "moment";

declare var SqPaymentForm: any; //magic to allow us to access the SquarePaymentForm lib

@Component({
  selector: 'page-register',
  templateUrl: 'register.html',
  styleUrls: ['./register.scss']
})
export class RegisterPage implements OnInit {

  public mail: string;
  public password: string;
  public firstname: string;
  public lastname: string;
  public address1: string;
  public city: string;
  public state: string;
  public zip: string;
  public appservices = [];
  public serviceURL = "";
  public paymentForm: any;
  public videoDone = false;

  public phone: string;
  public brokerage: string;
  public tier: string;
  public brokerage_code: string;
  public account_lead: boolean = true;
  // public cardErrors: Boolean = false;
  private modalCtrl: any;

  private completingInvite = false

  // when a team member registers they will usually have a url with the code stored
  private loadedTeamLead: IUser | boolean;

  private videoModalCtrl: any;
  private welcomeVideo: SafeResourceUrl;

  private tiers = "BETA";

  constructor(public nav: NavController,
    public auth: AuthService,
    public toastCtrl: ToastController,
    public modalController: ModalController,
    private readonly appService: AppService,
    private readonly userService: UserAdminService,
    private readonly appInfoService: AppInfoService,
    private sanitizer: DomSanitizer,
    public loadingController: LoadingController
  ) { }

  /*trustedVideo() {
    if (this.welcomeVideo) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.welcomeVideo);
    } else {
      return undefined;
    }
  }*/

  ngOnInit() {
    // TESTING
    const date = moment().format('YYYY-MM-DD_HH-mm-ss')
    this.firstname = date
    this.lastname = 'jacobs'
    this.mail = `${date}@jacobscreative.io`
    this.phone = '123456789'
    this.brokerage = `${date} brokerage`
    this.password = 'password123!'
    this.tier = 'BETA'

    // removing the square payment form for stripe registration
    // this.initPaymenForm();

    // couple states this page can be viewed from
    // 1. User from homepage clicks one of the subscription options, deep links here (?tier={TIER_NAME})
    // 2. User starts on portal and clicks the register button. Totally fresh, default BETA
    // 3. User clicks team invite URL and is signing up as a team member under the brokerage
    const params = objectifyQueryParams()

    if (params.id) {
      console.log("User completing invite")
      this.loadInvitedUser(params.id, params.code).then(() => console.log("... invite loaded"))
    } else if (params.code) {
      // doubt this case will occur often
      console.log("Team member is signing up from link: ", params.code)
      const linkedCode = params.code
      this.loadInviteByUrl(linkedCode).then(() => console.log("... invite loaded"))
    } else {
      console.log("Subscription from the start")
      this.loadedTeamLead = false
    }

    /**
     * STRIPE STUFF
     */
    this.testLoadElements()

  }

  async testLoadElements() {
    // const appearance = {
    //   theme: 'night',
    //   variables: {
    //     fontFamily: 'Sohne, system-ui, sans-serif',
    //     fontWeightNormal: '500',
    //     borderRadius: '8px',
    //     colorBackground: '#0A2540',
    //     colorPrimary: '#EFC078',
    //     colorPrimaryText: '#1A1B25',
    //     colorText: 'white',
    //     colorTextSecondary: 'white',
    //     colorTextPlaceholder: '#727F96',
    //     colorIconTab: 'white',
    //     colorLogo: 'dark'
    //   },
    //   rules: {
    //     '.Input, .Block': {
    //       backgroundColor: 'transparent',
    //       border: '1.5px solid var(--colorPrimary)'
    //     }
    //   }
    // };
    // const appearance = { theme: 'stripe' };

    // // const res = 
    // const signup = await this.auth.signUp(this.mail, this.password, this.firstname, this.lastname, this.phone, this.brokerage, this.tier, this.brokerage_code, this.account_lead).toPromise()
    // console.log("SIGNUP = ", signup)

    // const stripe = Stripe('pk_test_51LHbtMHrUT3IMFTDK8igxjTFw7BjOTBVKFQDQJCiUgqPkZbMRTLGUVAtUDPw14Aj3px9cotLEw5SymMC5OQY9ayU00T6EtnYyX')
    // console.log("STRIPE IS: ", Stripe)
    // const options = {
    //   // clientSecret: '{{CLIENT_SECRET}}',
    //   // clientSecret: 'pi_1Dse7m2eZvKYlo2CWvjx6ZiW_secret_esTgCEMn9x2eAU5iRqdSsLnkc',
    //   // Fully customizable with appearance API.
    //   clientSecret: signup.user.subscription.clientSecret,
    //   appearance
    // };

    // // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
    // const elements = stripe.elements(options);

    // const paymentElement = elements.create('payment');
    // paymentElement.mount('#payment-element');

    // // let card = elements.create('card', { style: style });
    // // card.mount('#card-element');

  }

  /**
   * Team Leaders can invite users to their brokerage.
   * This creates a partially created user, and the user's ID is included in the query params.
   * This will prefill the data from the invitation, allowing the user to overwrite any potential mistakes.
   */
  async loadInvitedUser(id: string, code: string) {
    this.completingInvite = true
    const loadingCtl = await this.loadingController.create({})
    loadingCtl.present()

    try {
      // probably more effective way to do this.. but good enough
      const tlList = await this.userService.findByBrokerageCode(code).toPromise()
      const tl = tlList.find(u => u.account_lead)
      this.loadedTeamLead = tl

      const user = await this.userService.getUserInvite(id, code).toPromise()
      console.log("LOADED USER: ", user)
      this.firstname = user.firstname
      this.lastname = user.lastname
      this.mail = user.local.email
      this.phone = user.profile.phone
      this.brokerage = user.profile.brokerage
      this.brokerage_code = user.brokerage_code
      this.tier = user.tier
      this.account_lead = false
    } catch (e) {
      console.log("Error loading invite: ", e)
    }

    loadingCtl.dismiss()
  }

  /**
   * Possible @deprecated
   * Before we offered invite subuser by email functionality we displayed the URL
   * that would allow a user to sign up to a specific brokerage.
   */
  async loadInviteByUrl(code: string) {
    try {
      const tlList = await this.userService.findByBrokerageCode(code).toPromise();
      console.log("-- TL LIST: ", tlList)
      const tl = tlList[0]
      console.log("TEAM LEAD IS: ", tl)
      this.loadedTeamLead = tl;
      this.brokerage = tl.profile.brokerage
      this.tier = tl.tier;
      this.brokerage_code = code
      this.account_lead = false
    } catch (err) {
      console.log("ERROR IS: ", err)
    }
  }

  // register and go to home page
  register() {

    if (this.serviceURL && this.serviceURL.length) {
      this.saveService(this.serviceURL);
    } else {
      this.saveService('http://localhost:9000/api/'); // hack for localhost
    }

    // removing the square payment form for stripe registration
    // this.paymentForm.requestCardNonce();

    /*this.auth.signUp(this.mail, this.password, this.firstname, this.lastname).subscribe(
      (loggedin: Boolean) => {
        if (loggedin){
          this.nav.navigateRoot('home');
        } else {
          this.nav.setRoot(LoginPage);
        }
    });*/

    this.auth.signUp(this.mail, this.password, this.firstname, this.lastname, this.phone, this.brokerage, this.tier, this.brokerage_code, this.account_lead, '')
      .subscribe(
        async (loggedIn) => {
          // this will bring up the 
          this.nav.navigateForward('/home')
        },
        async (err) => {
          // this is expected
          console.log('got signup result: ', err, this);
          if (err.error && err.error.message != "The user account has been created but is not yet active, check your email for an activation code") {
            // if it's not the one we're expecting we gotta return here
            console.log("NOT ROUTING TO CHECKOUT OR HOME")
            return;
          }

          // TODO : there is only one error we expect
          // the one where the user needs to activate after email is sent
          // so we should check to ensure that we get the error we're expecting,
          // and throw in any other case

          // determine if you need to get the users payment info
          const isIndividual = ['BETA', 'INDIVIDUAL'].includes(this.tier)
          // const isTeamLead = this.tier.includes('TEAM') && !this.brokerage_code;
          const isTeamLead = this.account_lead
          if (isIndividual || isTeamLead) {
            this.auth.getCheckout(this.tier, this.mail)
              .subscribe(
                async (checkoutData: any) => {
                  console.log("CHECKOUT RES: ", checkoutData)
                  // TODO - add this into the registration component
                  // if (confirm("Account created, you will receive an email.\nTo input billling information, you will be routed to our payment processing service.\nProceed?")) {
                  //   location.href = checkoutData.url
                  // }
                  this.modalCtrl = await this.modalController.create({
                    component: StripeCheckoutModal,
                    componentProps: { checkoutUrl: checkoutData.url }
                  })
                  await this.modalCtrl.present();
                },
                async (e) => {
                  console.log("--- ERR: checkout data: ", e)
                }
              )
          } else {
            this.modalCtrl = await this.modalController.create(
              {
                component: RegistrationCompleteModalComponent
              });

            this.modalCtrl.onDidDismiss().then(() => {
              this.nav.navigateRoot('home');
            });

            await this.modalCtrl.present();
          }
        }
      )
  }

  // removing the square payment form for stripe registration
  // initPaymenForm() {
  //   this.videoDone = true;
  //   setTimeout(() => {
  //     this.auth.getSquareWebToken().subscribe(webToken => {
  //       const me = this; // dereference 'this' for usage in the inner code below
  //       this.paymentForm = new SqPaymentForm({
  //         // Initialize the payment form elements

  //         //TODO: Replace with prod app ID for production
  //         applicationId: webToken.squareApplicationId,
  //         autoBuild: false,
  //         // Initialize the credit card placeholders
  //         card: {
  //           elementId: 'sq-card',
  //         },
  //         // SqPaymentForm callback functions
  //         callbacks: {
  //           cardNonceResponseReceived: function (errors, nonce, cardData) {
  //             console.log(errors, nonce, cardData);

  //             if (errors) {
  //               me.cardErrors = true;
  //               // Log errors from nonce generation to the browser developer console.
  //               let etext = "";
  //               errors.forEach(function (error) {
  //                 etext += ('  ' + error.message);
  //               });
  //               alert('Encountered errors, check browser developer console for more details - ' + etext);
  //               return;
  //             } else {
  //               me.cardErrors = false;
  //             }

  //             // send this stuff to the api so we can build some square records
  //             me.auth.signUp(me.mail, me.password, me.firstname, me.lastname, nonce, me.address1, me.city, me.state, me.zip)
  //               .subscribe(
  //                 async (loggedin: Boolean) => {
  //                   me.modalCtrl = await me.modalController.create(
  //                     {
  //                       component: RegistrationCompleteModalComponent
  //                     });


  //                   me.modalCtrl.onDidDismiss().then(() => {
  //                     if (loggedin) {
  //                       me.nav.navigateRoot('home');
  //                     } else {
  //                       // thank the user then send them home       
  //                       me.nav.navigateRoot('');
  //                     }
  //                   });

  //                   await me.modalCtrl.present();
  //                 },
  //                 async (err) => {
  //                   // this is actually ok
  //                   console.log('got signup result: ', err, me);
  //                   me.modalCtrl = await me.modalController.create(
  //                     {
  //                       component: RegistrationCompleteModalComponent
  //                     });

  //                   me.modalCtrl.onDidDismiss().then(() => {
  //                     me.nav.navigateRoot('home');
  //                   });

  //                   await me.modalCtrl.present();
  //                 });
  //           }
  //         }
  //       });

  //       this.paymentForm.build();
  //     });  
  //   })
  // }

  loadServices(username) {
    if (username && username.ngControl && !username.ngControl.invalid) {
      if (this.appService.needsSvcURL()) {
        return this.appService.listSvcURL(username.value).subscribe((svcs: any[]) => {
          //if we get more than one service then setup the end-user selection
          if (svcs.length > 1) {
            this.appservices = svcs;
            if (this.appservices && this.appservices.length) {
              this.serviceURL = this.appservices[0].baseurl;
            }
            //save the first one
            return svcs;
          } else {
            //Otherwise just set the service.
            this.appService.saveSvcURL('http://localhost:9000/api/');
          }
        });
      } else {
        this.serviceURL = this.appService.getDetaulSvcUrl();
      }
    }
  }

  saveService(service) {
    if (service && service.length) {
      this.appService.saveSvcURL(service);
    }
  }

  // go to login page
  login() {
    this.nav.navigateRoot('');
  }

  /**
   * Set subscription info based on component output
   */
  setSubscription(event: { tier: string, brokerage_code: string }) {
    this.tier = event.tier;
    this.brokerage_code = event.brokerage_code;
  }

  async toggleTermsModal() {
    console.log("here")
    this.modalCtrl = await this.modalController.create({
      component: TermsOfUseModal
    })
    await this.modalCtrl.present()
  }

  // async checkout() {
  //   console.log("Registering checkout modal")
  //   this.modalCtrl = await this.modalController.create({
  //     component: StripeCheckoutModal  
  //   })
  //   await this.modalCtrl.present();
  // }
}
