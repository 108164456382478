import { Component, Input, OnInit } from "@angular/core";
import { vcSliderOptions } from "../../components/slider/slider-options";
import { ChangeContext, Options } from "@angular-slider/ngx-slider";
import { CustomModifier } from "src/app/services/custommodifier-service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "modifiers-card",
  templateUrl: "modifiers-card.component.html",
  styleUrls: ["modifiers-card.component.scss"],
})
export class ModifiersCard implements OnInit {
  constructor(private formBuilder: FormBuilder) {}

  @Input() modifiers = [];
  @Input() adjustSlider: () => void;
  @Input() enabledSave = false;
  @Input() save: () => void;
  @Input() reset: () => void;
  @Input() isAutosave = false;
  @Input() addCustomModifier: (mod: CustomModifier) => void;

  adding = false;
  newModifier: CustomModifier = null;
  customModForm = this.formBuilder.group({
    modifierName: ["", Validators.required],
    modifierDesc: [""],
    modifierMin: ["", [Validators.required]],
    modifierMax: ["", [Validators.required, Validators.min(1)]],
  });

  removeMod: any = null;

  ngOnInit() {
    $("#modifiers-tabs label").on("click", function () {
      if (!this.classList.contains("active")) {
        $("#modifiers-tabs label.active").removeClass("active");
        this.classList.add("active");
      }
    });
    this.customModForm.valueChanges.subscribe((values) => {
      const value = values.modifierMin;
      if (!isNaN(value) && value !== null && value > 0) {
        this.customModForm
          .get("modifierMin")
          .setValue(value * -1, { emitEvent: false });
      }
    });
  }

  get selectedModifierType() {
    return $("label.active").attr("id").replace("-tab", "");
  }

  get selectedModifiers() {
    const selected = this.selectedModifierType;
    return this.modifiers.filter((m) => m.type === selected);
  }

  getSliderOptions(modifierValue): Options {
    const SUBDESC = {
      1: "Significantly Below Average",
      2: "Below Average",
      3: "Slightly Below Average",
      4: "Average",
      5: "Slightly Above Average",
      6: "Above Average",
      7: "Significantly Above Average",
    };

    return {
      ...vcSliderOptions,
      getLegend: (value) => {
        const tickClass = value === modifierValue ? "tick-active" : "tick";
        return `
      <span class="material-symbols-outlined ${tickClass}">
        arrow_drop_up
      </span>
      `;
      },
      hidePointerLabels: false,
      translate: (v) => SUBDESC[v],
    };
  }

  async onChangeEnd(_: ChangeContext) {
    if (this.isAutosave) {
      await this.save();
    }
  }

  toggleCustomMods() {
    this.adding = !this.adding;
  }

  saveCustomMod = async () => {
    const mod = new CustomModifier({
      s1v: this.customModForm.value.modifierMin,
      s7v: this.customModForm.value.modifierMax,
      title: this.customModForm.value.modifierName,
    });
    this.addCustomModifier(mod);
    if (this.isAutosave) {
      await this.save();
    }
    this.toggleCustomMods();
    this.customModForm.reset();
  };

  /**
   * @todo almost complete
   * add red circle x or remove icon with the modal "Remove" verification
   */
  // toggleRemoveCustomComp(mod) {
  //   this.removeMod = mod;
  // }

  // removeCustomMod() {}
}
