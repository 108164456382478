import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { IProperty } from "src/app/interfaces/property.interface";
import { ColDef, Grid, GridApi, GridOptions, GridOptionsWrapper, GridReadyEvent, IsRowSelectable, RowNode } from "ag-grid-community";
import * as moment from "moment";
import { columnDefs, getRowData, mapCompsToGrid } from "./compsCols";
import { memo } from "src/app/util/util";

@Component({
	selector: 'comps-modal',
	templateUrl: './comps-modal.html',
	styleUrls: ['./comps-modal.scss']
})
export class CompsModal implements OnInit {
	private property: IProperty
	private finalCompsGrid: GridApi
	private finalCompsGridOpts: GridOptions
	private allCompsGrid: GridApi
	private allCompsGridOpts: GridOptions
	private selectedAvgSqft: string
	private selectedAvgPricePerSqft: string
	private compType = 'selected'
	private isGuest = false // guests cannot update comps

	constructor(
		public viewCtrl: ModalController,
		public params: NavParams,
		private sanitizer: DomSanitizer
	) {
		this.property = params.get("property")
		this.isGuest = !!params.get('isGuest') /* @todo this isn't converting for the rest of the file */
		console.log("CompsModal:Constructor:property:", this.property)
	}

	ngOnInit() {
		const finalCompsData = this.property.zillowFinalComps.finalComps.map(mapCompsToGrid)
		const allCompsData = this.property.zillowComps.map(mapCompsToGrid)
		const defaultColDef = { resizable: true, sortable: true }
		let rowDragManaged = true
		// i have no clue why, but this value isn't being saved from the constructor
		const isGuest = !!this.isGuest
		if (isGuest) {
			columnDefs[0].checkboxSelection = false
			columnDefs[0].rowDrag = false
			rowDragManaged = false
		}

		const sharedOpts = {
			columnDefs,
			defaultColDef: defaultColDef,
			rowSelection: 'multiple', 
			rowDragManaged,
			animateRows: true,
			suppressRowClickSelection: isGuest,
			enableCellTextSelection: true
		}

		// alert(`${this.isGuest} | type = ${typeof this.isGuest}`) // stays as string... not boolean
		this.finalCompsGridOpts = { ...sharedOpts, columnDefs, rowData: finalCompsData  }
		new Grid($('#finalCompsGridDiv')[0], this.finalCompsGridOpts)
		this.finalCompsGridOpts.api.setRowData(finalCompsData)
		this.finalCompsGrid = this.finalCompsGridOpts.api

		this.allCompsGridOpts = {
			...sharedOpts,
			rowData: allCompsData,
			isRowSelectable: (p) => {
				const finalCompsData = getRowData(this.finalCompsGrid)
				const match = finalCompsData.find(c => c.Address === p.data.Address)
				return !match
			}
		}
		new Grid($('#allCompsGridDiv')[0], this.allCompsGridOpts)
		this.allCompsGridOpts.api.setRowData(allCompsData)
		this.allCompsGrid = this.allCompsGridOpts.api

		this.setAvgVals()
	}

	close(updateOnClose = false) {
		if (updateOnClose) {
			const finalComps = getRowData(this.finalCompsGrid)
			this.property.zillowFinalComps.finalComps = finalComps
			this.property.zillowFinalComps.cmaPerSqFt = Math.round(finalComps.reduce((sum, comp) => comp.pricePerSqFoot + sum, 0) / finalComps.length)
		}

		this.viewCtrl.dismiss({
			updateOnClose,
			property: this.property
		})
	}

	// Good idea, doesn't update when the comps move from All -> Selected
	// computeAvgVals = memo((grid: GridApi) => {
	// 	const rows = getRowData(grid)
	// 	const sqft = Math.round(rows.reduce((sum, comp) => sum + comp.livingArea, 0) / rows.length).toLocaleString('en-US')
	// 	const pricePerSqFt = Math.round(rows.reduce((sum, comp) => sum + comp.pricePerSqFoot, 0) / rows.length).toLocaleString('en-US')
	// 	console.log(`CompsModal:computeAvgVals:sqft=${sqft}|pricePerSqFt=${pricePerSqFt}|rows=`, rows)
	// 	return {
	// 		sqft,
	// 		pricePerSqFt
	// 	}
	// })

	setAvgVals = () => {
		const rows = getRowData(this.finalCompsGrid)
		this.selectedAvgSqft = Math.round(rows.reduce((sum, comp) => sum + comp.livingArea, 0) / rows.length).toLocaleString('en-US')
		this.selectedAvgPricePerSqft = Math.round(rows.reduce((sum, comp) => sum + comp.pricePerSqFoot, 0) / rows.length).toLocaleString('en-US')
	}

	moveToSelected() {
		const currentRows = getRowData(this.finalCompsGrid)
		const rowsToMove = this.allCompsGrid.getSelectedRows()
		this.finalCompsGridOpts.api.setRowData([...currentRows, ...rowsToMove])
		this.allCompsGridOpts.api.setRowData(getRowData(this.allCompsGrid)) // disable the moved checkboxes
		this.setAvgVals()
	}

	moveFromSelected() {
		const addressesToRemove = this.finalCompsGrid.getSelectedRows().map(c => c.Address)
		console.log("CompsModal:moveFromSelected:addressesToRemove:", addressesToRemove)
		this.finalCompsGridOpts.api.setRowData(
			getRowData(this.finalCompsGrid).filter(c => !addressesToRemove.includes(c.Address))
		)
		this.allCompsGridOpts.api.setRowData(getRowData(this.allCompsGrid))
		this.setAvgVals()
	}

	toggleCompPage(e) {
		console.log('-- toggleCompPage: ', e)
		this.compType = e.detail.value
		console.log('... ok compType = ', this.compType)
	}
}