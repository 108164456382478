import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-checkout-required-modal',
  templateUrl: './checkout-required.component.html',
  styleUrls: ['./checkout-required.component.scss']
})
export class CheckoutRequiredModal implements OnInit {
  @Input("email") email
  @Input("disableCloseBtn") disableCloseBtn

  constructor(
    public viewCtrl: ModalController,
    public authService: AuthService,
  ) { }

  ngOnInit() {
  }

  close() {
    if (this.disableCloseBtn) {
      this.authService.signOut()
    }

    this.viewCtrl.dismiss({
      'dismissed': ''
    });
  }

  async checkout() {
    const checkoutData: any = await this.authService.getCheckoutPartial(this.email).toPromise()
    location.href = checkoutData.url;
  }
}