import { IProperty } from 'src/app/interfaces/property.interface'
import { IUser } from '../../interfaces/user.interface'

export const MESSAGE_1 = (user: IUser) => `Dear Owner,

I hope you're well. Curious about your home’s true value in this booming market? Click <<this link>> to access a quick, user-friendly app (no download necessary) to see the most accurate value of your home in less than 2-minutes. You can even add any home improvements for a more refined estimate.

If you would prefer the most precise valuation, I offer a free in-home review to assess improvements and answer questions. Schedule yours by calling ${user.profile.phone} or emailing ${user.local.email}.

Think your friends or family are curious? Please share their email and address; I’ll send them a similar link. Referrals are greatly appreciated!

Best Regards,
${user.firstname} ${user.lastname}
${user.profile.brokerage}
${user.profile.phone}`

export const MESSAGE_2 = (user: IUser) => `Dear Owner,

I hope you're doing well. With inventory levels at historically low levels and the majority of homes selling for over list price, I wanted to offer you the opportunity to discover your home's current value.

Click <a href="{{link}}">this link</a> to access our quick and highly accurate app, no download required. In less than 2 minutes, you'll receive a precise estimate of what your home would sell for in today’s market.

Prefer a more personalized touch? I invite you to schedule a free in-home review. During this visit, we'll assess any improvements you've made and address your questions about the current real estate market. Just call me at ${user.profile.phone} or email ${user.local.email} to book your in-home review.

If your friends or family are curious too, please share their email addresses and home addresses with me. I'll gladly send them a similar link to discover what their homes would sell for today. Your referrals mean a lot to me!

Thank you for considering this opportunity. I'm excited to help you gain valuable insights into your home's current value.

Warm regards,
${user.firstname} ${user.lastname}
${user.profile.brokerage}

P.S. For guidance on using the app, <a href="https://youtu.be/OnW7vX6_RhU">here’s a 1-minute tutorial video</a>
`.replace(/(\r\n|\r|\n)/g, '<br>')
// note we need to start saving MLS number for these 
// original version: P.S. For guidance on using the app, <a href="https://app.valuclick.co/assets/videos/VALUclick-Animation.mp4">here’s a 2-minute tutorial video</a>