import { Component, NgModule } from "@angular/core";
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { IProperty } from "src/app/interfaces/property.interface";
import { IUser } from "src/app/interfaces/user.interface";
import { UserAdminService } from "src/app/services/user.service";

@Component({
	selector: 'property-admin-modal',
	templateUrl: 'property-admin-modal.html',
	styleUrls: ['./property-admin-modal.scss'],
})

export class PropertyAdminModal {
	property: IProperty
	eccovals: any[]
	users: IUser[]
	userEccovalMap: { [id: string]: any } = {}
	propertyJson: string

	constructor(
		public viewCtrl: ModalController,
		public params: NavParams,
		public userService: UserAdminService,
		public nav: NavController
	) {
		this.property = params.get("property");
		this.propertyJson = JSON.stringify(this.property, null, 4)
		// we're going to hide the "DELETED ecco vals for now"
		this.eccovals = this.property.eccovals.filter(e => !e.created_by.includes('DELETED:'))
		console.log("?--- PROPERTTY = ", this.property)
		this.loadUsers()
	}

	async loadUsers() {
		const userids = new Set(this.eccovals.map(e => e.created_by.replace('DELETED:', '')))
		const users = await Promise.all(
			Array.from(userids).map(id =>
				this.userService.findById(id).toPromise()
					.then(u => u)
					.catch(e => null)
			)
		)

		this.users = users;

		console.log("ECCOVALS", this.eccovals)
		this.userEccovalMap = users.reduce((acc, curr) => {
			acc[curr._id] = this.eccovals.find(e => e.created_by == curr._id)
			return acc
		}, {})

		console.log("USERS ", users)
		console.log("ECCOMAP: ", this.userEccovalMap)
	}

	close() {
		this.viewCtrl.dismiss({ dismissed: true })
	}

	viewProperty() {
		console.log("todo")
	}

	routeToUserDetails(userid: string) {
		this.nav.navigateForward(`/property/${this.property._id}?userid=${userid}`)
		this.close()
	}
}
