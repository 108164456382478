export function toQueryStr(obj) {
  return Object.keys(obj)
    .map((key) => key + "=" + encodeURIComponent(obj[key]))
    .join("&");
}

export function getMapsImgUrl(search_formatted_address: string) {
  return `https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=600x300&maptype=roadmap&markers=color:red%7Clabel:C%7C${encodeURIComponent(
    search_formatted_address
  )}&key=AIzaSyDdrX2CXoRUPf8MXt-LnYzlUtsnBc_LBGw`;
}
export function objectifyQueryParams() {
  // can't use this one...
  // const params = Object.fromEntries(new URLSearchParams(location.search));
  // https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
  const search = location.search.substring(1);
  return search
    ? JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    : {};
}

// Converts numeric degrees to radians
export function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export function calcCrow(lat1, lon1, lat2, lon2, inMiles = false) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1) as any;
  var lat2 = toRad(lat2) as any;

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  if (!inMiles) {
    d *= 0.621371;
  }
  return d.toFixed(1);
}

/**
 * Use a function to derive a value instead of always defining new state values
 * https://itnext.io/its-ok-to-use-function-calls-in-angular-templates-ffdd12b0789e
 */
export function memo<T extends Function>(fnToMemoize: T): T {
  let prevArgs = [{}];
  let result;

  return function (...newArgs) {
    if (hasDifferentArgs(prevArgs, newArgs)) {
      result = fnToMemoize(...newArgs);
      prevArgs = newArgs;
    }
    return result;
  } as any;
}

function hasDifferentArgs(prev: unknown[], next: unknown[]) {
  if (prev.length !== next.length) return true;
  for (let i = 0; i < prev.length; i++) {
    if (!Object.is(prev[i], next[i])) return true;
  }
  return false;
}

export function delay(t) {
  return new Promise((resolve) => setTimeout(resolve, t));
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getCreatedDate(mongoId: string): Date {
  const tstamp = mongoId.substring(0, 8);
  const createdAt = new Date(parseInt(tstamp, 16) * 1000);
  return createdAt;
}

export function formatNumber(n) {
  if (n < 1e3) return n; // less than 1k
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "k"; // from 1k to 1m
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "m"; // from 1m to 1b
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "b"; // from 1b to 1t
}

export function fmtPrice(n, withSpace = false) {
  return `$${withSpace ? " " : ""}${Math.round(n).toLocaleString("en-US")}`;
}

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
