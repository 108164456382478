import { ColDef, GridOptions } from "ag-grid-community";
import * as moment from "moment";
import { IProperty } from "src/app/interfaces/property.interface";
import { calcCrow, fmtPrice } from "src/app/util/util";

export interface Table {
  cols: (
    curr: IProperty,
    presentedComps: any[],
    togglePresentedCb: (property: any, checked: boolean) => void
  ) => ColDef[];
  defColDef: ColDef;
  gridOptions: (presentedComps: any[]) => GridOptions;
}

export const compsTable: Table = {
  cols: (curr, presentedComps, togglePresentedCb) => {
    return [
      {
        headerName: "Presented",
        width: 105,
        minWidth: 60,
        filter: false,
        cellRenderer: (p) => {
          const id = `toggle-${p.node.id}`;
          const initialChecked = presentedComps.find(
            (c) => c.address === p.data.address
          );
          setTimeout(() => {
            document
              .getElementById(id)
              .addEventListener("ionChange", (e: any) => {
                togglePresentedCb(p.data, e.detail.checked);
              });
          }, 50);
          return `
            <ion-toggle
              id="${id}"
              style="padding: 6px 0; margin-top: 14px;"
              ${initialChecked ? "checked" : ""}
            ></ion-toggle>
          `;
        },
      },
      {
        field: "address",
        // headerCheckboxSelection: true,
        // checkboxSelection: true,
        minWidth: 440,
        cellRenderer: (p) => {
          const imgSrc = p.data.hasImage
            ? p.data.imgSrc
            : "assets/images/property/no-photo-tbl.svg";
          const style = `border-radius: 8px;${
            !p.data.hasImage ? "border: solid 1px #ccc;" : ""
          }`;
          return `
            <div>
              <img src="${imgSrc}" height="80px" width="100px" style="${style}" />
              <span class="ml-2">${p.data.address}</span>
            </div>
          `;
        },
      },
      {
        headerName: "Date Sold",
        valueGetter: (n) => moment(n.data.dateSold).fromNow(),
        minWidth: 115,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          const a = moment(nodeA.data.dateSold).unix();
          const b = moment(nodeB.data.dateSold).unix();
          return a - b;
        },
        // floatingFilter: true,
        // filter: "agNumberColumnsFilter",
      },
      {
        headerName: "Distance",
        valueGetter: (n) =>
          `${calcCrow(
            curr.Latitude,
            curr.Longitude,
            n.data.latitude,
            n.data.longitude,
            true
          )} mi`,
        minWidth: 115,
      },
      {
        headerName: "Sale Price",
        valueGetter: (n) => fmtPrice(n.data.price),
        minWidth: 130,
      },
      {
        headerName: "Living Area",
        valueGetter: (n) => `${n.data.livingArea} sq ft`,
        minWidth: 115,
      },
      {
        headerName: "Beds",
        valueGetter: (n) => `${n.data.bedrooms} beds`,
        minWidth: 115,
      },
      {
        headerName: "Baths",
        valueGetter: (n) => `${n.data.bathrooms} beds`,
        minWidth: 115,
      },
    ];
  },
  defColDef: {
    sortable: true,
    filter: true,
    flex: 1,
    resizable: true,
    cellStyle: {
      display: "flex",
      "align-items": "center",
    },
    // headerCheckboxSelectionFilteredOnly: true,
  },
  gridOptions: (presentedComps: any[]) => ({
    pagination: true,
    paginationPageSize: 5, // fits on one modal page
    isRowSelectable: (n) => {
      return !presentedComps.find((comp) => n.data.address === comp.address);
    },
  }),
};
