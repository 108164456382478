import { Component, Input, NgModule, OnInit } from "@angular/core";
import { LoadingController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { Grid, GridOptions } from "ag-grid-community";
import * as moment from "moment";
import { PaymentInfoModal } from "src/app/components/payment-info-modal/payment-info-modal.component";
import { SubscriptionModal } from "src/app/components/subscription-modal/subscription-modal.component";
import { IUser, VcInvoice, VcPaymentMethod, VcPlan } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { TITLES } from "src/app/util/constants";
import { capitalize } from "src/app/util/util";

const getStatusCap = (status) => {
  if (status === 'paid') {
    return `
      <div>
        <span style="background-color: #F1F7F4; border-radius: 24px; padding: 5px 12px; font-size: 10px; color: #438168; font-weight: bold;">
          <ion-icon name="checkmark" style="color: #66B892;"></ion-icon>
          Paid
        </span>
      </div>
    `
  }
  return `<div class="statuscap warning">${status}</div>`
}

const INVOICE_TABLE_OPTS: GridOptions = {
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    cellStyle: { color: '#91949F' }
  },
  columnDefs: [
    {headerName: 'Invoice', field: 'period_end', valueFormatter: p => p.data.name /*cellRenderer: p => `<ion-icon name="document"></ion-icon>${p.data.name}`*/, flex: 1, cellStyle: { color: 'rbga(0, 0, 0, 0.7)'}},
    {headerName: 'Billing Date', field: 'period_end', valueFormatter: p => moment(p.data.period_end).format('MMM D, YYYY'), width: 135},
    {headerName: 'Status', field: 'status', cellRenderer: p => getStatusCap(p.data.status), width: 110},
    {headerName: 'Amount', field: 'amtDue', valueFormatter: p => `$${p.data.amtDue}`, width: 110},
    // {headerName: 'Plan', field: ''}
    {headerName: '', sortable: false, cellRenderer: p => `<a href="${p.data.hostedInvoiceUrl}" target="_blank" rel="noopener" style="text-align: center">Download</a>`, cellStyle: { textAlign: 'end' }, width: 110}
  ],
  domLayout: 'autoHeight',
  rowHeight: 80,
}

@Component({
  selector: 'billing-details',
  templateUrl: './billing-details.html',
  styleUrls: ['./billing-details.scss']
})
export class BillingDetails implements OnInit {
  user: IUser
  @Input() forUser: IUser

  private grid: Grid  
  private gridOptions = INVOICE_TABLE_OPTS

  plan: VcPlan
  paymentMethod: VcPaymentMethod
  allPaymentMethods: VcPaymentMethod[]
  upcomingInvoice: VcInvoice
  discountLeft: string // description of how many days remaining on discount

	constructor(
    public auth: AuthService,
    public userService: UserAdminService,
    public loadingController: LoadingController,
    public toastCtl: ToastController,
    public modalCtl: ModalController
  ) {}

  async ngOnInit() {
    document.title = TITLES.BILLING_DETAILS
    this.user = this.forUser ? this.forUser : this.auth.getUser()
    this.grid = new Grid($('#invoice-grid')[0], this.gridOptions)
    setTimeout(() => {
      this.styleTable()
    }, 100);

    await this.getStripeInfo()
  }

  styleTable() {
    const wrapper = $('.ag-root-wrapper')
    wrapper.css('border-radius', '8px')
    wrapper.css('border-color', '#dde2eb !important')
    const header = $('.ag-header')
    header.css('min-height', '35px')
    header.css('height', '35px')
    // #dde2eb
    header.css('border-bottom-color', '#dde2eb')
    $('.ag-header-row').css('height', '35px')
  }

  async getStripeInfo(): Promise<any> {
    if (!this.user.stripe_customer_id) {
      console.log('No stripe customer id for this user - ', this.user)
      const toast = await this.toastCtl.create({ message: 'No stripe information to load', duration: 3000, position: 'top' })
      return await toast.present()
    }
    const loader = await this.loadingController.create({})
    await loader.present()
    try {
      const data: any = await this.userService.getStripeInfo(this.user._id)
      console.log('billing info: ', data)
      this.gridOptions.api.setRowData(data.pastInvoices)
      $('.ag-cell').css('line-height', '80px')
      this.plan = data.plan
      this.paymentMethod = { ...data.paymentMethod, card: { ...data.paymentMethod.card, brand: capitalize(data.paymentMethod.card.brand) }}
      this.allPaymentMethods = data.allPaymentMethods.map(p => ({ ...p, card: { ...p.card, brand: capitalize(p.card.brand) } }))
      this.upcomingInvoice = data.upcomingInvoice
      const discountEnd = this.upcomingInvoice.discountEnd
      if (discountEnd) {
        this.discountLeft = `Ends ${discountEnd} - ${moment(discountEnd).diff(moment(), 'days')} days left`
      }
    } catch (e) {
      console.error(`Error loading stripe data: `, e)
    }
    await loader.dismiss()
  }

  /**
   * Open a modal to update subscription plan info
   */
  async updateSubscription() {
    const modal = await this.modalCtl.create({
      component: SubscriptionModal, 
      componentProps: { tier: this.plan.tier, user: this.user }
    })
    modal.present()
    modal.onDidDismiss().then(async ({ data }) => {
      if (data && data.reload) {
        this.user = await this.auth.getFreshUser(true)
        await this.getStripeInfo()
      }
    })
  }

  /**
   * Open modal to update card info
   */
  async updateCard(paymentMethod: VcPaymentMethod) {
    const modal = await this.modalCtl.create({
      component: PaymentInfoModal,
      componentProps: { paymentMethod }
    })
    modal.present()
    modal.onDidDismiss().then(async ({ data }) => {
      if (data && data.reload) {
        this.user = await this.auth.getFreshUser(true)
        await this.getStripeInfo()
      }
    })
  }
}
