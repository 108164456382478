/**
 * Subscription + Payment info component that can be for a new user or user partially registered.
 */
import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";

const PLAN_CARDS = [
  {
    tier: 'TRIAL',
    name: 'Trial',
    subheader: 'For 1 user',
    cost: '$10',
    costDesc: 'Single Use Only',
    details: [
      'VALU One Property',
      'Generate One Report',
      'No Contract (One time use only)'
    ]
  },
  {
    tier: 'LAUNCH',
    name: 'Launch',
    subheader: 'For 1 user',
    cost: '$25',
    costDesc: 'per month',
    details: [
      'VALU Unlimited Properties',
      'Generate Unlimited Reports',
      'No Contract (cancel anytime)'
    ]
  },
  {
    tier: 'GROWTH',
    name: 'Growth',
    subheader: 'For 2 - 200 users',
    cost: '$20',
    costDesc: 'per user, per month',
    details: [
      'VALU Unlimited Properties',
      'Generate Unlimited Reports',
      'No Contracts (cancel anytime)',
      'Add Users as Needed (Easy to Add/Remove)'
    ]
  }
]

@Component({
  selector: "subscription",
  templateUrl: "./subscription.html",
  styleUrls: ["./subscription.scss"],
})
export class Subscription implements OnInit {
  @Input() onChange: (tier: string) => void
  // eslint-disable-next-line no-use-before-define
  PLAN_CARDS = PLAN_CARDS
  @Input() tier
  @Input() hidden

  constructor(
    public userService: UserAdminService,
    public auth: AuthService
  ) {}

  async ngOnInit() {
    // allow caller to remove cards not relevant to the current user's subscription status
    if (this.hidden) {
      this.PLAN_CARDS = PLAN_CARDS.filter(p => !this.hidden.includes(p.tier))
    }
  }

  setTier(tier) {
    this.onChange(tier)
  }

  /**
   * @deprecated originally implementation included card in the input - 
   * can probably remove
   */
  async load() {}
  async submit() {}
	async loadStripe() {}
  async displayCardInput() {}
  async addCard() {}
}
