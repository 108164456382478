import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { IProperty } from "src/app/interfaces/property.interface";
import { calcCrow, fmtPrice } from "src/app/util/util";
import { PropertyFilter } from "../../components/property-filter-modal/property-filter-modal.component";

@Component({
  selector: "comps-card",
  templateUrl: "comps-card.component.html",
  styleUrls: ["comps-card.component.scss"],
})
export class CompsCard implements OnInit {
  @Input() sourceProperty: IProperty;
  @Input() availableComps;
  @Input() loadAvailableComps: () => void;
  @Input() loadingComps: boolean;
  @Input() save: (nextPresented: any[]) => void;
  @Input() currentComps: any[];
  @Input() autofillCma: (syncCma: boolean, forceAutofillComps: boolean) => void;

  adding = false;
  scrolled = false;
  activePropertyFilter = false;
  propertyFilter: PropertyFilter;

  get filteredAvailableComps() {
    const currCompAddresses = this.sourceProperty.comps.map((c) => c.address);
    // console.log("---- Property Filter = ", this.propertyFilter);
    // return this.availableComps
    //   .filter((c) => !currCompAddresses.includes(c.address))
    //   .filter(
    //     (c) =>
    //       !this.propertyFilter ||
    //       (this.propertyFilter.beds && this.propertyFilter.beds === c.bedrooms)
    //   )
    //   .filter(
    //     (c) =>
    //       !this.propertyFilter ||
    //       (this.propertyFilter.baths &&
    //         this.propertyFilter.baths === c.bathrooms)
    //   );
    // console.log(
    //   "DEBUG",
    //   this.availableComps,
    //   this.sourceProperty.comps,
    //   this.propertyFilter
    // );
    // 1. remove currently selected comps
    let available = this.availableComps.filter(
      (c) => !currCompAddresses.includes(c.address)
    );
    // 2. remove based on filter: price, beds, baths, size, type*
    if (!this.propertyFilter) {
      return available;
    }
    if (this.propertyFilter.priceRange) {
      available = available.filter(
        (a) =>
          a.price >= this.propertyFilter.priceRange[0] &&
          a.price <= this.propertyFilter.priceRange[1]
      );
    }
    if (this.propertyFilter.beds) {
      available = available.filter(
        (a) => a.bedrooms === this.propertyFilter.beds
      );
    }
    if (this.propertyFilter.baths) {
      available = available.filter(
        (a) => a.bathrooms === this.propertyFilter.baths
      );
    }
    if (this.propertyFilter.size) {
      available = available.filter(
        (a) =>
          a.livingArea >= this.propertyFilter.size[0] &&
          a.livingArea <= this.propertyFilter.size[1]
      );
    }
    if (this.propertyFilter.type) {
      available = available.filter(
        (a) => a.listingStatus === this.propertyFilter.type
      );
    }
    return available;
  }

  constructor() {}
  ngOnInit(): void {}

  getDistance(comp) {
    try {
      const dist = calcCrow(
        comp.latitude,
        comp.longitude,
        this.sourceProperty.Latitude,
        this.sourceProperty.Longitude,
        true
      );
      return `${dist}mi`;
    } catch (e) {
      return "n/a";
    }
  }

  getPrice(comp) {
    try {
      const { price, livingArea } = comp;
      // const pricePerSqft = Math.round(price / livingArea).toLocaleString('en-is')
      const pricePerSqft = fmtPrice(price / livingArea);
      return `${pricePerSqft}/sqft`;
    } catch (e) {
      return "n/a";
    }
  }

  getFullPrice(comp) {
    try {
      const { price } = comp;
      return fmtPrice(price);
    } catch (e) {
      return "n/a";
    }
  }

  getDateSold(comp) {
    try {
      return moment(comp.dateSold).fromNow();
    } catch (e) {
      return "n/a";
    }
  }

  getBed(comp) {
    try {
      return comp.bedrooms;
    } catch (e) {
      return "n/a";
    }
  }

  getBath(comp) {
    try {
      return comp.bathrooms;
    } catch (e) {
      return "n/a";
    }
  }

  getSize(comp) {
    try {
      return `${comp.livingArea.toLocaleString("en-US")} sqft`;
    } catch (e) {
      return "n/a";
    }
  }

  toggleAllComps = () => {
    this.adding = !this.adding;
  };

  addComp = async (p) => {
    await this.save([...this.sourceProperty.comps, p]);
    this.adding = false;
  };

  removeComp = async (i) => {
    if (
      confirm(
        `Sure you want to remove ${this.sourceProperty.comps[i].address}?`
      )
    ) {
      await this.save(this.sourceProperty.comps.filter((_, idx) => idx !== i));
    }
  };

  setSearchShadow(event) {
    this.scrolled = event.target.scrollTop > 0;
  }

  togglePropertyFilter = () => {
    this.activePropertyFilter = !this.activePropertyFilter;
  };

  filterComps = (filter) => {
    console.log("--- FILTER STUFF", filter);
    this.activePropertyFilter = false;
    this.propertyFilter = filter;
  };
}
