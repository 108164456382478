/**
 * Handler for resetting the user's password.
 * The login page has the "Reset Password" button to initiate the process.
 * An email is sent containing the activation code in the URL,
 * and the query params include the email and the name of the user for the greeting message.
 */
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { LoadingController, NavController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";
import { TITLES } from "src/app/util/constants";
import { objectifyQueryParams } from "src/app/util/util";

@Component({
	selector: 'resetpass',
	templateUrl: 'resetpass.html',
	styleUrls: ['./resetpass.scss']
})

export class ResetPass {
	private email: string
	private firstname: string
	private password: string
	private passwordConfirm: string
	private token: string

	constructor(
		public nav: NavController,
		public auth: AuthService,
		public loadingCtrl: LoadingController,
		public activatedRoute: ActivatedRoute
	) {
		document.title = TITLES.RESET_PASS
		const { email, firstname } = objectifyQueryParams()
		this.email = email
		this.firstname = firstname
		this.token = this.activatedRoute.snapshot.paramMap.get('code')
	}

	async resetpass() {
		if (!this.password) {
			return alert("Please provide password")
		}
		if (this.password !== this.passwordConfirm) {
			return alert("Password must match Confirmed Password")
		}
		if (this.password.length < 6) {
			return alert("Password must be at least 6 characters")
		}

		const loader = await this.loadingCtrl.create()
		await loader.present()

		try {
			const res = await this.auth.changePassword(this.email, this.password, this.token).toPromise()
			console.log("RES IS: ", res)

			await this.auth.signIn(this.email, this.password)
			this.nav.navigateForward('/home')
		} catch (e) {
			console.log("Error resetting password: ", e)
			// once user is in resetpass state they need to change it. their account is inactive and they can't just login.
			// if (e.error.message == "You may not reuse your existing password") {
			// 	if (confirm("The password you've provided is your current password. Want to keep using it?")) {
			// 		await this.auth.signIn(this.email, this.password)
			// 		this.nav.navigateForward('/home')
			// 	}
			// }
		}

		await loader.dismiss()
	}
}