import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { IProperty } from 'src/app/interfaces/property.interface';
import { AuthService } from 'src/app/services/auth.service';
import { PropertyService2 } from 'src/app/services/property2.service';
import { MESSAGE_2 } from './share-valu-constants';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { linkValidator } from '../leadgen-email-form/leadgen-email-form.component';

@Component({
	selector: 'share-valu-modal',
	templateUrl: 'share-valu-modal.component.html',
	styleUrls: ['share-valu-modal.component.scss']
})
export class ShareValuModal implements OnInit {
	private property: IProperty
	private name
	private emails = ""
	private subject = `Discover your home's true value in this current market!`
	private link
	private message = ''
	private submitting = false
	private ccMyEmail = false
	private messageForm = this.formBuilder.group({
		emails: ['', [Validators.required, multipleEmails()]],
		ccMyEmail: [false, Validators.required],
		subject: [`Discover your home's true value in this current market!`, Validators.required],
		message: ['', [Validators.required, linkValidator()]]
	})
	
	constructor(
		private viewCtl: ModalController,
		private auth: AuthService,
		private params: NavParams,
		private toastCtl: ToastController,
		private propertyService: PropertyService2,
		public formBuilder: FormBuilder,
	) {
		this.property = params.get('property')
	}

	ngOnInit() {
		// this.initialize()
		// this.attachInputListener()
		// $('.nw-toolbar').css('display', 'flex')
		// new Quill('#editor', { theme: 'snow' })
		this.messageForm.patchValue({ message: MESSAGE_2(this.auth.getUser())})
		this.messageForm.valueChanges.subscribe(updated => {
			console.log('---- updatedValue = ', updated)
		})
	}

	cancel() {
		this.viewCtl.dismiss()
	}

	get canSend() {
		return this.messageForm.valid && !this.submitting
	}

	async submit() {
		/** @todo figure how to make disabled actually unclickable */
		if (!this.canSend) {
			return
		}
		// this.viewCtl.dismiss()
		this.submitting = true
		try {
			await this.propertyService.sendLeadgenLink(
				this.property._id,
				this.messageForm.get('emails').value,
				this.messageForm.get('subject').value,
				this.messageForm.get('message').value,
				this.ccMyEmail
			)
			await this.showToast('Successfully sent the email.')
			this.viewCtl.dismiss({ update: true })
		} catch (e) {
			console.error('Error sending leadgen link: ', e)
			await this.showToast('Error sending leadgen link. Check values and try again.')
		}
		this.submitting = false
	}

	private async showToast(message) {
		const toast = await this.toastCtl.create({ message, duration: 3000, position: 'top' })
		toast.present()
	}

	initialize() {
		const user = this.auth.getUser()
		this.name = `${this.property.search_formatted_address.split(',')[0]} Assessment ${this.property.eccovals.length}` // arbitrary
		this.link = this.formatLink(this.name)
		this.message = MESSAGE_2(user)
// P.S For guidance on using the app, <<link to video>>`
	}

	formatLink(reportname: string) {
		const user = this.auth.getUser()
		const endpoint = reportname.trim() // remove spaces from both ends
			.toLowerCase() // convert to lowercase
			.replace(/\s+/g, '-') // replace spaces with dash
			.replace(/\//g, '-') // replace slash with dash
			.replace(/[^a-z0-9\-]/g, '') // remove non-alphanumeric and non-dash characters
		return `${window.location.origin}/guest/${this.property._id}/${endpoint}`
	}

	stripLink(link: string) {
		const toks = link.split('/')
		return toks[toks.length - 1]
	}

	attachInputListener() {
		const me = this
		const values = ['emails', 'name', 'subject', 'message']
		values.map(v => {
			console.log('-- FOR ', v)
			$(`#report${v}`).on('input', function (e) {
				console.log('---- INPUT: ', e.target)
				me[v] = $(this).val()
				if (v === 'name') {
					const currlink = me.link
					const newlink = me.formatLink($(this).val() as string)
					me.message = me.message.replace(currlink, newlink)
					me.link = newlink
				}
			})
		})
	}

	onChange(data) {
		// console.log('---- event data = ', data)
		this.message = data
	}

	get isMobile() {
		return window.innerWidth <= 600
	}

}

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function multipleEmails(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    // if (value && !value.includes('{{link}}')) {
    //   return { linkInvalid: true };
    // }
		if (value) {
			const emails = value.split(',')
			if (emails.find(e => !emailPattern.test(e.trim()))) {
				return { emailsInvalid: true }
			}
		}
    return null;
  };
}
