import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { IProperty } from "src/app/interfaces/property.interface";
import { PropertyService } from "src/app/services/property.service";

@Component({
	selector: 'property-detail-guest',
	templateUrl: 'property-detail-guest.html',
	styleUrls: ['property-detail-guest.scss']
})
export class PropertyDetailGuestPage {
	// private propertyId: string
	// private valuId: string
	private link: string
	private property: IProperty

	constructor(
		private nav: NavController,
		private activatedRoute: ActivatedRoute,
		private propertyService: PropertyService,
		private toastCtl: ToastController,
		private loadCtl: LoadingController
	) {
		// this.propertyId = this.activatedRoute.snapshot.paramMap.get('id')
		// this.valuId = this.activatedRoute.snapshot.paramMap.get('valuid')
		// if (!this.valuId) {
		// 	alert('Invalid link.')
		// 	return
		// }
		this.link = this.activatedRoute.snapshot.paramMap.get('link')
		if (!this.link) {
			alert('Invalid link.')
			return
		}
		this.load()
	}

	private async showLoader() {
		const loader = await this.loadCtl.create()
		loader.present()
		return loader
	}

	private async showToast(message) {
		const toast = await this.toastCtl.create({ message, duration: 3000, position: 'top' })
		toast.present()
	}

	private async load() {
		const loader = await this.showLoader()
		try {
			// const p = await this.propertyService.findById_guest(this.propertyId, this.valuId)
			// const p = await this.propertyService.findByLink(this.link)
		} catch (e) {
			console.error('Error loading property: ', e)
			await this.showToast('Error loading property')
		}
		loader.dismiss()
	}
}