import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from "@ionic/angular";
import { IProperty, ZillowComp } from "src/app/interfaces/property.interface";
import { AuthService } from "src/app/services/auth.service";
import { PropertyService } from "src/app/services/property.service";
import { Options } from "@angular-slider/ngx-slider";
import { ModifierService } from "src/app/services/modifier-service";
import { CompsModal } from "../comps-modal/comps-modal";
import { AddValuModal } from "src/app/components/add-valu-modal/add-valu-modal.component";
import { InputCmaModal } from "src/app/components/input-cma-modal/input-cma-modal.component";

@Component({
  selector: "property-detail-page",
  templateUrl: "property-detail-test.html",
  styleUrls: ["property-detail-test.scss"],
})
export class PropertyDetailPage_Test implements OnInit {
  private propertyId: string;
  private property: IProperty;
  private eccovalIdx: number; // NOTE: this value will remove after migration
  private userId: string;
  private sliderOpts: Options = { showTicksValues: true, floor: 1, ceil: 7 };
  private loader: HTMLIonLoadingElement;
  private inputcma = false;
  private inputcmaVal = null;

  constructor(
    private nav: NavController,
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private toastCtl: ToastController,
    private authService: AuthService,
    private loadCtl: LoadingController,
    private modifierService: ModifierService,
    private modalCtl: ModalController
  ) {
    this.propertyId = this.activatedRoute.snapshot.paramMap.get("id");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId")
      ? this.activatedRoute.snapshot.paramMap.get("userId")
      : authService.getUser()._id;
    this.load();
  }

  async ngOnInit() {
    await this.load();
    if (!this.property.eccovals[this.eccovalIdx].cmaprice) {
      const modal = await this.modalCtl.create({
        component: InputCmaModal,
        componentProps: { property: this.property },
        cssClass: "input-cma-modal",
      });
      modal.present();
    }
  }

  private async showLoader() {
    const loader = await this.loadCtl.create();
    loader.present();
    return loader;
  }

  private async showToast(message) {
    const toast = await this.toastCtl.create({
      message,
      duration: 3000,
      position: "top",
    });
    toast.present();
  }

  private async load() {
    const loader = await this.showLoader();
    try {
      const p = await this.propertyService
        .findById(this.propertyId)
        .toPromise();
      this.property = p;
      this.eccovalIdx = p.eccovals.findIndex(
        (e) => e.created_by === this.userId
      );
    } catch (e) {
      console.error("Error loading property");
      this.toastCtl
        .create({
          message: "Error loading Property.",
          duration: 3000,
          position: "top",
        })
        .then((t) => t.present());
    }
    loader.dismiss();
  }

  // ---------------------------------- CMA ---------------------------------- //
  private async updateCMA(cmaprice) {
    console.log("updating cma");
    const priceStr = cmaprice ? cmaprice : $("#updateCMA").val();
    const price = Number(priceStr.replace(/[^0-9.-]+/g, ""));
    const loader = await this.showLoader();
    try {
      const { property, valu } = await this.propertyService.updateCMA(
        this.propertyId,
        price
      );
      this.property = property;
    } catch (e) {
      console.error(`Error updating the CMA: `, e);
    }
    this.inputcma = false;
    loader.dismiss();
  }

  private toggleInputCMA() {
    this.inputcma = !this.inputcma;
    if (this.inputcma) {
      this.inputcmaVal = this.property.eccovals[this.eccovalIdx].cmaprice;
    }
  }

  private async autofillCMA() {
    const loader = await this.showLoader();
    try {
      const { property, cmaPerSqFt } = await this.propertyService
        .autofillCma(this.propertyId)
        .toPromise();
      this.property = property;
    } catch (e) {
      console.error(`Error autofilling CMA`);
    }
    loader.dismiss();
  }

  private formatAddress(comp: ZillowComp) {
    // return `${comp.address.streetAddress}, ${comp.address.city}, ${comp.address.state} ${comp.address.zipcode}`
    // from v3 change thougj none of this file may matter lol
    return comp.address;
  }

  // ---------------------------------- Comps ---------------------------------- //
  private viewComps = async () => {
    console.log("-- this is handled correct");
    const modal = await this.modalCtl.create({
      component: CompsModal,
      componentProps: { property: this.property },
      cssClass: "my-modal-class",
      backdropDismiss: false,
    });

    console.log("-- 2!!");
    modal.onDidDismiss().then(async ({ data }) => {
      if (data.updateOnClose) {
        alert("Need to update property");
      }
    });

    await modal.present();
  };

  // ---------------------------------- Slider ---------------------------------- //
  // private async sliderChange(mod, { value }) {
  private async sliderChange(mod, value) {
    console.log("Slider Change");
    console.log("Mod = ", mod);
    console.log("value = ", value);
    const loader = await this.showLoader();
    try {
      const { property, valu } = await this.propertyService.updateSlider(
        this.propertyId,
        mod._id,
        value
      );
      console.log("Property = ", property);
      console.log("Valu = ", valu);
      this.property = property;
    } catch (e) {
      console.error(`Error setting slider value: `, e);
    }
    loader.dismiss();
  }

  private valumod = "Interior";
  private displayMods = true;
  private displayComps = false;
  private displayCma = false; // note we'll probably just have this as an input
  private displayValulist = true;
  private toggle(card) {
    if (card === "mod") this.displayMods = !this.displayMods;
    if (card === "comps") this.displayComps = !this.displayComps;
    if (card === "cma") this.displayCma = !this.displayCma;
    if (card === "valulist") this.displayValulist = !this.displayValulist;
  }
  private getMods(mod) {
    return this.property.eccovals[this.eccovalIdx].modifiers.filter(
      (m) => m.type === mod
    );
  }
  // note cant customize the pin display value in ionic 5
  private pinformatter(evt) {
    const value = evt.detail.value;
    const pins = [
      null,
      "Significantly Below Average",
      "Below Average",
      "Slightly Below Average",
      "Average",
      "Slightly Above Average",
      "Above Average",
      "Significantly Above Average",
    ];
    console.log("rangepin = ", pins[value]);
    console.log(`$('.range-pin') = `, $(".range-pin"));
    $(".range-pin").val(pins[value]);
  }
  private onMouseUp(event, mod) {
    console.log("-- mouseup", event);
    console.log($(`#${mod._id} input`).val());
    this.sliderChange(mod, $(`#${mod._id} input`).val());
  }

  private async addValu() {
    const modal = await this.modalCtl.create({
      component: AddValuModal,
      componentProps: { property: this.property },
    });
    await modal.present();

    modal.onDidDismiss().then(async ({ data }) => {
      if (data.reload) {
        await this.showToast("Successfully added valu");
        await this.load();
      }
    });
  }

  reorderImages(id) {
    this.nav.navigateForward("reorder-property-images", {
      state: { sourceProperty: this.property },
    });
  }
}
