import { Component, Input, NgModule, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";

@Component({
  selector: 'register-subuser-modal',
  templateUrl: './register-subuser-modal.component.html',
  styleUrls: ['./register-subuser-modal.component.scss']
})
export class RegisterSubuserModal {
  loading = false
  form = this.fb.group({
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    email: ['', [
      Validators.required, 
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$') // Validator.email allows a@b
    ]],
    phone: ['', [
      Validators.required, 
      Validators.pattern(/\d{10}/),
      Validators.maxLength(10),
    ]],
  })
  nextTier: string // if user needs to upgrade sub before adding the user, we ensure they follow through first
  user: IUser
  acknowledged = true
  currentPrice
  nextPrice

	constructor(
    public params: NavParams,
		public viewCtrl: ModalController,
    public userService: UserAdminService,
    public auth: AuthService,
    public toastCtl: ToastController,
    public fb: FormBuilder,
    public toastController: ToastController,
    public authService: AuthService
  ) {
    // this.tier = params.data.tier
    // this.user = params.data.user ? params.data.user : this.auth.getUser()
    // this.onChange = this.onChange.bind(this)
    this.nextTier = params.data.nextTier
    this.user = params.data.user
    this.acknowledged = this.nextTier ? false : true
    this.currentPrice = params.data.currentPrice
    this.nextPrice = params.data.nextPrice
  }

  cancel() {
    this.viewCtrl.dismiss({ reload: false })
  }

  async submit() {
    this.loading = true
    console.log(this.fb)

    try {
      if (this.nextTier) {
        await this.upgradeTier()
      }
      await this.userService.addSubuser({
        firstname: this.form.get('firstname').value,
        lastname: this.form.get('lastname').value,
        mail: this.form.get('email').value,
        phone: this.form.get('phone').value
      }).toPromise()
      const toast = await this.toastCtl.create({ position: 'top', duration: 3000, message: 'Subuser added successfully' })
      toast.present()
      this.viewCtrl.dismiss({ reload: true })
    } catch (e) {
      console.error(`Error adding subuser: `, e)
      const toast = await this.toastCtl.create({ position: 'top', duration: 3000, message: 'Error adding subuser' })
      toast.present()
    }
    this.loading = false
  }

  /**
   * upgrade tiers right before the user has committed to creating the user
   */
  async upgradeTier() {
    try {
      await this.userService.setSub(this.user._id, this.nextTier)
      this.user = await this.authService.getFreshUser(true)
    } catch (e) {
      console.error(`Error upgrading tier: `, e)
      const toast = await this.toastController.create({ position: 'top', duration: 3000, message: 'Error upgrading. Try again later.' })
      toast.present()
    }
  }

}