import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { IAddress, IProperty } from "src/app/interfaces/property.interface";
import { PropertyDetailsModal } from "src/app/pages/property-details-modal/property-details-modal.component";
import { AuthService } from "src/app/services/auth.service";
import { PropertyService } from "src/app/services/property.service";

@Component({
  selector: 'card-search-property',
  templateUrl: './card-search-property.component.html',
  providers: [PropertyService]
})
export class CardSearchProperty implements OnInit {
  @Input() property: IProperty;
  @Input() addressObj: IAddress;
  mapsUrl = ""

  private modalCtrl: any;
  private alreadyClaiming = false // fixes a double click

  constructor(
    public propertyService: PropertyService,
    public modalController: ModalController,
    public navController: NavController,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.mapsUrl = `https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=600x300&maptype=roadmap&markers=color:red%7Clabel:C%7C${encodeURIComponent(this.addressObj.formatted_address)}&key=AIzaSyDdrX2CXoRUPf8MXt-LnYzlUtsnBc_LBGw`
  }

  async claim() {
    /**
     * @note claiming does a double click, so we'll lock the value
     */
    if (this.alreadyClaiming) {
      return
    }
    this.alreadyClaiming = true

    const eccoval = this.property.eccovals.find(e => e.created_by == this.authService.getUser()._id)
    const alreadyClaimed = !!eccoval
    let alreadyPurchased = false
    if (eccoval && eccoval.purchased) {
      alreadyPurchased = true
    }

    let purchased = false
    if (this.authService.getUser().tier === 'TRIAL' && !alreadyPurchased) {
      if (!confirm(`Click "OK" to VALU this property or "Cancel" to exit without saving. By clicking "OK", you agree to a $10 charge to card on file.`)) {
        return
      }
      // charge the user 10 dollars for this property
      // this.alreadyClaiming = true
      const paymentIntent = await this.propertyService.purchase(this.property._id)
      purchased = true
      // console.log('--- paymentIntent = ', paymentIntent)
    }

    // if the property is already claimed, fon't claim again.
    if (!alreadyClaimed) {
      await this.propertyService.claim(this.property, purchased).toPromise()
    }
    const propertyDetailsUrl = !(purchased || alreadyPurchased) ? `/property/${this.property._id}?tmp=true` : `/property/${this.property._id}`
    this.navController.navigateForward(propertyDetailsUrl)
  };
}
