import { Component } from '@angular/core';
import { NavController, ActionSheetController, ToastController, LoadingController } from '@ionic/angular';
import {LocationService} from "../../services/location.service";
import {PropertyService} from "../../services/property.service";
import {SourcedataService} from "../../services/sourcedata.service";
import {ISearchParams} from "../../services/sourcedata.service";
import {ISearchParam} from "../../services/sourcedata.service";

import {HomePage} from "../home/home";
import { TITLES } from 'src/app/util/constants';

declare var cordova: any;

@Component({
  selector: 'page-createproperty',
  templateUrl: 'createproperty.html',
  styleUrls: ['./createproperty.scss']
})
export class CreatePropertyPage {
  lastImage: string = null;
  // location: any;
  // geocodebuttons: any= [];
  actionSheet: any;
  public searchparams: ISearchParams = { 'empty': {display: '', type: '', key: '', value: ''} };

  public staticsearch = {
    streetname: "empty",
    streetnumber: "empty",
    city: "empty",
    postalcode: "empty"
  };
  private pool: number;



  constructor(
    public navCtrl: NavController,
    public actionSheetCtrl: ActionSheetController,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    public locationService: LocationService,
    public propertyService: PropertyService,
    public sourcedataService: SourcedataService
  ) {
    document.title = TITLES.CREATE_PROPERTY
      /*
        this.geocodebuttons.push(
            {
            text: 'Loading...',
            role: 'cancel',
            handler: () => {
                this.navCtrl.navigateRoot('home');
            }
          }
        );

        this.geocodebuttons.push(
          {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
              this.navCtrl.navigateRoot('home');
          }
        }
      );*/
      /*locationService.getWatch().subscribe((data) => {
        if (data && data.coords){
          this.location = data;
        } else {
          //use a default for when one is not found.
          this.location = { "coords": { "latitude":33.538331, "longitude":-117.13794299999999 } };
        }
      });*/

      /*sourcedataService.getParamFields().subscribe(
        (params: ISearchParams) => {
          this.staticsearch = {
            streetname: "StreetName",
            streetnumber: "StreetNumberNumeric",
            city: "City",
            postalcode: "PostalCode"
          };

          this.searchparams = params;

          if (this.location && this.location.coords) {
            this.locationService.getAddress(this.location.coords.latitude, this.location.coords.longitude).subscribe((goecodes: any) => {
              if (goecodes && goecodes.length > 1) {
                this.searchparams[this.staticsearch.streetname].value = goecodes[1].address_components[1].short_name;
                this.searchparams[this.staticsearch.city].value = goecodes[1].address_components[2].short_name;
                this.searchparams[this.staticsearch.postalcode].value = goecodes[1].address_components[6].short_name;
              }
            });
          }
        });*/

  }

//   public searchSourceData(){
//     this.sourcedataService.getProperties(this.searchparams).subscribe(
//       (property: any[]) => {
//         this.geocodebuttons = [];
//         property.forEach(element => {
//           this.geocodebuttons.push(
//                   {
//                       text: this.formatAddress(element),
//                       handler: () => {
//                         this.createProperty(element);
//                       }
//                     }
//               );
//           });
//           this.geocodebuttons.push(
//             {
//             text: 'Cancel',
//             role: 'cancel',
// /*            handler: () => {
//                 this.cancel();
//             }
// */
//           }
//         );
//         // KLB Item52: If the search query returns zero results:
//         // 1. Check if a full address has been entered, if so then call the geocode (on location.service.getgeocode)
//         // service to check Google for the addres.
//         // 2. If the address is not complete, then tell the user to add more details and retry their lookup.
//         // 3. If the address is not found suing this lookup and instead falls back to #1,
//         // ask the user to enter the details that are shown in the property page
//         // (other than the pictures which can be uploaded from the property page).
//         this.presentActionSheet();
//       });
//
//
//   }

  private formatAddress(element: any){
    if (element.formatted_address){
      return element.formatted_address;
    } else {
      return element.StreetNumberNumeric + ' ' + element.StreetName + ' ' + element.City + ', ' + element.StateOrProvince + ' ' + element.PostalCode;
    }
  }

  // public presentActionSheet() {
  //   if (this.actionSheet){
  //     this.actionSheet.dismiss();
  //   }
  //   this.actionSheet = this.actionSheetCtrl.create({
  //     title: 'Select an Address',
  //     buttons: this.geocodebuttons
  //     /*[
  //       {
  //         text: 'Load from Library',
  //         handler: () => {
  //           this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
  //         }
  //       },
  //       {
  //         text: 'Use Camera',
  //         handler: () => {
  //           this.takePicture(this.camera.PictureSourceType.CAMERA);
  //         }
  //       },
  //       {
  //         text: 'Cancel',
  //         role: 'cancel'
  //       }
  //     ]*/
  //   });
  //   this.actionSheet.present();
  //
  // }

  // public staticsearch = {
  //   streetname: "empty",
  //   streetnumber: "empty",
  //   city: "empty",
  //   postalcode: "empty"
  // };

  public createProperty(){
    // lets do some basic validation
    if (!this.street_number || !this.street_name || this.street_name == '' || !this.city || this.city == '' || !this.state || this.state == '' || !this.zip || !this.sqft || !this.pool || this.property_type == '' || !this.lot_size || !this.year_built) {
      alert("please ensure all fields are filled in.");
      return;
    }

    // need to build up a property object
    let newProperty = {
      formatted_address: "",
      StreetNumberNumeric: this.street_number,
      StreetName: this.street_name,
      City: this.city,
      StateOrProvince: this.state,
      PostalCode: this.zip,
      LivingArea: this.sqft,
      LotSizeAcres: this.lot_size,
      PoolPrivateYN: this.pool,
      PropertySubType: this.property_type,
      YearBuilt: this.year_built,
      eccoval: {
        modifiers: [],
        cmaprice: 0,
        priceadjustment: 0,
        pricerecomended: 0,
        minprice: 0,
        maxprice: 0
      },
      images: [],
      geocode: {}
    };

    newProperty.formatted_address = this.formatAddress(newProperty);

    this.propertyService.save(newProperty).subscribe(
        (property) => {
          this.toastCtrl.create({
            message: 'Property Saved Successfully!',
            duration: 3000,
            position: 'top'
          }).then(ctl => {
            ctl.present();
          });

          this.navCtrl.navigateRoot('home');
    });
  }
/*
  public takePicture(sourceType) {
    // Create options for the Camera Dialog
    var options = {
        destinationType: this.camera.DestinationType.DATA_URL,
        quality: 100,
        sourceType: sourceType,
        saveToPhotoAlbum: false,
        correctOrientation: true
    };


    }*/
  street_number: number;
  street_name: string = '';
  city: string = '';
  zip: number;
  state: string = '';
  sqft: string;
  lot_size: string;
  property_type: string;
  year_built: string;

  cancel(){
    this.navCtrl.navigateRoot('home');
  }

}
