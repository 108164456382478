import { Component, Input } from "@angular/core";

@Component({
	selector: 'vc-btn',
	template: `
		<ion-button [id]="id ? id : null" [disabled]="disabled || loading" (click)="click()" [ngStyle]="ngStyle" [ngClass]="ngClass" [color]="color">
			<ion-label style="display: flex; align-items: center; justify-content: center">
				<ion-spinner *ngIf="loading" name="lines-small"></ion-spinner>
				<span>{{txt}}</span>
			</ion-label>
		</ion-button>
	`,
	styleUrls: ['vc-btn.component.scss']
})
export class VcBtn {
	@Input() loading = false
	@Input() disabled = false
	@Input() txt = 'Submit'
	@Input() click
	@Input() id
	@Input() ngStyle = {}
	@Input() ngClass = []
	@Input() color = "primary"
	constructor() {}
}