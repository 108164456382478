import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { AgGridAngular } from "ag-grid-angular";
import {
  CellClickedEvent,
  ColDef,
  GridOptions,
  GridPanel,
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import * as moment from "moment";
import * as _ from "lodash";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { getCreatedDate } from "src/app/util/util";

const FMT = "MM/DD/YYYY hh:mma";
const toUnix = (_id) => getCreatedDate(_id).getTime();
const fmtDate = (d, fmt = FMT) => moment(d).format(fmt);

interface Table {
  cols: ColDef[];
  defColDef: ColDef;
  gridOptions: GridOptions;
}
const userTable: Table = {
  cols: [
    {
      headerName: "Name",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      valueGetter: (p) => `${p.data.firstname} ${p.data.lastname}`,
      minWidth: 170,
    },
    {
      field: "local.email",
      headerName: "Email",
      minWidth: 170,
    },
    {
      headerName: "Created At",
      minWidth: 175,
      valueGetter: (p) => fmtDate(getCreatedDate(p.data._id)),
      comparator: (valueA, valueB) => {
        const a = moment(valueA, FMT).unix();
        const b = moment(valueB, FMT).unix();
        return a - b;
      },
    },
    {
      headerName: "Role",
      minWidth: 82,
      valueGetter: (p) =>
        p.data.roles.includes("admin")
          ? "ADMIN"
          : p.data.account_lead
          ? "Lead"
          : "Subuser",
    },
    {
      field: "tier",
      headerName: "Tier",
      minWidth: 100,
    },
    {
      headerName: "Logins",
      minWidth: 100,
      valueGetter: (p) => p.data.logins.length,
    },
    {
      headerName: "Last Login",
      minWidth: 175,
      valueGetter: (p) => {
        const lastLogin = _.last(p.data.logins);
        return lastLogin
          ? moment(_.last(p.data.logins).date).format(FMT)
          : null;
      },
      comparator: (valueA, valueB) => {
        if (!valueA && !valueB) return 0;
        else if (!valueA && valueB) return -1;
        else if (!valueB && valueA) return 1;
        const a = moment(valueA, FMT).unix();
        const b = moment(valueB, FMT).unix();
        return a - b;
      },
    },
    {
      headerName: "Admin Invite",
      minWidth: 150,
      valueGetter: (p) => {
        if (!p.data.adminInvite) return "No";
        return `Yes (${p.data.freeTrialDays} free trial days)`;
      },
    },
    {
      headerName: "Stripe Link",
      field: "stripe_customer_id",
      minWidth: 200,
      cellRenderer: (p) =>
        p.value
          ? `<a href="https://dashboard.stripe.com/customers/${p.value}" target="_blank">${p.value}</a>`
          : '<span style="color: #AAA">n/a</span>',
    },
  ],
  defColDef: {
    sortable: true,
    filter: true,
    flex: 1,
    resizable: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  gridOptions: {
    pagination: true,
    paginationPageSize: 10,
    // paginationAutoPageSize: true,
  },
};

@Component({
  selector: "user-table",
  templateUrl: "user-table.component.html",
  styleUrls: ["user-table.component.scss"],
})
export class UserTable implements OnInit {
  loading = true;
  users: IUser[];

  // grid props
  cols: ColDef[] = userTable.cols;
  defColDef: ColDef = userTable.defColDef;
  gridOptions: GridOptions = userTable.gridOptions;
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  selected: IUser[] = [];
  displayedRows = 0;

  search: "";
  deleting = false;
  pageLength = 10;

  constructor(
    public userService: UserAdminService,
    public auth: AuthService,
    public toastCtl: ToastController
  ) {}

  async ngOnInit() {}

  private async showToast(message) {
    const toast = await this.toastCtl.create({
      message,
      duration: 3000,
      position: "top",
    });
    toast.present();
  }

  async load() {
    this.loading = true;
    this.agGrid.api.showLoadingOverlay();
    try {
      this.users = await this.userService
        .adminFindAll()
        .then((users) => users.sort((a, b) => toUnix(b._id) - toUnix(a._id)));
      this.agGrid.api.setRowData(this.users);
      this.displayedRows = this.users.length;
      console.log(this.users);
    } catch (e) {
      console.error("Error loading users: ", e);
      await this.showToast("Error loading users");
    }
    this.agGrid.api.hideOverlay();
    this.loading = false;
  }

  get filteredUsers() {
    if (!this.users) return [];
    if (!this.search) return this.users;
    console.log("-- getting filteredUsers");
    return this.users.filter((u) => {
      const searchableFields = ["firstname", "lastname", "local.email"];
      return searchableFields
        .map((k) => _.get(u, k))
        .some((v) => v.includes(this.search));
    });
  }

  // ------------------------------------ Grid Methods
  async onGridReady(params: GridReadyEvent) {
    this.agGrid.gridOptions.pagination = true;
    this.agGrid.gridOptions.paginationPageSize = 10;
    await this.load();
    this.agGrid.api.sizeColumnsToFit();
    const me = this.auth.getUser();
    this.agGrid.gridOptions.isRowSelectable = (node) =>
      node.data._id !== me._id;
  }

  async onSelectionChanged(params: SelectionChangedEvent) {
    const selected = params.api.getSelectedNodes();
    this.selected = selected.map((row) => row.data);
  }

  async onCellClicked(e: CellClickedEvent) {}

  // ------------------------------------ Other
  onSearch(val) {
    console.log("--- ", val);
    this.agGrid.api.onFilterChanged();
  }

  filterUsers(event) {
    const value = event.detail.value.toLowerCase();
    const fields = this.cols.map((def) => def.field);
    this.agGrid.api.setRowData(
      this.users.filter((u) => {
        return fields.find((field) =>
          typeof _.get(u, field) === "string"
            ? _.get(u, field).includes(value)
            : false
        );
      })
    );
    this.displayedRows = this.agGrid.api.getDisplayedRowCount();
  }

  isExternalFilterPresent = () => {
    return !!this.search;
  };

  doesUserPassFilter = (node: any): boolean => {
    if (node.data) {
      const search = this.search.toLowerCase();
      const name = `${node.data.firstname} ${node.data.lastname}`.toLowerCase();
      const email = node.data.local.email.toLowerCase();
      return name.includes(search) || email.includes(search);
    }
    return true;
  };

  async deleteUsers() {
    const msg = `Are you sure you would like to delete ${this.selected.length} users?`;
    if (confirm(msg)) {
      console.log("DELETE: ", this.selected);
      this.deleting = true;
      const requests = this.selected.map((u) =>
        this.userService.delete(u._id).toPromise()
      );
      try {
        await Promise.all(requests);
        await this.showToast(
          `All ${requests.length} users successfully deleted`
        );
        await this.load();
      } catch (e) {
        await this.showToast("Some users may have failed to delete.");
      }
      this.deleting = false;
    }
  }

  changePageLength(val) {
    this.gridOptions.api.paginationSetPageSize(parseInt(val));
  }
}
