import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "cma-dom-input",
  templateUrl: "cma-dom-input.component.html",
  styleUrls: ["cma-dom-input.component.scss"],
})
export class CmaDOMInput {
  @Input() cmaDOMInput: FormControl;
  @Input() autofilling: boolean;
  @Input() autofillCmaDOM: () => void;
  @Input() onSave: () => void;
  @Input() disabled: boolean;
  constructor() {}
}
