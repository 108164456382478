import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IUser, UserLeadgenTemplate } from "../interfaces/user.interface";
import { MESSAGE_2 } from "../components/share-valu-modal/share-valu-constants";

@Injectable()
export class UserAdminService {
  constructor(private readonly httpClient: HttpClient) {}

  save(user: any): Observable<IUser> {
    console.log("got user save: ", user);
    if (!!user._id) {
      return this.httpClient.post<IUser>("users/profile-update", user);
    } else {
      // the user is not created this way, they self-register.
    }
  }

  saveCard(cardnonce: any): Observable<IUser> {
    //if (!!user._id){
    const card = { cardnonce: cardnonce };

    return this.httpClient.post<IUser>("users/update-card", card); // TODO: need to make this an actual object
    //} else {
    // the user is not created this way, they self-register.
    //}
  }

  getRecord(id: string): Observable<IUser> {
    return this.httpClient.get<IUser>("users/byid/" + id);
  }

  /* --We may use these later to allow logos to be uploaded....
    uploadimage(imageData: any): Observable<Iuser> {
      let headers = new HttpHeaders();
      //headers.append('Accept', 'application/json');
      //headers.append("Content-Type", "application/json");
      headers.append("Content-Type", 'multipart/form-data');
  
      return this.httpClient.post('property/image', imageData, { headers: headers });
    }
  
    getImage(imgId){
      if (!imgId){
        return 'assets/img/logo.png';
      }
      return this.configService.getSvcURL("property/image?imgId=" + imgId)
    }
  
    */

  delete(user: any): Observable<IUser> {
    if (!user.id) {
      return this.httpClient.get<IUser>("users/delete?id=" + user);
    } else {
      return this.httpClient.get<IUser>("users/delete?id=" + user.id);
    }
  }

  deleteSubuser(user: any): Observable<IUser> {
    if (!user._id) {
      return this.httpClient.delete<IUser>("users/delete/subuser?id=" + user);
    } else {
      return this.httpClient.delete<IUser>(
        "users/delete/subuser?id=" + user._id
      );
    }
  }

  findAll(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>("users/");
  }

  adminFindAll(): Promise<IUser[]> {
    return this.httpClient.get<IUser[]>("users/admin").toPromise();
  }

  findById(id: any): Observable<IUser> {
    return this.httpClient.get<IUser>("users/byid?id=" + id);
  }

  findByBrokerageCode(brokerage_code: string): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(
      `users/by_brokerage_code?brokerage_code=${brokerage_code}`
    );
  }

  // TODO
  // async findAccountLead(id: string): Observable<IUser> {}

  addSubuser(data: any) {
    return this.httpClient.post<any>(`users/subuser`, data);
  }

  inviteAccountLead(data: any) {
    return this.httpClient.post<any>(`users/invite/lead`, data).toPromise();
  }

  getUserInvite(id: string, code: string) {
    return this.httpClient.get<IUser>(`users/invite?id=${id}&code=${code}`);
  }

  resendInvite(user: IUser) {
    return this.httpClient.post<IUser>(
      `users/invite/resend?id=${user._id}`,
      {}
    );
  }

  /**
   * Get the payment info/status for a given user.
   * API is available to multiple users if Admin, otherwise only available to themselves.
   * @param id User ID
   * @returns Payment Info object
   */
  getStripeInfo(id: string): Promise<any> {
    return this.httpClient
      .get<any>(`users/paymentInfo?id=${id}`, {})
      .toPromise();
  }

  /**
   * Similar to getStripeInfo but simpler info for registration page.
   * @param id
   * @returns
   */
  getPaymentMethods(id: string): Promise<any> {
    return this.httpClient
      .get<any>(`users/paymentInfo/paymentmethods?id=${id}`, {})
      .toPromise();
  }

  /**
   * Create or update the users subscription.
   */
  setSub(
    userId: string,
    tier: string,
    coupon = null,
    freeTrialCode = null
  ): Promise<any> {
    return this.httpClient
      .post<any>(
        `users/subscription?id=${userId}&tier=${tier}&coupon=${
          coupon ? coupon : ""
        }&freeTrialCode=${freeTrialCode ? freeTrialCode : ""}`,
        {}
      )
      .toPromise();
  }

  syncPayment(user: IUser) {
    return this.httpClient
      .post<any>(`users/subscription/sync-payment-method`, { user })
      .toPromise();
  }

  payInvoiceAndSyncSubscriptionPayment(latestInvoice, paymentMethod) {
    return this.httpClient
      .post<any>(`users/subscription/invoice`, {
        latestInvoice,
        paymentMethod,
      })
      .toPromise();
  }

  /**
   * Get a stripe setup intent to submit payment method info.
   */
  getStripeIntent(id: string): Promise<any> {
    return this.httpClient.get<any>(`users/setupIntent?id=${id}`).toPromise();
  }

  /**
   * @deprecated ?
   * Similar to AuthService.sendActivationEmail, but here we don't need a password.
   * If I'm authenticated, I can send myself an activation email.
   */
  getActivationEmail(id: string): Promise<{
    user: IUser;
    updated: boolean;
    subscription: any;
    currSubscription: any;
  }> {
    return this.httpClient
      .get<any>(`users/activation-email?id=${id}`)
      .toPromise();
  }

  deletePaymentMethod(userId: string, pmId: string): Promise<any> {
    return this.httpClient
      .request<any>("delete", `users/payment-method`, {
        body: { userId, pmId },
      })
      .toPromise();
  }

  checkEmail(email: string): Observable<boolean> {
    return this.httpClient.get<any>(`users/availability?q=${email}`);
  }

  updateEmail(email: string, password: string): Promise<IUser> {
    return this.httpClient
      .post<any>(`users/update/email`, { email, password })
      .toPromise();
  }

  /** @todo add subject */
  saveTemplate(userId: string, template: UserLeadgenTemplate): Promise<any> {
    return this.httpClient
      .post<any>(`users/leadgenTemplates`, { userId, template })
      .toPromise();
  }

  getLeadgenSubscription(
    userId: string
  ): Promise<{ subscription: any | null; hasLeadgenSub: boolean }> {
    return this.httpClient
      .get<{ subscription: any | null; hasLeadgenSub: boolean }>(
        `users/leadgen/subscription?id=${userId}`
      )
      .toPromise();
  }

  upgradeLeadgenSubscription(
    user: IUser
  ): Promise<{ subscription: any | null }> {
    return this.httpClient
      .post<{ subscription: any | null }>(`users/leadgen/subscription`, {
        user,
      })
      .toPromise();
  }

  /**
   * Fetch the ordered user templates
   * @param userId User to fetch templates for
   * @param addCustom Helper to add the "next-to-add" template
   * @returns
   */
  async getLeadgenTemplates(
    userId: string,
    addCustom = false
  ): Promise<UserLeadgenTemplate[]> {
    const getDefaultTemplate = (user: IUser): UserLeadgenTemplate => ({
      _id: null,
      name: "Default",
      subject: `See your home's value!`,
      content: MESSAGE_2(user),
      isDefault: true,
    });

    const user = await this.findById(userId).toPromise();
    if (!user) return [];

    // if the user has no saved templates, they get the default
    // if they do, we'll order them with the default(s) ordered first
    const templates = user.leadgenTemplates.length
      ? user.leadgenTemplates.sort((a, b) => (a.isDefault ? -1 : 0))
      : [getDefaultTemplate(user)];

    if (addCustom) {
      templates.push({
        _id: null,
        name: "Custom",
        subject: null,
        content: "",
        isDefault: false,
      });
    }

    return templates;
  }
}
