import { Component } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';

import { ModifierService } from '../../services/modifier-service';

import { HomePage } from '../home/home';

import * as XLSX from 'xlsx';
import { ModifierAddModal } from "./modifier-add-modal/modifier-add-modal.component";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";


@Component({
  selector: 'page-modifier-admin',
  templateUrl: 'modifier-admin.html'
})
export class ModifierAdminPage {
  //functions
  public Math = Math;
  actionSheet: any;

  public selectedModifier: any;
  private gridApi;
  private gridColumnApi;
  private modifiers: any[];
  // private defaultColDef = { resizable: true }


  constructor(
    public nav: NavController,
    public modifierService: ModifierService,
    public modalController: ModalController,
    public toastCtrl: ToastController) {

  }

  loadModifiers() {
    this.modifierService.findAll().subscribe(
      (modifiers: [any]) => {
        console.log("modifiers: ", modifiers);
        this.modifiers = modifiers;
      });
  }

  removeModifier(obj) {
    console.log("got object: ", obj, obj['_id']);
    // for now just drop the row -- need to add a confirm
    this.modifierService.delete(obj).subscribe((v) => {
      console.log("row removed: ", v);
      this.toastCtrl.create({
        message: 'Feature VALUmodifier Removed',
        duration: 3000,
        position: 'top'
      }).then((ctl) => {
        ctl.present();
      });
      this.loadModifiers();
    });
  }


  private modifierColumnDefs = [
    { headerName: 'Title', field: 'title', editable: false, sortable: true, filter: 'agTextColumnFilter', resizable: true },
    { headerName: 'Zip', field: 'zip', editable: false, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'City', field: 'country', editable: false, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'State', field: 'state', editable: false, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Type', field: 'type', editable: false, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'S1V', field: 's1v', editable: false, sortable: true, filter: 'agNumberColumnFilter' },
    { headerName: 'S2V', field: 's2v', editable: false, sortable: true, filter: 'agNumberColumnFilter' },
    { headerName: 'S3V', field: 's3v', editable: false, sortable: true, filter: 'agNumberColumnFilter' },
    { headerName: 'S4V', field: 's4v', editable: false, sortable: true, filter: 'agNumberColumnFilter' },
    { headerName: 'S5V', field: 's5v', editable: false, sortable: true, filter: 'agNumberColumnFilter' },
    { headerName: 'S6V', field: 's6v', editable: false, sortable: true, filter: 'agNumberColumnFilter' },
    { headerName: 'S7V', field: 's7v', editable: false, sortable: true, filter: 'agNumberColumnFilter' },
    {
      headerName: 'Actions', field: '', editable: false, filter: false,
      onCellClicked: (params) => {
        if (confirm("Are you sure you wish to delete this row?")) {
          this.removeModifier(params['data']);
        }
      }, cellRenderer: (params) => {
        return '<button class="btn btn-danger">Delete</button>';
      }
    }
  ];

  cancel() {
    this.nav.navigateRoot('home');
  }

  onModifierRowClicked(params) {
    if (params.node.selected) {
      this.selectedModifier = params.node.data;
    } else {
      this.selectedModifier = undefined;
      let element = <HTMLElement>document.querySelector("#modifiers");
      element.style.width = "90vw";
    }
    //this.gridColumnApi.setColumnsVisible(["name", "supportemail", "defaultroles", "supportname", "supportphone", "inactive"], !params.node.selected);
    //params.node.setSelected(params.node.selected, !params.node.selected);
    return true;
    //
  }

  onManifestGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadModifiers();
  }

  onUserGridReady(params) {
    params.api.sizeColumnsToFit();
  }


  private file: any;
  private arrayBuffer: any;
  private filelist: any[];

  addfile(event) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, { type: "binary" });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      let arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      // loop over the data and add the records
      arraylist.forEach((a) => {
        console.log('request to save: ', a);
        // convert the excel object to our internal object schema
        let _tmp = {
          'title': a['Title'],
          'type': a['Type'],
          'country': a['County'],
          'city': a['City'],
          'state': a['State'],
          'zip': a['Zip/Postal Code'],
          's1v': a['S1V'],
          's2v': a['S2V'],
          's3v': a['S3V'],
          's4v': a['S4V'],
          's5v': a['S5V'],
          's6v': a['S6V'],
          's7v': a['S7V']
        };

        if (_tmp.title && _tmp.city && _tmp.state && _tmp.zip) {
          this.modifierService.save(_tmp).subscribe((v) => {
            this.loadModifiers();
          });
        }
      })

      this.toastCtrl.create({
        message: 'Worksheet Import Complete: ' + arraylist.length + ' rows.',
        duration: 3000,
        position: 'top'
      }).then((ctl) => {
        ctl.present();
      });
    }
  }

  onGridSizeChanged(params) {
    //this.gridApi.sizeColumnsToFit();
    params.api.sizeColumnsToFit();
  }

  boleanColumnRenderer(params) {
    return (!params.value ? '<b>Inactive</b>' : '<b>Active</b>');
  }

  private addModifierModalCtrl: any;

  addNewModifier() {
    this.addModifierModalCtrl = this.modalController.create({ component: ModifierAddModal, cssClass: 'epd-modal' }).then(ctl => {
      ctl.onDidDismiss().then(data => {
        this.loadModifiers();
      });
      ctl.present();
    });
  }

  // declaring an inner component for the delete button



}
