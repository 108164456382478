/**
 * Allow users to add and remove members of their team.
 */
import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { LoadingController, ModalController, ToastController } from "@ionic/angular";
import { AddUserForm } from "src/app/components/add-user-form/add-user-form.component";
import { RegisterSubuserModal } from "src/app/components/register-subuser-modal/register-subuser-modal.component";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { TITLES } from "src/app/util/constants";


const DESCRIPTION_MAP = {
  BETA: 'Account Tier: Beta. $15 per month. Adding users requires an upgrade',
  INDIVIDUAL: 'Account Tier: Launch. $25 per user, per month',
  TEAM_1_5: 'Account Tier: Growth. $20 per user, per month',
  LAUNCH: 'Account Tier: Launch. $25 per month',
  GROWHT: 'Account Tier: Growth. $20 per user, per month',
}
const COST_MAP = {
  BETA: 15,
  INDIVIDUAL: 25,
  TEAM_1_5: 20,
  TRIAL: 0,
  LAUNCH: 25,
  GROWTH: 20
}

@Component({
  selector: 'page-team',
  templateUrl: 'team.html',
  styleUrls: ['./team.scss'],
})
export class TeamPage implements OnInit {
  private user: IUser;
  private subusers: IUser[] = [];
  private loading = false
  private tier = { description: '', monthlyCost: '' }

  constructor(
    private authService: AuthService,
    private userService: UserAdminService,
    private modalCtrl: ModalController,
    private toastController: ToastController,
		router: Router
  ) {
		// having some issues with subscription upgrade/downgrade being reflected in the UI
		// seems like overkill, but we just need the user to be loaded every time the page is rendered.
		router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				if (e.url === '/team') {
					this.user = this.authService.getUser()
				}
			}
		})	
	}

  ngOnInit() {
    document.title = TITLES.TEAM
    this.user = this.authService.getUser()
    this.load()
  }

  async load() {
    this.loading = true
    try {
      this.subusers = await this.userService.findByBrokerageCode(this.user.brokerage_code).toPromise()
      this.tier = {
        description: DESCRIPTION_MAP[this.user.tier],
        monthlyCost: `Current monthly cost: $${COST_MAP[this.user.tier] * this.subusers.length} per month`
      }
    } catch (e) {
      console.error(`Loading subusers: `, e)
      const toast = await this.toastController.create({ duration: 3000, message: 'Error loading subusers', position: 'top' })
      toast.present()
    }
    this.loading = false
  }

  openAddUserModal = async () => {
    // check if user needs to change account before this action
    let nextTier
    if (this.user.tier === 'BETA')
      nextTier = 'INDIVIDUAL'
    else if (this.user.tier === 'INDIVIDUAL' && this.subusers.length === 4)
      nextTier = 'TEAM_1_5'
    // update 4/29 - new tiers
    else if (this.user.tier === 'LAUNCH' && this.subusers.length >= 1)
      nextTier = 'GROWTH'
    else if (this.user.tier === 'TRIAL')
      nextTier = 'LAUNCH'

    const modal = await this.modalCtrl.create({
      component: RegisterSubuserModal,
      componentProps: {
        onExit: async () => {
          this.subusers = await this.userService.findByBrokerageCode(this.user.brokerage_code).toPromise()
          this.user = await this.authService.getFreshUser(true)
          await this.load()
        },
        nextTier,
        user: this.user,
        currentPrice: COST_MAP[this.user.tier],
        nextPrice: COST_MAP[nextTier]
      }
    })
    modal.present()
    modal.onDidDismiss().then(async ({ data }) => {
      if (data && data.reload) {
        this.user = await this.authService.getFreshUser(true)
        await this.load()
      }
    })
  }

  async delete(user: IUser) {
    if (confirm(`Are you sure you want to delete ${user.firstname} ${user.lastname}?`)) {
      this.loading = true
      try { 
        let nextTier
        if (this.subusers.length <= 5 && this.user.tier !== 'INDIVIDUAL') {
          if (!confirm(`Account must downgrade to Launch, and your cost will be $25 per user, per month. Proceed?`)) {
            this.loading = false
            return
          }
          nextTier = 'INDIVIDUAL'
        }
        await this.userService.deleteSubuser(user).toPromise()
        const toast = await this.toastController.create({ message: 'User successfully deleted', duration: 3000, position: 'top' })
        toast.present()
        this.subusers = await this.userService.findByBrokerageCode(this.user.brokerage_code).toPromise()
        if (nextTier) {
          await this.downgradeTier(nextTier)
        }
        await this.load()
      } catch (e) {
        console.log("ERROR deleting user:", e)
        const toast = await this.toastController.create({ message: 'Error deleting user. Try again later.', duration: 3000, position: 'top' })
        toast.present()
      }
      this.loading = false
    }
  }

  async resendInvite(user: IUser) {
    if (confirm(`Sure you want to resend invite to ${user.local.email}?`)) {
      this.loading = true
      try {
        await this.userService.resendInvite(user).toPromise()
        const toast = await this.toastController.create({ message: 'Invite resent!', duration: 3000, position: 'top' })
        toast.present()
      } catch (e) {
        console.log("Error resending invite: ", e)
        const toast = await this.toastController.create({ message: 'Error resending invite.', duration: 3000, position: 'top' })
        toast.present()
      }
      this.loading = false
    }
  }

  /**
   * Upgrade the account from TEAM_1_5 to TEAM_6 so we can add users again.
   */
  async upgradeTier() {
    if (!confirm('If you upgrade, you can add more users and will be at a lower cost. But billing will change.')) return

    try {
      await this.userService.setSub(this.user._id, 'TEAM_6')
      this.user = await this.authService.getFreshUser(true)
    } catch (e) {
      console.error(`Error upgrading tier: `, e)
      const toast = await this.toastController.create({ position: 'top', duration: 3000, message: 'Error upgrading. Try again later.' })
      toast.present()
    }
  }

  async downgradeTier(nextTier: string) {
    try {
      await this.userService.setSub(this.user._id, nextTier)
      this.user = await this.authService.getFreshUser(true)
    } catch (e) {
      console.error(`Error downgrading tier: `, e)
      const toast = await this.toastController.create({ position: 'top', duration: 3000, message: 'Error upgrading. Try again later.' })
      toast.present()
    }
  }
}