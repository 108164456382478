import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { NavController, ActionSheetController, ToastController, Platform, LoadingController, IonReorderGroup } from '@ionic/angular';
import { IProperty } from 'src/app/interfaces/property.interface';

import { ConfigService } from '../../services/config.service';
import { PropertyService } from "../../services/property.service";
import { TITLES } from 'src/app/util/constants';

declare var cordova: any;

@Component({
  selector: 'reorder-property-images',
  templateUrl: './reorder-property-images.html',
  styleUrls: ['./reorder-property-images.scss']
})
export class ReorderPropertyImages implements OnInit {
  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;

  private property: any;
  private dirty = false;
  private originalImages: string[];
  private updatedImages: string[];
  loading: any;

  constructor(
    public navCtrl: NavController, 
    private route: ActivatedRoute, 
    private router: Router,
    public actionSheetCtrl: ActionSheetController, 
    public toastCtrl: ToastController, 
    public platform: Platform,
    public loadingCtrl: LoadingController, 
    public propertyService: PropertyService
  ) {}

  ngOnInit(): void {
    document.title = TITLES.PROPERTY_IMAGES
    this.property = this.propertyService.defaultProperty();
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.property = this.router.getCurrentNavigation().extras.state.sourceProperty;
        delete this.property['__v']; // removing version attribute to avoid save issues
        console.log("THIS PROPERTY IS: ", this.property)
        this.originalImages = this.property.images
        this.updatedImages = this.property.images
      }
    });
  }

  async loadData(id: string) {
    this.property = await this.propertyService.findById(id).toPromise();
    this.updatedImages = this.property.images;
  }

  async doReorder(ev) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
    console.log(ev)

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    ev.detail.complete();

    // NOTE: we'll want this to be smarter, based on the ev.detail
    this.dirty = true;
    // console.log("PROPERTY IMAGES: ", this.property.images)
    // console.log("FROM ITEM:", this.property.images[ev.detail.from])
    // console.log("TO ITEM: ", this.property.images[ev.detail.to])
    const temp = this.updatedImages[ev.detail.from]
    this.updatedImages[ev.detail.from] = this.updatedImages[ev.detail.to]
    this.updatedImages[ev.detail.to] = temp

    this.showLoading(async () => {
      await this.update();
      this.loadingCtrl.dismiss()
    })
  }

  toggleReorderGroup() {
    this.reorderGroup.disabled = !this.reorderGroup.disabled;
  }

  async delete(evt, imgIdx) {
    console.log("EVT = ", evt)
    console.log("imageIdx = ", imgIdx)
    if (confirm('Sure you want to delete this image?')) {
      const updateProperty = {...this.property}
      updateProperty.images = this.property.images.filter((c, i) => i !== imgIdx)
      console.log("Updating property to: ", updateProperty)
      this.showLoading(async () => {
        try {
          const newProp = await this.propertyService.save(updateProperty).toPromise()
          console.log("... updated to ", newProp)
          this.property = newProp
          this.presentToast("Image successfully removed.")
        } catch(e) {
          console.log("ERROR REMOVING IMAGE")
          console.log(e)
          this.presentToast("Error removing image.")
        }
        this.loadingCtrl.dismiss()
      })
    }
  }

  async update() {
    const updatedProperty = { ...this.property }
    console.log("OG IMAGES: ", this.originalImages)
    console.log("IMAGES ARE: ", this.property.images)
    updatedProperty.images = this.property.images
    const newlyUpdated = await this.propertyService.save(updatedProperty).toPromise()
    console.log("NEWLY UPDATED: ", newlyUpdated)
    this.toastCtrl.create({
      message: 'Property Images Updated Successfully!',
      duration: 3000,
      position: 'top'
    }).then(ctl => {
      ctl.present();
      this.loadData(this.property._id);
    });
  }

  public handleFiles(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.showLoading(() => {
        const reader = new FileReader();
        reader.onload = e => {

          let formData = new FormData();
          formData.append('somekey', 'some value')
          formData.append('file', event.target.files[0]);

          this.propertyService.uploadimage(formData).subscribe(
            (image: any) => {
              //contains image.imgId
              //this.lastImage = reader.result
              this.saveImage(image);
            });
        };

        reader.readAsDataURL(file);
      });
    }
  }

  private saveImage(image) {
    if (!this.property.images.includes(image.imgId)) {
      this.property.images.push(image.imgId);
      this.propertyService.save(this.property).subscribe(
        (property) => {
          this.loading.dismiss();
          this.presentToast('Image succesful uploaded.');
          // this.navCtrl.back();
          this.property = property;
        });
    } else {
      // original... i think it's wrong
      // this.loading.dismiss();
      // this.presentToast('Image succesful uploaded.');
      // this.navCtrl.back();
      this.loading.dismiss()
      this.presentToast('Image already exists.')
    }
  }

  private showLoading(after: any) {
    this.loadingCtrl.create({}).then(ctl => {
      ctl.present();
      this.loading = ctl;
      after();
    });
  }
  
  private presentToast(text) {
        let toast = this.toastCtrl.create({
            message: text,
            duration: 3000,
            position: 'top'
        }).then(ctl => {
            ctl.present();
        });
        return toast;
    }
}
