import { Component, Input, NgModule, OnInit } from "@angular/core";
import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { IUser, VcPaymentMethod } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";

@Component({
  selector: 'payment-info-modal',
  templateUrl: './payment-info-modal.component.html',
  styleUrls: ['./payment-info-modal.component.scss']
})
export class PaymentInfoModal implements OnInit {
  paymentMethod: VcPaymentMethod
  user: IUser

  constructor(
    public params: NavParams,
		public viewCtrl: ModalController,
    public userService: UserAdminService,
    public auth: AuthService,
    public toastCtl: ToastController
  ) {
    // this.tier = params.data.tier
    // this.user = params.data.user ? params.data.user : this.auth.getUser()
    // this.onChange = this.onChange.bind(this)
    this.paymentMethod = params.data.paymentMethod
    this.user = params.data.user ? params.data.user : this.auth.getUser()
    this.onCardChange = this.onCardChange.bind(this)
  }
	
  async ngOnInit() {
    this.userService.getStripeIntent(this.user._id)
  }

  // ------- EDITING CARD
	async deletePaymentMethod() {
		if (confirm('Are you sure you would like to remove this payment method?')) {
			try {
				await this.userService.deletePaymentMethod(this.user._id, this.paymentMethod.id)
				const toast = await this.toastCtl.create({ position: 'top', duration: 3000, message: 'Successfully detached payment method.' })
				await toast.present()
				this.viewCtrl.dismiss({ reload: true })
			} catch (e) {
				console.error(`Error detaching payment method: `, e)
				const toast = await this.toastCtl.create({ position: 'top', duration: 3000, message: 'Error detaching payment method.' })
				await toast.present()
			}
		}
	}

  // ------- If adding NEW card
	// wanted this in a component but not sure how to get the Card element keeps coming back as null when passing the reference
	cards: { brand: string, last4: number, exp_month: string, exp_year: string, email: string }[]
  stripe: stripe.Stripe;
	card: stripe.elements.Element

	// this could be passed into another component... if the stripe card ref would work
	async onCardChange(event: stripe.elements.ElementChangeResponse, stripeInstance: stripe.Stripe, card: stripe.elements.Element) {
		this.stripe = stripeInstance
		this.card = card
		let displayError = document.getElementById('card-element-errors');
		if (event.error) {
			displayError.textContent = event.error.message;
			displayError.style.color = '#eb1c26';
		} else {
			// displayError.textContent = '';
		}
		if (event.complete) {
			$('#submitBtn')
				.removeAttr('disabled')
				.addClass('enabled')
				.html('Continue')
		} else {
			$('#submitBtn')
				.attr('disabled', 'true')
				.removeClass('enabled')
		}
	}

	async savePayment() {
		const intent = await this.userService.getStripeIntent(this.user._id)
		// const cardParams = { payment_method: { card: $('#card-element').get() as any }} 
		const cardParams = { payment_method: { card: this.card }}
		const res = await this.stripe.confirmCardSetup(intent.client_secret, cardParams)
		if (!res.setupIntent) {
			return alert('Error setting up card.')
		}
    this.viewCtrl.dismiss({ reload: true })
	}}