import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'video',
  templateUrl: 'video.html'
})
export class VideoPage {
	constructor(private sanitizer: DomSanitizer) {}

	// trustedVideo() {
	// 	return this.sanitizer.bypassSecurityTrustResourceUrl(this.welcomeVideo);
	// }
}