import { Options } from "@angular-slider/ngx-slider";

export const vcSliderOptions: Options = {
  floor: 1,
  ceil: 7,
  showSelectionBar: true,
  hidePointerLabels: true,
  hideLimitLabels: true,
  showTicks: true,
  animate: false,
  getTickColor: () => "transparent",
  getLegend: () => {
    return `
      <span class="material-symbols-outlined tick">
        arrow_drop_up
      </span>
      `;
  },
};
