import { Component, Input, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "vc-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.scss"],
})
export class Header implements OnInit {
  @Input() title;
  @Input() backLinkTitle = "My Properties";
  @Input() property;
  isPropertyPage = window.location.href.includes("/v2/property/");

  constructor(public nav: NavController, public auth: AuthService) {}

  ngOnInit() {}

  routeToMyProperties() {
    this.nav.navigateForward("/v2");
  }

  routeBack() {
    const url = this.backLinkTitle === "My Properties" ? "/v2" : "/v2/add";
    this.nav.navigateBack(url);
  }
}
