import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
	selector: 'vc-input',
	template: `
<div class="vc-input" [id]="ctrId">
	<label [ngStyle]="ngStyle_label">
		<input
			*ngIf="!!control"
			placeholder=" "
			[disabled]="disabled || loading"
			[ngStyle]="ngStyle" 
			[ngClass]="ngClass"
			[value]="value"
			[id]="id"
			(input)="onInput($event)"
			[formControl]="control"
		>
		<!-- hate this but need this for the deeplink from website to this site -->
		<input
			*ngIf="!control"
			placeholder=" "
			[disabled]="disabled || loading"
			[ngStyle]="ngStyle" 
			[ngClass]="ngClass"
			[value]="value"
			[id]="id"
			(input)="onInput($event)"
		>
		<span [ngStyle]="ngStyle_labelSpan">{{label}}</span>
	</label>
</div>
	`,
	// Note - currently the actual styling is in global.scss
	styleUrls: ['vc-input.component.scss']
})
export class VcInput {
	@Input() loading = false
	@Input() disabled = false
	@Input() txt = 'Submit'
	@Input() click
	@Input() id
	@Input() ngStyle = {}
	@Input() ngClass = []
	@Input() ngStyle_label = {}
	@Input() ngStyle_labelSpan = {}
	@Input() label = 'Label'
	@Input() value = ""
	// @Input() error = false
	@Input() ctrId // hack - add class 'error' to label. this makes it easier to jquery for
	@Input() onInput = (evt) => {}
	@Input() control: FormControl;

	constructor() {}
}