import { Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "sidebar",
  templateUrl: "sidebar.component.html",
  styleUrls: ["sidebar.component.scss"],
})
export class Sidebar implements OnInit {
  adminAccess: boolean = false;

  constructor(public nav: NavController, public auth: AuthService) {}

  ngOnInit() {
    this.adminAccess = this.auth.getUser().roles.includes("admin");
  }

  toggleSidebar() {
    $("#sidebar").toggleClass("active");
  }

  /**
   * this will be replace when we get rid of the app.component.ts page
   */
  get tab() {
    let tab = "home";
    if (window.location.href.includes("/v2/admin/user")) {
      tab = "admin-user-mgmt";
    } else if (window.location.href.includes("/v2/add")) {
      tab = "add";
    }
    return tab;
  }

  routeToHome() {
    this.nav.navigateForward("/v2");
    $("#sidebar").removeClass("active");
  }

  routerToAddProperty() {
    this.nav.navigateForward("/v2/add");
    $("#sidebar").removeClass("active");
  }

  routeToAdminUserMgmt() {
    this.nav.navigateForward("/v2/admin/user");
    $("#sidebar").removeClass("active");
  }

  logout() {
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.nav.navigateRoot("");
  }
}
