import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Grid, GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { IProperty } from 'src/app/interfaces/property.interface';
import { PropertyService } from 'src/app/services/property.service';
import { PropertyAdminModal } from './property-admin-modal/property-admin-modal';

@Component({
  selector: 'property-admin',
  templateUrl: 'property-admin.html',
  styleUrls: ['./property-admin.scss']
})

export class PropertyAdminPage implements OnInit {
  private properties: IProperty[];
  private searchTxt: string;

  private grid: Grid;
  private gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
    },
    columnDefs: [
      {
        headerName: 'Property ID',
        field: '_id',
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        headerName: "Address",
        valueGetter: p => `${p.data.StreetNumberNumeric} ${p.data.StreetName}`,
        flex: 1,
        minWidth: 270
      },
      { field: 'City' },
      { headerName: 'State', field: 'StateOrProvince' },
      { headerName: 'Zip', field: 'PostalCode' },
      {
        headerName: '# Claims',
        valueGetter: p => `${p.data.eccovals && p.data.eccovals.length ? p.data.eccovals.length : 0}`
      },
      {
        headerName: 'Tovo Pull Date',
        valueGetter: p => `${p.data.tovoData && p.data.tovoData.results.created ? moment(p.data.tovoData.results.created).format('YYYY-MM-DD') : 'N/A'}`
      }
    ],
    rowSelection: 'multiple', // allow rows to be selected
    animateRows: true, // have rows animate to new positions when sorted
  }

  constructor(
    public nav: NavController,
    public propertyService: PropertyService,
    public titleService: Title,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController
  ) { }

  async ngOnInit(): Promise<void> {
    this.grid = new Grid($('#propGrid')[0], this.gridOptions)
    await this.loadGrid()
  }

  async loadGrid() {
    this.properties = await this.propertyService.search().toPromise()
    this.gridOptions.api.setRowData(this.properties)
  }

  clear() {
    this.searchTxt = ""
  }

  async delete(propertyIdx) {
    const property = this.properties[propertyIdx]
    const address = property.search_formatted_address ? property.search_formatted_address : property.formatted_address
    if (confirm(`Sure you want to delete ${address}?`)) {
      await this.propertyService.delete(property).toPromise();
      this.properties = await this.propertyService.search().toPromise();
    }
  }

  async deleteProperties() {
    const rows = this.gridOptions.api.getSelectedNodes()
    console.log(rows)
    const selected = rows.map(r => r.data)
    const msg = `Delete ${rows.length} properties?\n\n${selected.map(s => `- ${s.search_formatted_address ? s.search_formatted_address : s.formatted_address}`).join('\n')}`
    if (confirm(msg)) {
      const loader = await this.loadingCtrl.create({})
      await loader.present()
      await Promise.all(
        selected.map((property, i) => this.propertyService.delete(property).toPromise().catch(e => {
          console.log("ERROR DELETING PROPERTY ", property, e)
          alert(`Error deleting property ${i} - ${property.search_formatted_address ? property.search_formatted_address : property.formatted_address}`)
        }))
      )
      await this.loadGrid()
      loader.dismiss()
    }
  }

  async displayModal() {
    const property = this.gridOptions.api.getSelectedNodes()[0].data
    const modal = await this.modalCtrl.create({
      component: PropertyAdminModal,
      componentProps: { property }
    })
    await modal.present()
  }

  async refreshTovo() {
    const loader = await this.loadingCtrl.create()
    await loader.present()
    const property = this.gridOptions.api.getSelectedNodes()[0].data
    console.log("the property is!! ", property)
    await this.propertyService.refreshTovoData(property._id).toPromise()
    this.loadGrid()
    await loader.dismiss()
  }

  filterProperties(evt) {
    console.log("Event is: ", evt)
    const search = evt.target.value
    const searchLowerCase = search.toLowerCase()
    // const currProps = [];
    // this.gridOptions.api.forEachNode(node => currProps.push(node.data));
    // console.log("Curr Props = ", currProps)

    // couple ways we could do this but we'll filter accross all relevant rows
    this.gridOptions.api.setRowData(this.properties.filter(p => {
      const address = `${p.StreetNumberNumeric} ${p.StreetName}`.toLowerCase()
      const city = p.City.toLowerCase()
      const state = p.StateOrProvince.toLowerCase()
      const postal = p.PostalCode
      const claims = `${p.eccovals && p.eccovals.length ? p.eccovals.length : 0}`
      // return search.includes(address) || search.includes(city) || search.includes(state) || search.includes(postal) || search.includes(claims)
      // return address.includes(search) || city.includes(search) || state.includes(search) || postal.toString().includes(search) || claims.toString().includes(search)
      return address.includes(searchLowerCase) || city.includes(searchLowerCase) || state.includes(searchLowerCase) || postal.toString().includes(searchLowerCase) || claims.toString().includes(searchLowerCase)
    }))
  }
}
