/**
 * Map of titles for Google Analytics.
 */
export const TITLES = {
	LOGIN: 'VALUclick Home Valuation | Login',
	HOME: 'VALUclick | Home',
	TEAM: 'VALUclick | Team',
	QUICK_CALC: 'VALUclick | Quick-Calc',
	EDIT_PROFILE: 'VALUclick | Edit Profile',
	BILLING_DETAILS: 'VALUclick | Billing Details',
	PROPERTY_DETAILS: 'VALUclick | Property Details',
	PROPERTY_DETAILS_GUEST: 'VALUclick | Guest Valuation',
	REGISTRATION: 'VALUclick | Registration',
	ACTIVATION: 'VALUclick | Account Activation',
	// extra ones just for safety
	CREATE_PROPERTY: 'VALUclick | Create Property',
	RESET_PASS: 'VALUclick | Reset Pass',
	PROPERTY_IMAGES: 'VALUclick | Set Property Images'
}