import { Directive, HostListener, ElementRef, Renderer2 } from "@angular/core";
import { NgControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { fmtPrice } from "src/app/util/util";

@Directive({
  selector: "[appPriceFormat]",
})
export class PriceFormatDirective {
  private valueChangesSubscription: Subscription;

  // constructor(private el: ElementRef) {}
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngControl: NgControl
  ) {}

  ngOnInit() {
    // this.ngOnInit.
    // console.log(this.ngControl.control.value);
    // console.log(this.ngControl);
    // this.formatValue(this.ng)

    this.valueChangesSubscription =
      this.ngControl.control.valueChanges.subscribe((value) => {
        // this.formatValue(value);
        const formattedVal =
          "$ " +
          value
            .toString()
            .replace(/[^0-9]/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.el.nativeElement.value = formattedVal;
        if (formattedVal !== value) {
          this.ngControl.control.setValue(formattedVal, { emitEvent: false });
        }
      });
  }
  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe(); // Prevent memory leaks
    }
  }

  @HostListener("input", ["$event"]) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value =
      "$ " +
      initalValue.replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // fmtPrice(initalValue
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

// export class PriceFormatDirective {
//   constructor(
//     private el: ElementRef,
//     private renderer: Renderer2,
//     private ngControl: NgControl
//   ) {}

//   @HostListener("input", ["$event.target.value"]) onInput(value: string) {
//     console.log("--- INPUT : ", value);
//     const numericValue = this.parseValue(value);
//     this.ngControl.control.setValue(numericValue, { emitEvent: false });
//   }

//   @HostListener("blur") onBlur() {
//     this.formatDisplayValue(this.el.nativeElement.value);
//   }

//   @HostListener("focus") onFocus() {
//     this.renderer.setProperty(
//       this.el.nativeElement,
//       "value",
//       this.ngControl.control.value
//     );
//   }

//   private formatDisplayValue(value: number) {
//     console.log("--- value = ", value);
//     const formattedValue = value
//       ? `$ ${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
//       : "";
//     this.renderer.setProperty(this.el.nativeElement, "value", formattedValue);
//   }

//   private parseValue(formattedValue: string): number {
//     return Number(formattedValue.replace(/[^0-9.-]+/g, ""));
//   }
// }
