import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { IUser } from "src/app/interfaces/user.interface";
import { UserAdminService } from "src/app/services/user.service";

@Component({
	selector: 'admin-user-details-modal',
	templateUrl: './admin-user-details-modal.html',
	styleUrls: ['./admin-user-details-modal.scss'],
})
export class AdminUserDetailsModal implements OnInit {
	user: IUser

	constructor(
		public viewCtrl: ModalController,
		public params: NavParams,
		public toastCtl: ToastController,
		public userService: UserAdminService
	) {
		this.user = this.params.get('user')
	}

	async ngOnInit() {
		await this.load()
	}

	async load() {
		try {
			// const details = await this.userService.getStripeInfo(this.user._id)
			// console.log('-- the details are ', details)
		} catch (e) {
			console.error(`Error loading user`)
			const toast = await this.toastCtl.create({message: 'Error loading user'})
			await toast.present()
		}
	}

	close() {
		this.viewCtrl.dismiss()
	}
}
