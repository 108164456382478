import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { vcSliderOptions } from "../slider/slider-options";
import { formatNumber } from "src/app/util/util";
import { Options } from "@angular-slider/ngx-slider";

export interface PropertyFilter {
  priceRange: number[];
  beds: number;
  baths: number;
  size: number[];
  type: string[];
}

@Component({
  selector: "property-filter-modal",
  templateUrl: "property-filter-modal.component.html",
  styleUrls: ["property-filter-modal.component.scss"],
})
export class PropertyFilterModal {
  priceRange = [0, 5000000];
  beds: number = null;
  baths: number = null;
  size = [0, 20000];
  type: string[] = null;
  // sortBy

  @Input() active = false;
  @Input() close: () => void;
  @Output() apply = new EventEmitter<PropertyFilter>();

  AMT_OPTS = [
    { label: "Any", value: null },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
  ];
  TYPE_OPTS = [
    { label: "Any", value: null },
    // { label: "For Sale", value: "FOR_SALE" },
    // { label: "Recently Sold", value: "RECENTLY_SOLD" },
    // { label: "Off Market", value: "OFF_MARKET" },
    // not sure if ^^ works
    { label: "For Sale", value: "ForSale" },
    { label: "Recently Sold", value: "RecentlySold" },
    { label: "For Rent", value: "ForRent" },
  ];

  // ------------- Slider
  getPriceSliderOpts(): Options {
    return {
      ...vcSliderOptions,
      getLegend: () => "",
      hidePointerLabels: false,
      floor: 0,
      ceil: 5000000,
      step: 50000,
      // stepsArray: todo - would be nice to have 50k increments up to 1m, then 100k / 500k increments
      translate: (value: number): string => {
        return "$" + formatNumber(value);
      },
      pushRange: true,
      showTicks: false,
    };
  }

  getSizeSliderOpts(): Options {
    return {
      ...vcSliderOptions,
      getLegend: () => "",
      hidePointerLabels: false,
      floor: 0,
      ceil: 20000,
      step: 100,
      // stepsArray: todo - would be nice to have 50k increments up to 1m, then 100k / 500k increments
      translate: (value: number): string => {
        return `${value.toLocaleString("en-US")} sqft`;
      },
      pushRange: true,
      showTicks: false,
    };
  }

  submit = () => {
    this.apply.emit({
      priceRange: [...this.priceRange],
      beds: this.beds,
      baths: this.baths,
      size: [...this.size],
      type: this.type,
    });
  };
}
