import { Component, Input, NgModule, OnInit } from "@angular/core";
import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";

@Component({
  selector: 'subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModal {
  tier: string
  user: IUser
  constructor(
    public params: NavParams,
		public viewCtrl: ModalController,
    public userService: UserAdminService,
    public auth: AuthService,
    public toastCtl: ToastController
  ) {
    this.tier = params.data.tier
    this.user = params.data.user ? params.data.user : this.auth.getUser()
    this.onChange = this.onChange.bind(this)
  }

  onChange(tier) {
    this.tier = tier
  }

  cancel() {
    this.viewCtrl.dismiss({ reload: false })
  }

  async submit() {
    try {
      await this.userService.setSub(this.user._id, this.tier)
      this.toastCtl.create({ position: 'top', message: 'Subscription Successfully Updated', duration: 3000 }).then(m => m.present())
      this.viewCtrl.dismiss({ reload: true })
    } catch (e) {
      console.error(`Error updating subscription: `, e)
      await this.toastCtl.create({ position: 'top', message: 'Error updating subscription. Try again later.' })
    }
  }
}