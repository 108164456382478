import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core"
import { IUser } from "src/app/interfaces/user.interface";
import { UserAdminService } from "src/app/services/user.service";
import { delay, objectifyQueryParams } from "src/app/util/util";

@Component({
  selector: 'subscription-input',
  templateUrl: './subscription-input.component.html',
  styleUrls: ['./subscription-input.component.scss']
})

/**
 * The subscription has 2 views:
 *  Individual / Team Lead User must select the tier they're on
 *  Team members will already have it pre-filled. They just need to fill in personal info... "taking" a spot
 */
export class SubscriptionInput implements OnInit {
  @Output() subscriptionEvent = new EventEmitter<{ tier: string, brokerage_code: string, account_lead: boolean }>();
  @Input() loadedTeamLead: IUser;

  public tier = objectifyQueryParams().tier ? objectifyQueryParams().tier : 'BETA';
  public account_lead = true;
  public brokerage_code: string;

  constructor(private userService: UserAdminService) {}

  ngOnInit(): void {
    if (!this.loadedTeamLead) {
      this.subscriptionEvent.emit({ tier: this.tier, brokerage_code: '', account_lead: true })
    }
    // may be able to support this component in multiple places (ie profile)
    setTimeout(this.setWidthForMobile, 500)
  }


  // -------- these get called if it's a team lead signing up

  setTier(tier) {
    this.tier = tier;
    this.subscriptionEvent.emit({ tier: this.tier, brokerage_code: this.brokerage_code, account_lead: true })
  }

  /**
   * Set's the teamLead value so the team member code input knows whether or not to render
   */
  setTeamLead(tl: boolean) {
    this.account_lead = tl
  }

  onBrokerageCodeChange(brokerage_code) {
    this.brokerage_code = brokerage_code;
    this.subscriptionEvent.emit({ tier: this.tier, brokerage_code: this.brokerage_code, account_lead: this.account_lead  })
  }

  async setWidthForMobile() {
    // may need to toggle this container name if we use this in other components
    // if window.url.href.includes('register') use registerCtr, otherwise something else
    const registerCtr = $('#registerCtr')
    let registerCtrWidth = registerCtr.width()
    if (registerCtrWidth < 10) {
      console.warn('-- reload happened too quick and register ctr is small - retrying')
      await delay(500)
      registerCtrWidth = registerCtr.width()
      console.log('... and width is now ', registerCtrWidth)
      if (registerCtrWidth < 10) {
        return console.warn('-- cant apply the styles. exiting.')
      }
    }

    console.log('REGISTER WIDTH: ', registerCtrWidth)
    if (registerCtrWidth < 800) {
      $('#subCtr').width(registerCtrWidth)
      $('#subCtr').css('overflow-x', 'scroll')
      $('#subCtr').css('padding-left', '20px')
      $('#subCtr').css('padding-bottom', '5px')
    }
  }
}
