import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ConfigService } from "./config.service";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Events } from "@ionic/angular";
import { calcCrow, toQueryStr } from "../util/util";

import { IProperty, IAddress } from "../interfaces/property.interface";
import * as moment from "moment";

@Injectable()
export class PropertyService {
  private readonly configService: ConfigService = new ConfigService();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly events: Events
  ) {}

  //*******ML calls ******/
  getDaysonMarket(property: any): Observable<IProperty> {
    //this is the placeholder object.
    let listprice: number = property.eccoval.priceadjustment
      ? property.eccoval.priceadjustment
      : 0;
    let sq_feet: number = property.features.home_square_footage
      ? property.features.home_square_footage
      : 0;
    //return this.httpClient.post('wf/ml/daysonmarket', {
    return this.httpClient.post<IProperty>("sourcedata/estimatedom", {
      property: property,
      overlay: {
        PricePerSquareFoot: listprice / sq_feet,
        ListPrice: listprice,
      },
    });
  }

  save(property: any, link?: string, isRealtor = false): Observable<IProperty> {
    // default the cma price to the List Price from MLS /klb
    if (property.ListPrice != null && property.eccoval == null) {
      property.eccoval = { cmaprice: 0 };
    }
    if (link) {
      return this.httpClient.post<IProperty>(
        `property/update/guest/${link}?isRealtor=${isRealtor}`,
        property
      );
    } else if (!!property._id) {
      return this.httpClient.post<IProperty>("property/update", property);
    } else {
      return this.httpClient.post<IProperty>("property/create", property);
    }
  }

  // makeshift claim functionality
  claim(property: IProperty, purchased = false): Observable<IProperty> {
    return this.httpClient.post<IProperty>(
      `property/claim?purchased=${purchased}`,
      property
    );
  }

  uploadimage(imageData: any): Observable<IProperty> {
    /*    let formData = new FormData();
        formData.append('somekey', 'some value')
        //formData.append('files', image);
        formData.append('files[]', image.rawFile, image.name);
    */
    let headers = new HttpHeaders();
    //headers.append('Accept', 'application/json');
    //headers.append("Content-Type", "application/json");
    headers.append("Content-Type", "multipart/form-data");

    return this.httpClient.post<IProperty>("property/image", imageData, {
      headers: headers,
    });
  }

  getImage(imgId) {
    if (!imgId) {
      return "assets/img/logo.png";
    } else if (imgId.startsWith("http")) {
      return imgId.replace("http://", "https://");
    } else {
      return this.configService.getSvcURL("property/image?imgId=" + imgId);
    }
  }

  delete(property: any): Observable<IProperty> {
    if (!property.id) {
      return this.httpClient.get<IProperty>("property/delete?id=" + property);
    } else {
      return this.httpClient.get<IProperty>(
        "property/delete?id=" + property.id
      );
    }
  }

  removeUserFromProperty(property: any): Observable<IProperty> {
    const id = property.id ? property.id : property;
    console.log("REMOVING USER FROM PRIPERTY!!", id);
    return this.httpClient.delete<IProperty>(`property/delete/soft?id=${id}`);
  }

  findAll(): Observable<IProperty[]> {
    return this.httpClient.get<IProperty[]>("property/");
  }

  findById(id: any): Observable<IProperty> {
    return this.httpClient.get<IProperty>("property/byid?id=" + id);
  }

  // ---------------------- ZILLOW ---------------------- //

  findByZillowId(
    zpid: string
  ): Promise<{ property: IProperty; zillowProperty: any }> {
    return this.httpClient
      .get<{ property: IProperty; zillowProperty: any }>(
        `property2/zillow/${zpid}`
      )
      .toPromise();
  }

  fetchZillowComps(zpid: string): Promise<any[]> {
    return this.httpClient
      .get<any>(`property2/zillow/${zpid}/comps`)
      .toPromise();
  }

  async zillowSave(property: IProperty): Promise<IProperty> {
    return this.httpClient
      .post<IProperty>(`property2/save`, property)
      .toPromise();
  }

  // ---------------------- ZILLOW ---------------------- //

  propertyVerified(property: any) {
    return !property.geocode || !property.geocode.resolving;
  }

  search(): Observable<IProperty[]> {
    return this.httpClient.get<IProperty[]>("property/search");
  }

  searchTovoData(address: IAddress): Observable<IProperty> {
    return this.httpClient.get<IProperty>(
      `property/search/tovo?${toQueryStr(address)}`
    );
  }

  searchRealty(address: string): Promise<IProperty> {
    return this.httpClient
      .get<IProperty>(`property/search/realty?${toQueryStr({ address })}`)
      .toPromise();
  }

  /**
   * An attempt to pull tovo property and mls data and attach it to an existing property
   */
  refreshTovoData(id: string): Observable<IProperty> {
    return this.httpClient.get<IProperty>(`property/refresh/tovo?id=${id}`);
  }

  autofillCma(
    id: string
  ): Observable<{ property: IProperty; cmaPerSqFt: Number }> {
    return this.httpClient.post<{ property: IProperty; cmaPerSqFt: Number }>(
      `property/autocma?id=${id}`,
      {}
    );
  }

  defaultProperty() {
    return {
      empty: true,
      location: "Loading...",
      images: [],
      features: {
        home_area: -1,
        lot_area: -1,
      },
      geocode: {},
      eccoval: {
        cmaprice: 0,
        priceadjustment: 0,
        pricerecomended: 0,
        minprice: 0,
        maxprice: 0,
        modifiers: [],
      },
    };
  }

  // zillow things
  async searchByAddress(location) {
    return this.httpClient
      .get(
        `property/zillow/propertyExtendedSearch?${toQueryStr({
          location,
          home_type: "Houses",
        })}`
      )
      .toPromise();
  }

  async getZillowProperty(zpid) {
    return this.httpClient
      .get(`property/zillow/property?${toQueryStr({ zpid })}`)
      .toPromise();
  }

  async getComps(zpid, property) {
    const data: any = await this.httpClient
      .get(`property/zillow/similarSales?${toQueryStr({ zpid })}`)
      .toPromise();

    const fmtResponse = data
      .sort((a, b) => b.dateSold - a.dateSold)
      .map((c) => {
        const distance_crowFlies_km: any = calcCrow(
          property.latitude,
          property.longitude,
          c.latitude,
          c.longitude
        );
        const distance_crowFlies_miles = distance_crowFlies_km * 0.621371;
        const lowerAreaBound = property.livingArea - property.livingArea * 0.3;
        const higherAreaBound = property.livingArea + property.livingArea * 0.3;
        return {
          ...c,
          dateSoldString: moment(c.dateSold).format("MM/DD/YYYY"),
          daysSinceSale: moment().diff(moment(c.dateSold), "days"),
          distance_crowFlies_km,
          distance_crowFlies_miles,
          // sizeDiffPercentage: relDiff(property.livingArea, c.livingArea)
          in30PercThresh:
            c.livingArea >= lowerAreaBound && c.livingArea <= higherAreaBound,
        };
      });

    return fmtResponse;
  }

  calculateCma(comps) {
    let remainingComps = [];

    // 1. remove any > 90 day sale date
    console.log("\nRemoving > 180 days");
    remainingComps = comps.filter((c) => c.daysSinceSale <= 180);
    console.log("... remaining ", remainingComps.length);

    // 2. remove any with livingArea not in 30% radius
    console.log("Removing any not in livingArea radius");
    remainingComps = comps.filter((c) => c.in30PercThresh);
    console.log("... remaing", remainingComps.length);

    // 3. TODO - remove any out of date built range. Will need to pull more data... see if it's worth it
    // also note - this may not be necessary if the data is consistently good enough...
    // it is coming from zillow, so one could assume the comps are naturally more likely to be in the valid range...

    // 4. find 5 closest comps
    const ranges = [30, 60, 90, 180];
    let finalComps = [];
    for (let range of ranges) {
      console.log(`For the ${range} day range`);
      remainingComps
        .filter((c) => c.daysSinceSale <= range)
        .forEach((d) => {
          if (!finalComps.find((z) => z.zpid == d.zpid)) {
            finalComps.push(d);
          }
        });
      console.log("... # of final comps: ", finalComps.length);
      if (finalComps.length >= 5) {
        console.log("---- Found the final comps!! exiting");
        break;
      }
    }

    // 5. calc the avg sqft price
    // to do this we'll calculate the avg / sq ft of each property, and add it
    // const priceTotal = finalComps.reduce((acc, curr) => acc + curr.last, 0)
    const totalAvgPerSqFt = finalComps.reduce((acc, curr) => {
      console.log("For ", curr);
      const avgSalePriceSqFt = Math.round(curr.lastSoldPrice / curr.livingArea);
      console.log("...", avgSalePriceSqFt);
      return acc + avgSalePriceSqFt;
    }, 0);

    const avg = Math.round(totalAvgPerSqFt / finalComps.length);
    console.log("... Avg price per sqft = ", avg);

    const zillowCompCma = {
      finalComps,
      cmaPerSqFt: avg,
      created_on: new Date(),
    };

    // return avg
    return zillowCompCma;
  }

  // ------- Realty in US API
  async realtyProperty(property: IProperty) {
    return this.httpClient
      .get(`property/realty/property?id=${property._id}`)
      .toPromise();
  }

  // note: the additional args could be a good generic solution but will just solve my first issue first
  // async realtyComps(property: IProperty, additionalArgs?: object) {
  async realtyComps(property: IProperty, radius?: number) {
    // let url = `property/realty/list?id=${property._id}`
    // if (additionalArgs) url += `&${toQueryStr(additionalArgs)}`
    // const res: any = await this.httpClient.get(url).toPromise()

    let url = `property/realty/list?id=${property._id}`;
    if (radius) url += `&${toQueryStr({ radius })}`;
    const res: any = await this.httpClient.get(url).toPromise();

    let comps = res.data.home_search.results;
    console.log(".... comps are: ", comps);

    // 0. Process to fit the current table display
    const propLat = property.tovoData.results.propertyAddress.latitude;
    const propLon = property.tovoData.results.propertyAddress.longitude;
    console.log("propLat", propLat);
    console.log("propLon", propLon);

    comps = comps.map((a) => {
      console.log("--- COMP ", a);
      const listDate = a.list_date;
      const dateSoldObj = moment(a.last_sold_date);
      const dateSold = dateSoldObj.valueOf();
      const dateSoldString = dateSoldObj.format("MM/DD/YYYY");
      const daysSinceSale = moment().diff(dateSoldObj, "days");
      const DOM =
        dateSoldObj.diff(moment(listDate), "days") > 0
          ? dateSoldObj.diff(moment(listDate), "days")
          : null;
      // we may want to keep the value out of the DB but I'll keep it around for ref
      // but this will allow us to remove the values with no DOM value
      // if (!DOM) {
      //   console.warn('Warning - bad list date. removing it from the DB.')
      // }

      const bedrooms = a.description.beds;
      const bathrooms = a.description.baths;

      // const realtyPropCo = realtyProp.location.address.coordinate
      const currCo = a.location.address.coordinate;
      console.log("-- CURR CO", currCo);
      const distance_crowFlies_km: any = calcCrow(
        propLat,
        propLon,
        currCo.lat,
        currCo.lon
      );
      const distance_crowFlies_miles = (
        distance_crowFlies_km * 0.621371
      ).toFixed(2);
      const latitude = currCo.lat;
      const longitude = currCo.lon;

      const addr = a.location.address;
      // const Address = `${addr.line}, ${addr.city}, ${addr.state_code} ${addr.postal_code}`
      const address = {
        city: addr.city,
        state: addr.state_code,
        streetAddress: addr.line,
        zipcode: addr.postal_code,
      };

      const livingArea = a.description.sqft;

      const homeType = a.description.type;

      const lastSoldPrice = a.last_sold_price;
      const price = a.last_sold_price;
      const pricePerSqFoot = price / livingArea;

      return {
        ...a,
        listDate,
        dateSold,
        DOM,
        bedrooms,
        bathrooms,
        latitude,
        longitude,
        address,
        livingArea,
        homeType,
        lastSoldPrice,
        price,
        dateSoldString,
        daysSinceSale,
        distance_crowFlies_km,
        distance_crowFlies_miles,
        in30PercThresh: true,
        pricePerSqFoot, // note this won't get saved :( but works for next computation
      };
    });

    console.log(".... and the comps with distances are: ", comps);

    // 1. sold date
    comps = comps.sort(
      (a, b) =>
        moment(b.last_sold_date).valueOf() - moment(a.last_sold_date).valueOf()
    );
    console.log(
      "... sorted on sold date:",
      comps.map((a) => a.last_sold_date)
    );

    return comps;
  }

  filterComps(comps: any[]) {
    // round 1: grab all in 30 days
    const ranges = [0, 30, 60, 90, 120, 150, 180];
    let finalComps = [];

    for (let i = 1; i < ranges.length; i++) {
      let compsInRange = comps.filter((c) => {
        // we only want to be [inclusive, inclusive] when the start value is zero (first iteration)
        // every other one we want (exclusive, inclusive] to ensure all comps are considered
        return i - 1 === 0
          ? c.daysSinceSale >= ranges[i - 1] && c.daysSinceSale <= ranges[i]
          : c.daysSinceSale > ranges[i - 1] && c.daysSinceSale <= ranges[i];
      });
      if (compsInRange.length) finalComps = [...finalComps, ...compsInRange];

      if (finalComps.length >= 5) return finalComps;
    }

    return finalComps;
  }

  generateReport(id: String) {
    return this.httpClient
      .get(`property/realty/property/pdf?id=${id}`, {
        responseType: "arraybuffer",
      })
      .toPromise();
  }

  downloadPDF_v2(property: IProperty, comps: any[]) {
    return this.httpClient
      .post(
        `property2/pdf`,
        { property, comps },
        {
          responseType: "arraybuffer",
        }
      )
      .toPromise();
  }

  getDOM(id: String, link: string) {
    const url = link
      ? `property/guest/dom/${link}?id=${id}`
      : `property/dom?id=${id}`;
    return this.httpClient.get(url).toPromise();
  }

  purchase(id: String) {
    return this.httpClient.get(`property/paymentIntent?id=${id}`).toPromise();
  }

  /**
   * Update a modifier slider.
   * @param propertyId
   * @param modifierId
   * @param value
   * @returns
   */
  updateSlider(
    propertyId: String,
    modifierId: String,
    value: number
  ): Promise<{ property: IProperty; valu: number }> {
    return this.httpClient
      .patch<{ property: IProperty; valu: number }>(
        `property/${propertyId}/modifier`,
        { modifierId, value }
      )
      .toPromise();
  }

  /**
   * Update the CMA price, by total price or by square feet.
   * @param propertyId
   * @param cmaprice
   * @param isPerSqFt
   * @returns
   */
  updateCMA(
    propertyId: String,
    cmaprice: number,
    isPerSqFt = false
  ): Promise<{ property: IProperty; valu: number }> {
    return this.httpClient
      .patch<{ property: IProperty; valu: number }>(
        `property/${propertyId}/cma`,
        { cmaprice, isPerSqFt }
      )
      .toPromise();
  }

  // findById_guest(propertyId: String, valuId: string): Promise<{property: IProperty}> {
  //   return this.httpClient.get<{ property: IProperty }>(`property/guest/${propertyId}/${valuId}`).toPromise()
  // }
  findByLink(
    propertyId: string,
    link: string,
    isRealtor: boolean
  ): Promise<{ property: IProperty; valuidx: number }> {
    return this.httpClient
      .get<{ property: IProperty; valuidx: number }>(
        `property2/guest/${propertyId}/${link}?isRealtor=${isRealtor}`
      )
      .toPromise();
  }

  createValu(
    propertyId: string,
    name: string
  ): Promise<{ property: IProperty }> {
    return this.httpClient
      .post<{ property: IProperty }>(`property/${propertyId}/valu`, { name })
      .toPromise();
  }
}
