import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicStorageModule } from "@ionic/storage";
import { NavParams } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

// Modal Pages
import { ImagePageModule } from "./pages/modal/image/image.module";
import { SearchFilterPageModule } from "./pages/modal/search-filter/search-filter.module";

// Components
import { NotificationsComponent } from "./components/notifications/notifications.component";

// Ecco specific
import { AgGridModule } from "ag-grid-angular";

import { ModifierService } from "./services/modifier-service";
import { WeatherProvider } from "./services/weather";
import { CustomModifierService } from "./services/custommodifier-service";
import { AuthService } from "./services/auth.service";
import { ConfigService } from "./services/config.service";
import { LocationService } from "./services/location.service";
import { PropertyService } from "./services/property.service";
import { SourcedataService } from "./services/sourcedata.service";
import { OrgService } from "./services/org.service";
import { UserAdminService } from "./services/user.service";
import { AppService } from "./services/appservice.service";
import { ScriptService } from "./services/script.service";
import { ValidationService } from "./services/validation.service";

import { SettingsPage } from "./pages/settings/settings";
import { HomePage } from "./pages/home/home";
import { NotificationsPage } from "./pages/notifications/notifications";
import { RegistrationCtr } from "./pages/registration-ctr/registration-ctr";
import { RegisterPage } from "./pages/register/register";
import { RegistrationForm } from "./components/registration-form/registration-form.component";
import { RegisterSubuserModal } from "./components/register-subuser-modal/register-subuser-modal.component";
import { RegisterVerifyEmailPage } from "./pages/register-verify-email/register-verify-email";
import { Subscription } from "./components/subscription/subscription";
import { SubscriptionModal } from "./components/subscription-modal/subscription-modal.component";
import { PaymentInfo } from "./components/payment-info/payment-info.component";
import { PaymentInfoModal } from "./components/payment-info-modal/payment-info-modal.component";
import { RegisterActivate } from "./pages/register-activate/register-activate";
import { PropertyDetailPage } from "./pages/property-detail/property-detail";
//import {PropertylistPage} from "./pages/propertylist/propertylist";
import { LocalWeatherPage } from "./pages/local-weather/local-weather";
import { PictureLaodPage } from "./pages/pictureload/pictureload";
import { ReorderPropertyImages } from "./pages/reorder-property-images/reorder-property-images";
import { CreatePropertyPage } from "./pages/createproperty/createproperty";
import { OrgAdminPage } from "./pages/admin/org-admin";
import { AdminUserMgtPage } from "./pages/admin-user-mgt/admin-user-mgt";
import { ModifierAdminPage } from "./pages/admin/modifier-admin";
import { ModifierImportAdminPage } from "./pages/admin/modifier-import-modal/modifier-import-modal.component";
import { PropertyAdminPage } from "./pages/admin/property-admin";
import { PropertyAdminModal } from "./pages/admin/property-admin-modal/property-admin-modal";
import { ForceUpgrade } from "./pages/force-upgrade/force-upgrade";

import { environment } from "../environments/environment";
import { PropertyFilterPipe } from "./pipes/property-filter-pipe";
import { PropertyDetailsModal } from "./pages/property-details-modal/property-details-modal.component";
import { ExtendedPropertyDetailsModal } from "./pages/extended-property-details-modal/extended-property-details-modal";
import { TermsOfUseModal } from "./pages/modal/terms-of-use-modal/terms-of-use-modal.component";
import { ModifierAddModal } from "./pages/admin/modifier-add-modal/modifier-add-modal.component";
import { ProfilePage } from "./pages/profile/profile";
import { BillingDetails } from "./pages/billing-details/billing-details";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CardClaimedProperty } from "./components/card-claimed-property/card-claimed-property.component";
import { CardSearchProperty } from "./components/card-search-property/card-search-property.component";
import { AutocompleteAddress } from "./components/autocomplete-address/autocomplete-address.component";

import { AppLayoutComponent } from "./pages/applayout/applayout.component";
import { ApplicationHttpClient } from "./services/extended-http.service";
import { JwtModule, JWT_OPTIONS, JwtModuleOptions } from "@auth0/angular-jwt";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RegistrationCompleteModalComponent } from "./pages/modal/registration-complete-modal/registration-complete-modal.component";
import { SimulatorComponent } from "./pages/simulator/simulator.component";
import { NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { AppInfoService } from "./services/appinfo.service";
import { CookieModule } from "ngx-cookie";
import { VideoModal } from "./pages/video-modal/video-modal";
import { StripeCheckoutModal } from "./pages/stripe-checkout-modal/stripe-checkout-modal";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// import { GoogleMapsModule } from '@angular/google-maps';
// import { AgmCoreModule } from '@agm/core';
// import { AgmCoreModule } from '@agm/core';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { NgxWigModule, NgxWigComponent, BUTTONS } from "ngx-wig";

import { SubscriptionInput } from "./components/subscription-input/subscription-input.component";
import { RegisterSuccessPage } from "./pages/register-success.ts/register-success";
import { CheckoutRequiredModal } from "./pages/modal/checkout-required-modal/checkout-required.component";
import { TeamPage } from "./pages/team/team";
import { AddUserForm } from "./components/add-user-form/add-user-form.component";
import { CompsModal } from "./pages/comps-modal/comps-modal";
import { ResetPass } from "./pages/resetpass/resetpass";
import { AdminUserDetailsModal } from "./pages/admin-user-details-modal/admin-user-details-modal";
import { PropertyDetailPage_Test } from "./pages/property-detail-test/property-detail-test";
import { VcBtn } from "./components/vc-btn/vc-btn.component";
import { PropertyDetailGuestPage } from "./pages/property-detail-guest/property-detail-guest";
import { AddValuModal } from "./components/add-valu-modal/add-valu-modal.component";
import { InputCmaModal } from "./components/input-cma-modal/input-cma-modal.component";
import { PropertyService2 } from "./services/property2.service";
import { SharePropertyPopoverComponent } from "./components/share-property-popover/share-property-popover.component";
import { VcInput } from "./components/vc-input/vc-input.component";
import { ShareValuModal } from "./components/share-valu-modal/share-valu-modal.component";
import { VcTextArea } from "./components/vc-textarea/vc-textarea.component";
import { VideoPage } from "./pages/video/video";
import { LeadGenPageComponent } from "./pages/lead-gen-page/lead-gen-page.component";
import { LeadGenEmailFormComponent } from "./components/leadgen-email-form/leadgen-email-form.component";

// v2 rebuild
import { MyPropertiesPage } from "./v2/my-properties/my-properties.page";
import { RegistrationPage } from "./v2/registration/registration.page";
import { PropertyPage } from "./v2/property/property.page";
import { Slider } from "./v2/components/slider/slider.component";
import { Sidebar } from "./v2/components/sidebar/sidebar.component";
import { AdminUserMgmt } from "./v2/admin/admin-user-mgmt/admin-user-mgmt.page";
import { Header } from "./v2/components/header/header.component";
import { UserTable } from "./v2/components/user-table/user-table.component";
import { AddPropertyPage } from "./v2/add-property/add-property.page";
import { CompsTable } from "./v2/components/comps-table/comps-table.component";
import { PriceFormatDirective } from "./v2/property/price-format.directive";
import { CmaInput } from "./v2/property/cma-input/cma-input.component";
import { CmaDOMInput } from "./v2/property/cma-dom-input/cma-dom-input.component";
import { ModifiersCard } from "./v2/property/modifiers-card/modifiers-card.component";
import { CompsCard } from "./v2/property/comps-card/comps-card.component";
import { Leads } from "./v2/property/leads/leads.component";
import { PaywallModal } from "./v2/paywall/paywall";
import { AgmCoreModule } from "@agm/core";
import { PropertyFilterModal } from "./v2/components/property-filter-modal/property-filter-modal.component";
import { TemplateEditor } from "./v2/property/leads/template-editor/template-editor.component";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function tokenGetterFx(): string {
  return localStorage.getItem("token");
}

const jwtOptions: JwtModuleOptions = {
  config: {
    tokenGetter: tokenGetterFx,
    allowedDomains: environment.jwt.whitelistedDomains,
    disallowedRoutes: environment.jwt.blacklistedRoutes,
  },
};

@NgModule({
  declarations: [
    AppLayoutComponent,
    AppComponent,
    NotificationsComponent,
    HomePage,
    LocalWeatherPage,
    NotificationsPage,
    RegistrationCtr,
    RegisterPage,
    RegistrationForm,
    RegisterSubuserModal,
    Subscription,
    SubscriptionModal,
    PaymentInfo,
    PaymentInfoModal,
    RegisterVerifyEmailPage,
    RegisterActivate,
    RegisterSuccessPage,
    ResetPass,
    ForceUpgrade,
    PropertyDetailPage,
    PropertyDetailPage_Test,
    PropertyDetailGuestPage,
    PictureLaodPage,
    ReorderPropertyImages,
    CreatePropertyPage,
    OrgAdminPage,
    AdminUserMgtPage,
    ModifierAdminPage,
    PropertyAdminPage,
    PropertyAdminModal,
    ProfilePage,
    BillingDetails,
    PropertyDetailsModal,
    ExtendedPropertyDetailsModal,
    TermsOfUseModal,
    ModifierAddModal,
    ModifierImportAdminPage,
    SettingsPage,
    PropertyFilterPipe,
    RegistrationCompleteModalComponent,
    SimulatorComponent,
    VideoModal,
    StripeCheckoutModal,
    CheckoutRequiredModal,
    AutocompleteAddress,
    // before bugfixes were made on this card
    // CardClaimedProperty,
    CardSearchProperty,
    SubscriptionInput,
    TeamPage,
    AddUserForm,
    CompsModal,
    AdminUserDetailsModal,
    VcBtn,
    VcInput,
    VcTextArea,
    AddValuModal,
    InputCmaModal,
    SharePropertyPopoverComponent,
    ShareValuModal,
    VideoPage,
    LeadGenPageComponent,
    LeadGenEmailFormComponent,
    // -- v2 rebuild
    MyPropertiesPage,
    RegistrationPage,
    PropertyPage,
    Slider,
    Sidebar,
    AdminUserMgmt,
    Header,
    UserTable,
    AddPropertyPage,
    CompsTable,
    PriceFormatDirective,
    CmaInput,
    CmaDOMInput,
    ModifiersCard,
    CompsCard,
    Leads,
    PaywallModal,
    PropertyFilterModal,
    TemplateEditor,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ImagePageModule,
    SearchFilterPageModule,
    AgGridModule.withComponents([]),
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot(jwtOptions),
    IonicStorageModule.forRoot({
      name: environment.storagename,
      driverOrder: ["indexeddb", "sqlite", "websql"],
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgxCurrencyModule,
    NgxMaskModule.forRoot(),
    CookieModule.forRoot(),
    GooglePlaceModule,
    NgxSliderModule,
    // NgxWigModule,
    NgxWigModule,
    // NgxMuiDatatablesModule,
    // GoogleMapsModule
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyDdrX2CXoRUPf8MXt-LnYzlUtsnBc_LBGw",
    // }),
  ],
  entryComponents: [
    NotificationsComponent,
    HomePage,
    ModifierAddModal,
    SimulatorComponent,
    ExtendedPropertyDetailsModal,
    PropertyDetailsModal,
    RegistrationCompleteModalComponent,
    VideoModal,
    TermsOfUseModal,
    StripeCheckoutModal,
    CheckoutRequiredModal,
    AddUserForm,
    PropertyAdminModal,
    CompsModal,
    AdminUserDetailsModal,
    SubscriptionModal,
    PaymentInfoModal,
    RegisterSubuserModal,
    AddValuModal,
    InputCmaModal,
    ShareValuModal,
    SharePropertyPopoverComponent,
    PaywallModal,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ModifierService,
    CustomModifierService,
    AuthService,
    ConfigService,
    LocationService,
    PropertyService,
    PropertyService2,
    OrgService,
    UserAdminService,
    SourcedataService,
    AppService,
    WeatherProvider,
    ScriptService,
    ValidationService,
    AppInfoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationHttpClient,
      multi: true,
    },
    // {
    //   provide: BUTTONS,
    //   multi: true,
    //   useValue: {
    //     edithtml: {
    //       label: 'Stuff',
    //       title: 'edit stuff',
    //       command: (ctx: NgxWigComponent) => {
    //         console.log('-- the ctx stuff: ', ctx)
    //       },
    //       styleClass: 'ml-auto',
    //       icon: '',
    //     }
    //   }
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
