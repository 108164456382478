import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
	selector: 'share-popover',
	templateUrl: 'share-property-popover.component.html',
	styleUrls: ['share-property-popover.component.scss']
})

export class SharePropertyPopoverComponent implements OnInit {
	private openShareModal: Function
	private generatePdf: Function
	// currently this doesn't matter bc im hiding the whole share icon,
	// but when i bring back report functionality I'll have this hidden
	private isGuest: Boolean
	private alreadyShared = false

	constructor(
		private params: NavParams
	) {
		this.openShareModal = params.get("openShareModal")
		this.generatePdf = params.get("generatePdf")
		this.isGuest = params.get('isGuest')
		this.alreadyShared = params.get('alreadyShared')
	}

	ngOnInit() {}
}