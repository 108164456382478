import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "cma-input",
  templateUrl: "cma-input.component.html",
  styleUrls: ["cma-input.component.scss"],
})
export class CmaInput {
  @Input() cmaInput: FormControl;
  @Input() autofilling: boolean;
  @Input() cmaPricePerSqFt: boolean;
  @Input() autofillCmaPrice: () => void;
  @Input() onCmaPriceTypeChange: () => void;
  @Input() onSave: () => void;
  @Input() disabled: boolean;
  constructor() {}
}
