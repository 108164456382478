import { Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { IProperty } from "src/app/interfaces/property.interface";
import { AuthService } from "src/app/services/auth.service";
import { PropertyService } from "src/app/services/property.service";

@Component({
  selector: "my-properties",
  templateUrl: "my-properties.page.html",
  styleUrls: ["my-properties.page.scss"],
})
export class MyPropertiesPage /*implements OnInit*/ {
  properties: IProperty[];
  search: string = "";
  scrolled = false;
  loading = false;

  constructor(
    public nav: NavController,
    public authService: AuthService,
    public propertyService: PropertyService
  ) {}

  // async ngOnInit() {
  //   await this.load();
  // }

  async ionViewWillEnter() {
    await this.load();
  }

  async load() {
    this.loading = true;
    try {
      this.properties = (
        await this.propertyService.findAll().toPromise()
      ).reverse();
    } catch (e) {
      console.error("Error loading properties: ", e);
      alert("Error loading properties. Please try again later.");
    }
    this.loading = true;
  }

  get filteredProperties() {
    if (!this.properties) return [];
    return this.properties.filter((p) => {
      const address = p.search_formatted_address
        ? p.search_formatted_address.replace(", USA", "")
        : p.formatted_address;
      return !this.search
        ? p
        : address.toLowerCase().includes(this.search.toLowerCase());
    });
  }

  formatNum(n) {
    return Math.round(n).toLocaleString("en-US");
  }

  getPropertyMainAddress(property: IProperty) {
    /** @note may not work everytime */
    const address = property.search_formatted_address.replace(", USA", "");
    return address.split(",")[0];
  }

  getPropertySecondaryAddress(property: IProperty) {
    const address = property.search_formatted_address.replace(", USA", "");
    const toks = address.split(",");
    const suffix = toks[toks.length - 1].trim();
    const city = toks[toks.length - 2].trim();
    return `${city}, ${suffix}`;
  }

  getPropertyVALU(property: IProperty) {
    // potentially necessary when we bring back unclaimed properties
    if (!property.eccovals || !property.eccovals.length) {
      return "N/A";
    }
    const valu = property.eccovals[0].priceadjustment as number;
    return `$${this.formatNum(valu)}`;
  }

  selectProperty(property: IProperty) {
    this.nav.navigateForward(`/property/${property._id}`);
  }

  logout() {
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.nav.navigateRoot("");
  }

  addProperty() {
    // this.nav.navigateForward("/v2/add");
    this.nav.navigateForward("/add");
  }

  quickcalc() {
    this.nav.navigateForward("/simulator");
  }

  setSearchShadow(event) {
    this.scrolled = event.target.scrollTop > 0;
  }
}
