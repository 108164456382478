import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController, ToastController } from "@ionic/angular";
import { IProperty } from "src/app/interfaces/property.interface";
import { PropertyService } from "src/app/services/property.service";
import { vcSliderOptions } from "../components/slider/slider-options";
import * as moment from "moment";
import { calcCrow, delay, fmtPrice } from "src/app/util/util";
import { FormControl } from "@angular/forms";
import { PropertyService2 } from "src/app/services/property2.service";
import { CustomModifier } from "src/app/services/custommodifier-service";

@Component({
  selector: "property",
  templateUrl: "property.page.html",
  styleUrls: [
    "property.page.scss",
    "../components/slider/slider.component.scss",
  ],
})
export class PropertyPage implements OnInit {
  public id: string;
  public zpid: string;
  public property: IProperty;
  public loading = false;
  public selectedModifier = "Interior";
  public vcSliderOptions = vcSliderOptions;

  public loadingComps = false;
  public comps = [];
  public availableComps = [];

  // new version
  public tab = "cma";
  public cmaPricePerSqFt = false;
  // public cmaControl = new FormControl();
  public cmaInput = new FormControl();
  public autofillingCma = false;
  public autofillingDom = false;
  public cmaDOMInput = new FormControl();
  // "save" / "reset" button for mods
  public savedMods = [];
  public saving = false;

  // newer version
  // public claimed = true;
  public get claimed() {
    return this.eccoval.claimed;
  }

  zillowProperty = null;

  constructor(
    public nav: NavController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public propertyService: PropertyService,
    public propertyService2: PropertyService2,
    public toastCtl: ToastController
  ) {
    // turning off for now bc the state is unreliable
    // this.activatedRoute.queryParams.subscribe((params) => {
    //   console.log(this.router.getCurrentNavigation().extras.state);
    //   console.log("--- params = ", params);
    // });
    // this.activatedRoute.data.subscribe((d) => {
    //   console.log("-- data = ", d);
    // });
  }

  async ngOnInit() {
    // remove modal backdrop covering bug
    $(document).on("shown.bs.modal", ".modal", function () {
      $(".modal-backdrop").before($(this));
    });
    // load data based on route context (my-property or add-property)
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.zpid = this.activatedRoute.snapshot.paramMap.get("zpid");
    await this.load();
  }

  back() {
    this.nav.back();
  }

  async showToast(message) {
    const toast = await this.toastCtl.create({
      message,
      position: "top",
      duration: 3000,
    });
    await toast.present();
  }

  // -------------------- API calls -------------------- //
  async load() {
    this.loading = true;
    try {
      if (this.id) {
        this.property = await this.propertyService
          .findById(this.id)
          .toPromise();
        this.zpid = this.property.zillowData.zpid;
        this.savedMods = JSON.parse(JSON.stringify(this.modifiers));
        this.cmaInput.setValue(fmtPrice(this.cma));
        this.cmaDOMInput.setValue(this.dom);
        // await this.loadComps(false);
        // don't want presented comps chosen if they're not saved... i think
        this.comps = this.property.comps;
        this.availableComps = await this.propertyService.fetchZillowComps(
          this.zpid
        );
      } else if (this.zpid) {
        if (
          !(
            this.router.getCurrentNavigation().extras.state &&
            this.router.getCurrentNavigation().extras.state.property
          )
        ) {
          this.property = await this.propertyService
            .findByZillowId(this.zpid)
            .then((t) => {
              this.comps = t.property.comps; // t.property.zillowComps;
              this.zillowProperty = t.zillowProperty;
              return t.property;
            });
        } else {
          this.property =
            this.router.getCurrentNavigation().extras.state.property;
        }
        this.savedMods = JSON.parse(JSON.stringify(this.modifiers)); // deep copy
        if (!this.valu) {
          await this.loadComps(true);
          await this.loadDOM();
        } else {
          // this.cmaInput.setValue(this.cma);
          this.cmaInput.setValue(fmtPrice(this.cma, true));
          this.cmaDOMInput.setValue(this.cmaDOM);
          await this.loadComps(false);
          console.warn("- may want something different here");
        }
      }
      console.log("Property = ", this.property);
    } catch (e) {
      console.error("Error loading property: ", e);
      alert("Error loading property");
      // await this.showToast("Error loading property");
    }
    this.loading = true;
  }

  loadComps = async (syncCMA = true, forceAutoComps = false) => {
    this.loadingComps = true;
    try {
      this.availableComps = await this.propertyService.fetchZillowComps(
        this.zpid
      );
      // this.comps = this.availableComps.slice(0, 5);
      // originally just if (!this.comps.length) - not sure if I like this logic
      if (forceAutoComps || (!forceAutoComps && !this.comps.length)) {
        this.autofillComps();
      }
      console.log("-- comps = ", this.comps);
      if (syncCMA) {
        this.autofillCMA();
      }
    } catch (e) {
      console.error("Error loading comps: ", e);
      alert("Error loading comps");
      // await this.showToast("Error loading comps");
    }
    this.loadingComps = false;
  };

  // TODO - this may be integrated into the autocma flow
  loadAvailableComps = async () => {
    this.loadingComps = true;
    try {
      this.availableComps = await this.propertyService.fetchZillowComps(
        this.zpid
      );
    } catch (e) {
      console.error("Error loading available comps: ", e);
      await this.showToast("Error loading comps. Wait and try again.");
    }
    this.loadingComps = false;
  };

  async loadDOM() {
    this.autofillingDom = true;
    const compDoms = [];
    for (let i = 0; i < Math.min(3, this.comps.length); i++) {
      const comp = this.comps[i];
      const prop = await this.propertyService.findByZillowId(comp.zpid);
      console.log("comp: ", comp);
      const { datePosted, dateSold, priceHistory } = prop.zillowProperty;
      // use to do it this way: but the datePosted isn't the correct value
      // compDoms.push(moment(dateSold).diff(moment(datePosted), "days"));
      for (let history of priceHistory) {
        if (history.event === "Listed for sale") {
          compDoms.push(moment(dateSold).diff(moment(history.date), "days"));
          break;
        }
      }
    }
    console.log("-- comp doms = ", compDoms);
    if (compDoms.length) {
      this.cmaDOMInput.setValue(
        Math.round(
          compDoms.reduce((acc, curr) => {
            return (acc += curr);
          }, 0) / compDoms.length
        )
      );
    }
    this.setVALU_DOM(this.cmaDOMInput.value);
    this.autofillingDom = false;
  }

  save = async () => {
    this.saving = true;
    const updated = await this.propertyService.zillowSave({
      ...this.property,
      comps: this.comps,
    });
    this.property = updated;
    this.savedMods = JSON.parse(JSON.stringify(this.modifiers));
    this.saving = false;
  };

  // -------------------- VALU & CMA Helpers -------------------- //
  get eccoval() {
    // @todo this is how we will be able to toggle the VALU view for guests
    return this.property.eccovals[0];
  }

  get cma() {
    return this.eccoval.cmaprice as number;
  }

  get valu() {
    return this.eccoval.priceadjustment as number;
  }

  get cmaDOM() {
    return typeof this.eccoval.avgDOM === "number" ? this.eccoval.avgDOM : null;
  }

  get dom() {
    return typeof this.eccoval.valuDOM === "number"
      ? this.eccoval.valuDOM
      : null;
  }

  get displayValu() {
    return this.valu ? `$${this.formatNumber(this.valu)}` : "n/a";
  }

  get displayDOM() {
    return this.dom ? `${this.dom} days` : "n/a";
  }

  get propertyAddress() {
    if (!this.property) return "";
    const address = this.property.search_formatted_address
      ? this.property.search_formatted_address.replace(", USA", "")
      : this.property.formatted_address;
    return address;
  }

  setVALU(newVALU) {
    const duration = 300; // Duration in milliseconds
    const frameRate = 60; // Approximate frames per second
    const totalFrames = (duration / 1000) * frameRate;
    let frame = 0;
    let start = this.property.eccovals[0].priceadjustment as number;
    if (isNaN(start)) {
      start = 0;
    }
    let end = newVALU;

    const easeOutCubic = (t: number, b: number, c: number, d: number) => {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };

    const animate = () => {
      frame++;
      if (frame <= totalFrames) {
        this.property.eccovals[0].priceadjustment = easeOutCubic(
          frame,
          start,
          end - start,
          totalFrames
        );
        requestAnimationFrame(animate);
      }
    };

    animate();
    // after the animation we need to set the value explicitely, otherwise it becomes
    // some weird number from the animate() calculation
    this.property.eccovals[0].priceadjustment = newVALU;
  }

  setVALU_DOM(newDOM) {
    const duration = 300; // Duration in milliseconds
    const frameRate = 60; // Approximate frames per second
    const totalFrames = (duration / 1000) * frameRate;
    let frame = 0;
    let start = this.dom as number;
    let end = newDOM;

    const easeOutCubic = (t: number, b: number, c: number, d: number) => {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };

    const animate = () => {
      frame++;
      if (frame <= totalFrames) {
        this.property.eccovals[0].valuDOM = Math.round(
          easeOutCubic(frame, start, end - start, totalFrames)
        );
        requestAnimationFrame(animate);
      }
    };

    animate();
    // after the animation we need to set the value explicitely, otherwise it becomes
    // some weird number from the animate() calculation
    this.property.eccovals[0].valuDOM = newDOM;
  }

  setCMA(newCMA) {
    this.eccoval.cmaprice = newCMA;
  }

  setCMADom(newDOM) {
    this.eccoval.avgDOM = newDOM;
  }

  get inputCmaValue() {
    if (this.cmaInput.value == undefined) return null;
    return parseInt(this.cmaInput.value.replace(/[^0-9.-]+/g, ""));
  }

  get saveCmaEnabled() {
    if (this.cmaInput.value == undefined) return false;
    return this.cma !== parseInt(this.cmaInput.value.replace(/[^0-9.-]+/g, ""));
  }

  get saveCmaDOMEnabled() {
    if (this.cmaDOMInput.value === undefined) return false;
    return this.cmaDOMInput.dirty;
  }

  saveCma = async () => {
    console.log(
      "SAVE CMA: ",
      this.cmaInput.value,
      this.cmaInput.value.replace(/[^0-9.-]+/g, ""),
      parseInt(this.cmaInput.value.replace(/[^0-9.-]+/g, ""))
    );
    const cmaVal = parseInt(this.cmaInput.value.replace(/[^0-9.-]+/g, ""));
    this.setCMA(cmaVal);
    this.cmaInput.markAsPristine();
    this.calculateValu();
    await this.save();
  };

  saveCmaDOM = async () => {
    this.setCMADom(this.cmaDOMInput.value);
    this.cmaDOMInput.markAsPristine();
    this.calculateValu();
    await this.save();
  };

  calculateValu = () => {
    // calculate the new value
    const newVALU = Math.round(
      this.modifiers.reduce((valu, curr) => {
        const modifierPerc = curr[`s${curr.value}v`] / 100;
        const adjustment = this.cma * modifierPerc;
        return valu + adjustment;
      }, this.cma)
    );
    // TODO - calculate the new DOM
    console.warn("--- include slider calculation into DOM");
    console.log("-- newVALUE = ", newVALU, this.cma);
    const newDOM = this.cmaDOMInput.value;
    // this.property.eccovals[0].priceadjustment = newVALU;
    // animate upward or downward
    this.setVALU(newVALU);
    this.setVALU_DOM(newDOM);
  };

  onClickAutofillCma = async () => {
    this.autofillingCma = true;
    if (!this.comps.length) {
      try {
        await this.loadComps(true);
      } catch (e) {
        await this.showToast("No comps found. Can't autofill CMA.");
      }
    } else {
      await delay(500); // force the loader to appear in case the value doesn't change
      this.autofillCMA(false);
    }
    this.autofillingCma = false;
  };

  onClickAutofillDOM = async () => {
    await this.showToast("Currently not available");
  };

  autofillCMA(setValu = true) {
    const compsAvgPpsf = Math.round(
      this.comps.reduce((ppsfSum, curr) => {
        const ppsf = Math.round(curr.price / curr.livingArea);
        return ppsfSum + ppsf;
      }, 0) / this.comps.length
    );
    const cmaVal = compsAvgPpsf * (this.property.LivingArea as number);
    const cmaFmtVal = fmtPrice(cmaVal, true);
    // if (setValu) {
    //   this.setCMA(cmaVal);
    //   this.cmaControl.setValue(this.cma);
    //   this.cmaControl.markAsPristine();
    //   this.setVALU(this.property.eccovals[0].cmaprice);
    // } else {
    //   this.cmaControl.setValue(cmaVal);
    //   if (this.cmaControl.value !== this.cma) {
    //     this.cmaControl.markAsDirty();
    //   } else {
    //     this.cmaControl.markAsPristine();
    //   }
    // }
    if (setValu) {
      this.setCMA(cmaVal);
      this.cmaInput.setValue(cmaFmtVal);
      this.cmaInput.markAsPristine();
      console.log("--- ORIGINAL VALUE: ", cmaFmtVal);
      this.setVALU(this.property.eccovals[0].cmaprice);
      // this.property.comps = this.comps
    } else {
      this.cmaInput.setValue(cmaFmtVal);
      console.log("--- this.cma = ", this.cma);
      console.log("--- cmaVal = ", cmaVal);
      if (this.cmaInput.value !== this.cma) {
        this.cmaInput.markAsDirty();
      } else {
        this.cmaInput.markAsPristine();
      }
    }
  }

  onCmaPriceTypeChange = (v) => {
    const sqft = this.property.LivingArea as number;
    const isPerSqFt = v.detail.checked;
    if (isPerSqFt) {
      this.cmaInput.setValue(Math.round(this.inputCmaValue / sqft));
    } else {
      this.cmaInput.setValue(this.inputCmaValue * sqft);
    }
  };

  // -------------------- Modifier helpers -------------------- //
  get modifiers() {
    return this.property.eccovals[0].modifiers;
  }

  adjustSlider = (sliderVal, modifier: any) => {
    const SUBDESC = {
      1: "Significantly Below Average",
      2: "Below Average",
      3: "Slightly Below Average",
      4: "Average",
      5: "Slightly Above Average",
      6: "Above Average",
      7: "Significantly Above Average",
    };
    modifier.value = sliderVal;
    modifier.subdesc = SUBDESC[sliderVal];
    this.calculateValu();
  };

  get sliderSaveEnabled() {
    // if one mod is different you can save
    return this.modifiers.find((m) => {
      // thisMod won't exist for newly added custom mods
      const thisMod = this.savedMods.find((sm) => sm._id === m._id);
      return !thisMod || m.value !== thisMod.value;
    });
  }

  resetMods = () => {
    // console.log("--- resetting mods");
    // this.property.eccovals[0].modifiers = this.savedMods;
    this.modifiers.forEach((m) => {
      const thisMod = this.savedMods.find((sm) => sm._id === m._id);
      if (m.value !== thisMod.value) {
        m.value = thisMod.value;
        m.subdesc = thisMod.subdesc;
      }
    });
  };

  addCustomModifier = (mod: CustomModifier) => {
    // this.savedMods = JSON.parse(JSON.stringify([...this.modifiers, mod]));
    this.property.eccovals[0].modifiers.push(mod);
  };

  // -------------------- Comps helpers -------------------- //
  get currentComps() {
    return this.property.comps; // this.property.zillowComps;
  }

  navToComp(comp) {
    const msg = `Are you sure you would like to leave this property to VALU ${comp.address}?`;
    if (window.confirm(msg)) {
      this.nav.navigateForward(`/v2/add/${comp.zpid}`);
    }
  }

  async autofillComps() {
    console.log("-- autofill comps");
    const adjFactor = (this.property.LivingArea as number) * 0.3;
    const sqft_low = (this.property.LivingArea as number) - adjFactor;
    const sqft_high = (this.property.LivingArea as number) + adjFactor;

    const orderedComps = this.availableComps
      .sort((a, b) => b.dateSold - a.dateSold)
      .map((c) => ({
        ...c,
        dateSoldHuman: moment(c.dateSold).format("MM-DD-YY"),
        dateSoldFromNow: moment().diff(moment(c.dateSold), "days"),
        distance: parseFloat(
          calcCrow(
            c.latitude,
            c.longitude,
            this.property.Latitude,
            this.property.Longitude,
            true
          )
        ),
      }));
    const filteredComps = orderedComps
      // 1. remove > 90 day old
      .filter((c) => c.dateSoldFromNow <= 90)
      // 2. within sqft range
      .filter((c) => c.livingArea >= sqft_low && c.livingArea <= sqft_high)
      // 3. filter out by distance
      .filter((c) => c.distance <= 2);

    let finalComps = [];
    const ranges = [0, 30, 60, 90];
    for (let i = 1; i < 4; i++) {
      if (finalComps.length > 5) break;
      const range_low = ranges[i - 1];
      const range_high = ranges[i];
      const available = filteredComps.filter(
        (c) => c.dateSoldFromNow >= range_low && c.dateSoldFromNow <= range_high
      );
      finalComps = [...finalComps, ...available];
    }
    this.comps = finalComps;
    this.property.comps = finalComps;
    console.log("--- this.comps = ", this.comps);
    if (!this.comps.length) {
      // await this.showToast("Warning: suboptimal CMA assumption");
      // this.comps = orderedComps.slice(0, 5);
      if (
        this.zillowProperty &&
        this.zillowProperty.homeStatus === "FOR_SALE"
      ) {
        console.log("--- this.property.zillowData", this.zillowProperty);
        await this.showToast(
          "No comps found. Setting CMA to property list price."
        );
        this.cmaInput.setValue(this.zillowProperty.price);
        this.cmaInput.markAsPristine();
        await this.saveCma();
        // this.setCMA(this.zillowProperty.price)
        // this.setVALU()
      } else {
        await this.showToast(
          "No comps found. Please input CMA before valuing."
        );
      }
    }
  }

  saveComps = async (nextPresented) => {
    console.log("000 the next presented: ", nextPresented);
    this.comps = nextPresented;
    await this.save();
  };

  // -------------------- Leadgen Helpers ------------------ //
  shareProperty = async (
    emails: string,
    subject: string,
    message: string,
    ccMyEmail = false
  ) => {
    try {
      const { property } = await this.propertyService2.sendLeadgenLink(
        this.property._id,
        emails,
        subject,
        message,
        ccMyEmail
      );
      console.log("-0-- the property updates is: ", property);
      console.log("--- VS:L ", this.property);
      this.property = property;
    } catch (e) {
      console.error("Error sharing property: ", e);
      await this.showToast("Error sharing property.");
    }
  };

  // -------------------- Template helpers/formatters -------------------- //
  change = async (t) => {
    if (!this.valu) {
      return await this.showToast("Must input CMA first");
    }
    this.tab = t;
  };

  getImgSrc(property: IProperty) {
    return property.images.length > 0
      ? this.propertyService.getImage(property.thumbnail)
      : "assets/images/property/property-1.jpg";
  }

  formatNumber(num: number) {
    if (num == null || num == undefined || isNaN(num)) return "N/A";
    return Math.round(num).toLocaleString("en");
  }

  getPropertyFullAddress(property: IProperty) {
    const address = property.search_formatted_address
      ? property.search_formatted_address.replace(", USA", "")
      : property.formatted_address;
    return address;
  }

  getPropertyMainAddress(property: IProperty) {
    /** @note may not work everytime */
    const address = property.search_formatted_address
      ? property.search_formatted_address.replace(", USA", "")
      : property.formatted_address;
    return address.split(",")[0];
  }

  getPropertySecondaryAddress(property: IProperty) {
    const address = property.search_formatted_address
      ? property.search_formatted_address.replace(", USA", "")
      : property.formatted_address;
    const toks = address.split(",");
    const suffix = toks[toks.length - 1].trim();
    const city = toks[toks.length - 2].trim();
    return `${city}, ${suffix}`;
  }

  fmtDateSold(n: number) {
    return moment(n).format("M/D/YY");
  }

  dateSoldFromNow(n: number) {
    return moment(n).fromNow();
  }

  fmtDistance(lat: number, lon: number) {
    return `${calcCrow(
      lat,
      lon,
      this.property.Latitude,
      this.property.Longitude,
      true
    )} mi`;
  }

  handleSave = (nextComps) => {
    this.comps = nextComps;
    $("#searchCompsBtn").trigger("click");
  };

  async downloadPDF() {
    try {
      // fetch data
      const res = await this.propertyService.downloadPDF_v2(
        this.property,
        this.comps
      );
      // download in new tab
      const blob = new Blob([res], { type: "application/pdf" });
      const href = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = href;
      let fname = "VALUclick Report";
      if (this.property.search_formatted_address)
        fname += ` ${this.property.search_formatted_address.replace(
          ", USA",
          ""
        )}`;
      fname += ".pdf";
      a.download = fname;
      // a.target = "_blank";
      a.click();
      await this.showToast("Property Downloaded. Check your Downloads.");
    } catch (e) {
      console.error("Error downloading PDF");
      await this.showToast("Error downloading PDF. Please try again.");
    }
  }

  toggleOptions = () => {
    $("#options-modal").toggleClass("active");
  };

  /**
   * should be invoked from the delete-modal
   */
  async deleteProperty() {
    try {
      await this.propertyService.delete(this.property).toPromise();
      await this.showToast("Successfully deleted property");
      // await this.nav.navigateRoot("/home");
      // await this.nav.navigateForward("/home");
      await this.nav.pop();
    } catch (e) {
      console.error("Error deleting property: ", e);
      await this.showToast("Error deleting property.");
    }
  }

  toggleClaimModal = () => {
    $("#claim-modal").toggleClass("active");
  };

  toggleDeleteModal = () => {
    if ($("#options-modal").hasClass("active")) {
      $("#options-modal").toggleClass("active");
    }
    $("#delete-modal").toggleClass("active");
  };

  async claim() {
    this.eccoval.claimed = true;
    await this.save();
    this.toggleClaimModal();
    await this.showToast(
      'Property successfully claimed - it will appear in your "My Properties" list.'
    );
  }
}
