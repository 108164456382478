import { Component, Input } from "@angular/core";

@Component({
	selector: 'vc-textarea',
	template: `
<div class="vc-textarea">
	<label [ngStyle]="ngStyle_label">
		<textarea 
			placeholder=" "
			[value]="value"
			[disabled]="disabled || loading" 
			[ngStyle]="ngStyle" 
			[ngClass]="ngClass"
		>
		</textarea>
		<span>{{label}}</span>
	</label>
</div>
	`,
	// Note - currently the actual styling is in global.scss
	styleUrls: ['vc-textarea.component.scss']
})
export class VcTextArea {
	@Input() loading = false
	@Input() disabled = false
	@Input() txt = 'Submit'
	@Input() click
	@Input() id
	@Input() ngStyle = {}
	@Input() ngClass = []
	@Input() ngStyle_label = {}
	@Input() label = 'Label'
	@Input() value = ""
	constructor() {}
}