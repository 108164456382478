/**
 * Same as the register page with slight modifications, and exclusively the form.
 */
import { Component, Input, OnInit } from "@angular/core";
import { LoadingController, ModalController, ToastController } from "@ionic/angular";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";


@Component({
  selector: 'add-user-form',
  templateUrl: 'add-user-form.component.html',
  styleUrls: ['./add-user-form.component.scss'],
})

export class AddUserForm implements OnInit {
  @Input("onExit") onExit: () => void;

  public mail: string;
  public firstname: string;
  public lastname: string;
  public phone: string;

  constructor(
    private userService: UserAdminService,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private viewCtrl: ModalController
  ) {}

  ngOnInit() {}

  close() {
    this.viewCtrl.dismiss({ dismissed: '' })
  }

  async addSubUser() {
    const loadingCtl = await this.loadingController.create({})
    loadingCtl.present();

    // add the subuser
    // if the request fails, toast will be automatically displayed
    try {
      const res = await this.userService.addSubuser({
        firstname: this.firstname,
        lastname: this.lastname,
        mail: this.mail,
        phone: this.phone
      }).toPromise()
      console.log("RES IS: ", res)

      const toastCtl = await this.toastController.create({
        message: 'User invited',
        duration: 3000,
        position: 'top'
      })
      toastCtl.present();

      loadingCtl.dismiss()

      await this.onExit();

      this.close() 
    } catch(e) {
      console.log("ERROR ADDING SUBUSER: ", e)
      loadingCtl.dismiss()
    }

  }
}
