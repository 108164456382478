/**
 * @todo
 * 1. Bring back scrollIntoView({behavior: smooth}) or some type of scroll looked nicer. this.ignoreScroll value clashes with snapscroll eventListener
 * 2. Prev / Next should have some notion of geographical left-right or price left-right. perhaps fixed with sort functionality
 * 3. No-location provided state: search works, button to allow
 * 4. Fix initial location stuter state. It's doing that because the map is a different size when no cards exists | setup loading skeleton
 */
import { Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import {
  IProperty,
  ZillowProperty,
} from "src/app/interfaces/property.interface";
import { PropertyService } from "src/app/services/property.service";
import { PropertyService2 } from "src/app/services/property2.service";
import { fmtPrice, formatNumber } from "src/app/util/util";

declare var google: any;

@Component({
  selector: "add-property-v2",
  templateUrl: "add-property.page.html",
  styleUrls: ["add-property.page.scss"],
})
export class AddPropertyPage implements OnInit {
  loadingQuickProperties = true;
  quickSearchProps = [];
  markers = [];
  claimedProperties: IProperty[] = [];

  // google map vals
  map: any;
  zoom = 15;

  currentProperty: ZillowProperty;
  currentPropertyObj: { zillowProperty: ZillowProperty; property: IProperty };
  get currentPropertyIdx() {
    return this.quickSearchProps.findIndex(
      (p) => p.address === this.currentProperty.address
    );
  }

  activePropertyFilter = false;
  filter = {};

  noSearch = false;

  constructor(
    public propertyService: PropertyService2,
    public nav: NavController,
    public origPropertyService: PropertyService
  ) {}

  async ngOnInit() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.handleLocationAccess,
        this.handleNoLocationAccess
      );
    }
    this.claimedProperties = await this.origPropertyService
      .findAll()
      .toPromise();
    this.mountSnapSlide();
  }

  handleLocationAccess = async (position) => {
    try {
      const { longitude, latitude } = position.coords;
      await this.mountMap(latitude, longitude);
    } catch (e) {
      console.error("Error retrieving close properties");
    }
  };

  handleNoLocationAccess = async (error) => {
    console.log("no position access: ", error);
    console.log("TODO: center randomly and search in that area");
  };

  fmtPrice(n) {
    return fmtPrice(n);
  }

  fmtNum(n: number) {
    if (typeof n === "number") return n.toLocaleString("en-US");
    return "";
  }

  navToProperty = () => {
    // this is nice but need to destroy the state at some point - nav around get's stuck here
    // this.nav.navigateForward(`/add/${this.currentProperty.zpid}`, {
    //   state: this.currentPropertyObj,
    // });
    this.nav.navigateForward(`/add/${this.currentProperty.zpid}`);
  };

  _selectProperty = (p, scroll = true) => {
    this.currentProperty = p;
    // give time for the marker to present itself with a quick timeout
    setTimeout(() => {
      this.setCurrentPropertyMarker(p);
    }, 50);
    if (scroll) {
      this.scrollToProperty(p);
    }
  };

  setQuickProps = (props) => {
    this.clearMarkers();
    this.quickSearchProps = props;
    this.quickSearchProps.forEach((p) => {
      const propertyPosition = new google.maps.LatLng(p.latitude, p.longitude);
      const customMarker = new CustomMarker(
        propertyPosition,
        `$${formatNumber(p.price)}`,
        p.address,
        () => this._selectProperty(p)
      );
      customMarker.setMap(this.map);
      this.markers.push(customMarker);
    });
  };

  /**
   * Mount map that searches properties on scroll-release.
   */
  mountMap = async (lat, lng) => {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: new google.maps.LatLng(lat, lng),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      styles: [
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
      ],
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      clickable: true,
    });
    this.map.addListener("idle", async () => {
      console.log("IDLE CALLBACK");
      await this.search();
    });
  };

  search = async () => {
    console.log("SEARCHING");
    if (this.noSearch) {
      console.log("--- no search");
      return;
    }
    this.loadingQuickProperties = true;
    try {
      const mapBounds = this.map.getBounds();
      const res = await this.propertyService.quickSearchBounds(
        mapBounds.getNorthEast(),
        mapBounds.getSouthWest(),
        this.filter
      );

      // use to have a setTimeout that's not here anymore
      this.setQuickProps(
        res.props.sort((a, b) => {
          if (this.currentProperty) {
            if (this.currentProperty.address === a.address) return -1;
            else if (this.currentProperty.address === b.address) return 1;
          }
          return 0;
        })
      );
      if (this.quickSearchProps.length) {
        const currNotInMapBounds =
          this.currentProperty &&
          !this.quickSearchProps.find(
            (p) => p.address === this.currentProperty.address
          );
        if (!this.currentProperty || currNotInMapBounds) {
          this.currentProperty = this.quickSearchProps[0];
        }
        this._selectProperty(this.currentProperty, false);
      } else {
        this._selectProperty(null, false);
      }
    } catch (e) {
      console.error("Error searching:", e);
    }
    this.loadingQuickProperties = false;
  };

  get currentPropertyMarker() {
    return $("[data-address].active");
  }

  setCurrentPropertyMarker = (p) => {
    //  first load can't set marker properly
    // console.log($("[data-address]"));
    if (this.currentPropertyMarker) {
      this.currentPropertyMarker.removeClass("active");
    }
    $(`[data-address="${p.address}"]`).addClass("active");
  };

  get selectedPropertyAddress() {
    const { address } = this.currentProperty;
    if (typeof address === "object") {
      return `${address.streetAddress}, ${address.city}, ${address.state} ${address.zipcode}`;
    }
    return address;
  }

  getPropertyAddress(p) {
    const address = p.address;
    if (typeof address === "object") {
      return `${address.streetAddress}, ${address.city}, ${address.state} ${address.zipcode}`;
    }
    return address;
  }

  clearMarkers = () => {
    this.markers.forEach((m) => m.setMap(null));
    this.markers = [];
  };

  onSearch = async (addressObj) => {
    this.loadingQuickProperties = true;
    let results;
    this.noSearch = true;
    results = await this.propertyService.rawZillowSearch({
      location: addressObj.formatted_address,
    });
    if (results.zpid) {
      this.noSearch = true;
    }
    this.map.setCenter(
      new google.maps.LatLng(
        addressObj.geometry.location.lat(),
        addressObj.geometry.location.lng()
      )
    );
    if (results.zpid) {
      const property = await this.propertyService.findByZillowId(results.zpid);
      this.setQuickProps([property.zillowProperty]);
      this._selectProperty(property.zillowProperty, false);
      this.currentPropertyObj = property;
      console.log("-- property = ", property);
    }
    this.noSearch = false;
    console.log("results: ", results);
    this.loadingQuickProperties = false;
  };

  // ------------- Scroll Ctr ---------------- //
  mountSnapSlide = () => {
    const scrollCtr = document.querySelector(".scroll-ctr");
    if (scrollCtr) {
      scrollCtr.addEventListener("scroll", (evt) => {
        this.scrollSetCurrentProperty();
      });
    }
  };

  prev = () => {
    this._selectProperty(this.quickSearchProps[this.currentPropertyIdx - 1]);
  };

  next = () => {
    this._selectProperty(this.quickSearchProps[this.currentPropertyIdx + 1]);
  };

  scrollToProperty = (p) => {
    const propIdx = this.quickSearchProps.findIndex(
      (prop) => prop.address === p.address
    );
    const nextPane = $(`[data-idx="${propIdx}"]`);
    // this looks nice, but marker will stutter due to the scroll listener
    // nextPane.get(0).scrollIntoView({ behavior: "smooth" });
    nextPane.get(0).scrollIntoView();
  };

  scrollSetCurrentProperty = () => {
    const cards = document.querySelectorAll(".card");
    let closest = null;
    let closestIdx = 0;
    let closestDistance = Infinity;
    cards.forEach((item, idx) => {
      const rect = item.getBoundingClientRect();
      const distance = Math.abs(rect.left); // Distance of the item from the container's start
      if (distance < closestDistance) {
        closest = item;
        closestIdx = idx;
        closestDistance = distance;
      }
    });
    const closestPropertyIdx = parseInt(
      closest.parentElement.getAttribute("data-idx")
    );
    this._selectProperty(this.quickSearchProps[closestPropertyIdx], false);

    // adjust the btns
    const leftBtn = document.getElementById("left-nav") as HTMLButtonElement;
    const rightBtn = document.getElementById("right-nav") as HTMLButtonElement;
    if (this.quickSearchProps.length <= 1) {
      leftBtn.disabled = true;
      rightBtn.disabled = true;
    } else if (!closestIdx) {
      leftBtn.disabled = true;
      rightBtn.disabled = false;
    } else if (closestIdx === this.quickSearchProps.length - 1) {
      leftBtn.disabled = false;
      rightBtn.disabled = true;
    } else {
      leftBtn.disabled = false;
      rightBtn.disabled = false;
    }
  };

  togglePropertyFilter = () => {
    this.activePropertyFilter = !this.activePropertyFilter;
  };

  filterProperties = async (filter) => {
    console.log("--- filter to apply to search params: ", filter);
    let args: any = {};
    if (filter.priceRange[0] !== null) {
      args.minPrice = filter.priceRange[0];
    }
    if (filter.priceRange[1] !== null) {
      args.maxPrice = filter.priceRange[1];
    }
    // while i don't have x+ settings
    if (filter.beds !== null) {
      args.bedsMin = filter.beds;
      args.bedsMax = filter.beds;
    }
    if (filter.baths !== null) {
      args.bathsMin = filter.baths;
      args.bathsMax = filter.baths;
    }
    if (filter.size[0] !== null) {
      args.sqftMin = filter.size[0];
    }
    if (filter.size[1] !== null) {
      args.sqftMax = filter.size[1];
    }
    // this may not work - may need to be ForSale, ForRent, RecentlySold
    if (filter.type) {
      args.status_type = filter.type;
    }
    this.filter = args;
    await this.search();
    this.togglePropertyFilter();
  };

  isClaimed = (prop: ZillowProperty) => {
    // const address = prop.search_formatted_address
    //   ? prop.search_formatted_address
    //   : prop.formatted_address;
    // console.log("-- isclaimed? ", prop);
    // return !!this.quickSearchProps.find((p) => address.includes(p.address));
    return !!this.claimedProperties.find((p) => {
      const address = p.search_formatted_address
        ? p.search_formatted_address
        : p.formatted_address;
      return address.includes(prop.address);
    });
  };
}

export class CustomMarker extends google.maps.OverlayView {
  public div = null;
  public address = null;

  constructor(position, content, address, onClick = () => {}) {
    super();
    this.position = position;
    this.content = content;
    this.address = address;
    this.onClick = onClick;
  }

  onAdd() {
    this.div = document.createElement("div");
    this.div.style.position = "absolute";
    this.div.innerHTML = this.content;
    this.div.setAttribute("data-address", this.address);
    let panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(this.div);

    // come back to this: https://stackoverflow.com/questions/3361823/make-custom-overlay-clickable-google-maps-api-v3
    // if I click near a business, that pop-up appears
    // const me = this;
    // google.maps.event.addDomListeners(this.div, "click", function () {
    //   google.maps.event.trigger(me, "click");
    // });
    this.div.addEventListener("click", () => {
      console.log("--- ive been clicked", this.address);
      this.onClick();
    });
  }

  draw() {
    let overlayProjection = this.getProjection();
    let position = overlayProjection.fromLatLngToDivPixel(this.position);
    this.div.style.left = position.x + "px";
    this.div.style.top = position.y + "px";
    this.div.class = "marker";
    this.div.classList.add("marker");
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }
}
