import {Injectable} from "@angular/core";
import {Modifiers} from "./mock-activities";
import {ModifierTypes} from "./mock-activities";

import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { Events } from '@ionic/angular';


import { IModifier } from '../interfaces/modifier.interface';
import { flatMap, map, tap } from "rxjs/operators";


@Injectable()
export class ModifierService {


  private modifiers: IModifier[];
  private modifiertypes: any;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly events: Events
  ) {
    this.modifiertypes = ModifierTypes;
  }

  /**
   * for some reason the state was carrying between property detail page and quick calc
   * calling this in the constructor fixed it
   */
  resetToDefault(){
    if (this.modifiers)
      this.modifiers = this.modifiers.map(m => ({ ...m, value: 4 }))
  }


  getAllType(){
      return of(this.modifiertypes); 
  }

//Stub, remove latter when we are done with the real stuff. /KLB
  getItem(id: any){
    return this.modifiers.find(function (item){
      return item._id == id;
    });
  }

  getAll(): Observable<any> {
    if (this.modifiers) {
      return of(this.modifiers);
    } else {
      return this.findAll().pipe(tap((modifiers: any) => {
        this.modifiers = modifiers;
        return modifiers;
      }));
    }
  }

  //End of stubs...

  save(modifier: any): Observable<IModifier> {
    this.modifiers = undefined;
    if (!!modifier._id){
      return this.httpClient.post<IModifier>('modifier/update', modifier);
    } else {
      return this.httpClient.post<IModifier>('modifier/create', modifier);
    }
  }

/* --We may use these later to allow logos to be uploaded....
  uploadimage(imageData: any): Observable<IOrg> {
    let headers = new HttpHeaders();
    //headers.append('Accept', 'application/json');
    //headers.append("Content-Type", "application/json");
    headers.append("Content-Type", 'multipart/form-data');

    return this.httpClient.post('property/image', imageData, { headers: headers });
  }

  getImage(imgId){
    if (!imgId){
      return 'assets/img/logo.png';
    }
    return this.configService.getSvcURL("property/image?imgId=" + imgId)
  }

  */

  delete(modifier: any): Observable<IModifier> {
    this.modifiers = undefined;
    if (!modifier._id){
      return this.httpClient.get<IModifier>('modifier/delete?id='+ modifier._id);
    } else {
      return this.httpClient.get<IModifier>('modifier/delete?id='+ modifier._id);
    }
  }

  findAll(): Observable<IModifier[]> {
    return this.httpClient.get<IModifier[]>('modifier/');
  }

  findById(id: any): Observable<IModifier>{
    return this.httpClient.get<IModifier>('modifier/byid?id='+ id);
  }

  remove(item) {
    this.modifiers.splice(this.modifiers.indexOf(item), 1);
  }

}
