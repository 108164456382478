import { Component, OnInit } from "@angular/core";
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
@Component({
  selector: 'page-register-success',
  templateUrl: './register-success.html',
  styleUrls: ['./register-success.scss']
})
export class RegisterSuccessPage implements OnInit {
  private response;
  private user;
  private code;
  private teamMembers = [];
  private inviteUrl;

  constructor(
    public readonly authService: AuthService,
    public readonly nav: NavController,
    public readonly loadCtrl: LoadingController
  ) { }
  ngOnInit(): void {
    // update the team lead with stripe customer ID, generate a team code
    const session_id = window.location.search.split('=')[1]
    this.load(session_id)
    // this.authService.successfulRegistration(session_id)
    //   .subscribe(async (d: { updatedUser: IUser }) => {
    //     console.log("RESPONSE FROM SUCCESSFUL REGISTRATION: ", d)
    //     this.response = d
    //     this.user = d.updatedUser
    //     this.code = d.updatedUser.brokerage_code
    //     this.inviteUrl = `${window.location.href.split('/success')[0]}/register?code=${d.updatedUser.brokerage_code}`
    //     await this.authService.refreshtoken();
    //   })
  }

  async load(session_id) {
    const loader = await this.loadCtrl.create({})
    loader.present()

    const d: any = await this.authService.successfulRegistration(session_id).toPromise()
    this.response = d.updatedUser
    this.user = d.updatedUser
    this.code = d.updatedUser.brokerage_code
    this.inviteUrl = `${window.location.href.split('/success')[0]}/register?code=${d.updatedUser.brokerage_code}`
    await this.authService.refreshtoken();

    loader.dismiss()
    this.toHome()
  }

  toHome() {
    this.nav.navigateForward('/home')
  }
}