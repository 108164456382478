import { Component, NgModule } from "@angular/core";
import { NavController, Events } from '@ionic/angular';
import { AuthService } from "../../services/auth.service";
import { UserAdminService } from "../../services/user.service";
import { ToastController } from '@ionic/angular';
import { IUser } from "../../interfaces/user.interface";
import { Title } from "@angular/platform-browser";
import * as moment from "moment";
import { TITLES } from "src/app/util/constants";

declare var SqPaymentForm: any; //magic to allow us to access the SquarePaymentForm lib

interface InvoiceSummary { 
  amtDue: number 
  amtPaid: number
  period_start: string
  period_end: string
  lines: { amount: number }[]
  discount?: {
    start: string
    end: string
    coupon: {
      id: string
      name: string
    }
  }
}
interface PastInvoice extends InvoiceSummary {
  hostedInvoiceUrl: string
  invoicePdfUrl: string
}

@Component({
  selector: 'user-profile',
  templateUrl: 'profile.html'
})
export class ProfilePage {
  email: string;
  firstname: string;
  lastname: string;
  zip: string;
  address: string;
  city: any;
  state: any;
  public paymentForm: any;
  public user: IUser;
  public subusers: IUser[] = [];
  upcomingInvoice: InvoiceSummary;
  pastInvoices: PastInvoice[] = []

  constructor(
    public nav: NavController,
    public events: Events,
    public auth: AuthService,
    public users: UserAdminService,
    public toastCtrl: ToastController,
    public userService: UserAdminService,
    public titleService: Title
  ) {
    document.title = TITLES.EDIT_PROFILE

    // not using square anymore bc it didn't fit our subscription model as well as stripe
    // if (window.location.protocol === 'https:') {
    //   this.auth.getSquareWebToken().subscribe(webToken => {

    //     this.paymentForm = new SqPaymentForm({
    //       // Initialize the payment form elements
    //       //TODO: Replace with prod app ID for production from a legit location
    //       applicationId: webToken.squareApplicationId,
    //       autoBuild: false,
    //       // Initialize the credit card placeholders
    //       card: {
    //         elementId: 'sq-card',
    //       },
    //       // SqPaymentForm callback functions
    //       callbacks: {
    //         cardNonceResponseReceived: (errors, nonce, cardData) => {
    //           if (errors) {
    //             // Log errors from nonce generation to the browser developer console.
    //             errors.forEach(function (error) {
    //               console.error('  ' + error.message);
    //             });
    //             alert('Encountered errors, check browser developer console for more details');
    //             return;
    //           }

    //           this.users.saveCard(nonce).subscribe((user) => {
    //             this.user = user;
    //             this.toastCtrl.create({
    //               message: 'Credit Card has successfully been updated!',
    //               duration: 3000,
    //               position: 'top'
    //             }).then(ctl => {
    //               ctl.present();
    //             });
    //           });
    //         }
    //       }
    //     });
    //     if (this.paymentForm) {
    //       this.paymentForm.build(); // build the square form
    //     }
    //   });
    // } else {
    //   console.warn('Site not using SSL, SQUARE setup skipped.')
    // }

    this.user = this.auth.getUser(); // trigger the user profile get
    if (!this.user.profile) {
      this.user.profile = { // if the user has no profile, make one (this is mainly for older user accounts)
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: null,
        phone: '',
        image: '',
        brokerage: ''
      };
    }

    this.userService.findByBrokerageCode(this.user.brokerage_code)
      .subscribe(
        (data) => {
          console.log("current user: ", this.user)
          console.log("SUBYSERS ARE: ", data.filter(u => u.local.email !== this.user.local.email))
          this.subusers = data.filter(u => u.local.email !== this.user.local.email) 
        },
        (err) => {
          console.log("ERROR LOADING SUBUSERS")
        }
      )
  }

  saveProfile() {
    // save the user information
    this.users.save(this.user).subscribe((user) => {
      this.user = user;
      this.toastCtrl.create({
        message: 'User Information has been updated Successfully!',
        duration: 3000,
        position: 'top'
      }).then(ctl => {
        ctl.present();
      });
    });
  }

  navToBilling() {
    this.nav.navigateForward('')
  }

  /**
   * @deprecated
   */
  saveBillingInformation() {
    // save the user information
    this.users.save(this.user).subscribe((user) => {
      this.user = user;
      this.toastCtrl.create({
        message: 'Billing Address has been updated Successfully!',
        duration: 3000,
        position: 'top'
      }).then(ctl => {
        ctl.present();
      })
    });
  }

  /**
   * @deprecated
   */
  saveCCInformation() {
    if (this.paymentForm) {
      this.paymentForm.requestCardNonce();
    } else {
      this.toastCtrl.create({
        message: 'Payment form not initialized, skipping.',
        duration: 3000,
        position: 'top'
      }).then((ctl) => {
        ctl.present();
      });
    }
  }

  /**
   * @deprecated
   */
  downloadInvoice() {
    // download the square invoice that was selected - this is a receipt object off the Charge Object
  }

  deleteAccount() {
    console.log("DELETING ACCOUNT")
    if (confirm("Are you sure?")) {
      this.auth.deleteAccount().subscribe(
        (res) => {
          alert("We hate to see you go. Thank you for trying VALUclick!")
          console.log("RES IS: ", res)
          this.auth.signOut();
        },
        (err) => {
          console.log("ERROR IS: ", err)
        }
      )
    }
  }


}
