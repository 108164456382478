import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { compsTable } from "./compsTable";
import { AgGridAngular } from "ag-grid-angular";
import * as _ from "lodash";
import { ColDef, GridReadyEvent, GridOptions } from "ag-grid-community";
import { IProperty } from "src/app/interfaces/property.interface";

@Component({
  selector: "comps-table",
  templateUrl: "comps-table.component.html",
  styleUrls: ["comps-table.component.scss"],
})
export class CompsTable implements OnInit {
  @Input() availableComps: any[];
  @Input() presentedComps: any[];
  @Input() currProperty: IProperty;
  @Input() handleSave: (nextComps: any[]) => void;

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  cols: ColDef[];
  defColDef = compsTable.defColDef;
  gridOptions: GridOptions;

  compsToPresent: any[];

  constructor() {
    this.togglePresented = this.togglePresented.bind(this);
  }

  ngOnInit() {
    this.compsToPresent = [...this.presentedComps];
    this.cols = compsTable.cols(
      this.currProperty,
      this.presentedComps,
      this.togglePresented
    );
    this.gridOptions = compsTable.gridOptions(this.presentedComps);
  }

  togglePresented(property: any, checked: boolean) {
    this.compsToPresent = _.xor(this.compsToPresent, [property]);
  }

  get saveEnabled() {
    const normalizedSort = (a, b) => a.dateSold - b.dateSold;
    const orderedNextComps = this.compsToPresent.sort(normalizedSort);
    const orderedOldComps = this.presentedComps.sort(normalizedSort);
    return !_.isEqual(orderedNextComps, orderedOldComps);
  }

  save() {
    this.handleSave(this.compsToPresent);
  }
}
