import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IProperty } from "../interfaces/property.interface";
import * as queryString from "querystring";

interface SearchAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  formatted_address: string;
}

@Injectable()
export class PropertyService2 {
  constructor(private readonly httpClient: HttpClient) {}

  async search(address: SearchAddress): Promise<{ property: IProperty }> {
    return this.httpClient
      .post<{ property: IProperty }>("property2/search", { address })
      .toPromise();
  }

  async searchZillow(address: SearchAddress): Promise<{ zpid: number }> {
    return this.httpClient
      .get<any>(
        `property2/search/zillow?${queryString.stringify({
          location: address.formatted_address,
        })}`
      )
      .toPromise();
  }

  async quickSearch(
    longitude: number,
    latitude: number
  ): Promise<{ props: any[] }> {
    return this.httpClient
      .get<any>(
        `property2/search/zillow?${queryString.stringify({
          coordinates: `${longitude} ${latitude},5`,
          // status_type: "RecentlySold",
        })}`
      )
      .toPromise();
  }

  async rawZillowSearch(obj: any): Promise<any> {
    return this.httpClient
      .get<any>(`property2/search/zillow?${queryString.stringify(obj)}`)
      .toPromise();
  }

  async quickSearchBounds(
    neBounds,
    swBounds,
    otherArgs = {}
  ): Promise<{ props: any[] }> {
    const north = neBounds.lat();
    const east = neBounds.lng();
    const west = swBounds.lng();
    const south = swBounds.lat();
    const ne = `${east} ${north}`;
    const nw = `${west} ${north}`;
    const sw = `${west} ${south}`;
    const se = `${east} ${south}`;
    return this.httpClient
      .get<any>(
        `property2/search/zillow?${queryString.stringify({
          // coordinates: `${longitude} ${latitude},5`,
          polygon: `${ne}, ${nw}, ${sw}, ${se}, ${ne}`,
          // status_type: "RecentlySold",
          ...otherArgs,
        })}`
      )
      .toPromise();
  }

  findByZillowId(
    zpid: string
  ): Promise<{ property: IProperty; zillowProperty: any }> {
    return this.httpClient
      .get<{ property: IProperty; zillowProperty: any }>(
        `property2/zillow/${zpid}`
      )
      .toPromise();
  }

  async save(property: IProperty) {
    return this.httpClient.post(`property2/save`, property).toPromise();
  }

  async sendLeadgenLink(
    id,
    emails,
    subject,
    message,
    ccMyEmail = false
  ): Promise<{ property: IProperty; newprofile: any }> {
    return this.httpClient
      .post<{ property: IProperty; newprofile: any }>(
        `property2/${id}/leadgen`,
        { emails, subject, message, ccMyEmail }
      )
      .toPromise();
  }

  async sendLeadgenTemplateLink(
    properties: IProperty[],
    template
  ): Promise<any> {
    return this.httpClient
      .post<any>(`property2/leadgen/batch`, { properties, template })
      .toPromise();
  }
}
