import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { IProperty } from "src/app/interfaces/property.interface";
import { PropertyService } from "src/app/services/property.service";

@Component({
	selector: 'add-valu-modal',
	templateUrl: 'add-valu-modal.component.html',
	styleUrls: ['add-valu-modal.component.scss']
})
export class AddValuModal implements OnInit {
	private name
	private property: IProperty

	constructor(
		private viewCtrl: ModalController,
		private params: NavParams,
		private toastCtl: ToastController,
		private propertyService: PropertyService
	) {
		this.property = params.get("property")
		if (!this.property.eccovals[0].priceadjustment) {
			toastCtl.create({ message: 'Must generate VALU before sharing a property.', duration: 3000, position: 'top' })
				.then(t => t.present())
				.then(this.cancel)
		}
	}

  cancel() {
    this.viewCtrl.dismiss({ reload: false })
  }

	/**
	 * @todo get this to auto focus when the modal starts
	 */
	ngOnInit(): void {
		// $('#valuname').focus()
	}

	submit = async () => {
		await this.propertyService.createValu(this.property._id, this.name)
		this.viewCtrl.dismiss({ reload: true })
	}
}