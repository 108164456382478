/**
 * Page to display the email currently on file for the user, an ability to update it,
 * and an ability to send a verification email. There will be an input where the user can input the token as well.
 */
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NavController, ToastController } from "@ionic/angular";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { ValidationService } from "src/app/services/validation.service";

@Component({
	selector: 'register-verify-email',
	templateUrl: './register-verify-email.html',
	styleUrls: ['./register-verify-email.scss']
})
export class RegisterVerifyEmailPage implements OnInit {
	user: IUser
	code: string
	@Input() onChange: (code: string) => void
	@Input() resend: () => void
	updatingEmail = false
	form = this.fb.group({
		email: ['', this.validationService.emailValidation]
	})
	// for the control
	// we need access to the email
	@Input() onEmailUpdate: (user) => Promise<void>
	checkVerificationInterval: any
	loadingUpdate = false

	constructor(
		public auth: AuthService,
		public userService: UserAdminService,
		public nav: NavController,
		public toastCtl: ToastController,
		public fb: FormBuilder,
		public validationService: ValidationService
	) {}

	async ngOnInit() {
		this.user = this.auth.getUser()
		console.log('im here for user: ', this.user.local.email)	
		const me = this
		$('#code').on('input', function(e) {
			const val = $(this).val()
			if (me.onChange) {
				me.onChange(val as string)
			}
		})

		// automatically snap this page out of "verification mode" if the user verified somewhere else
		this.checkVerificationInterval = setInterval(async () => {
			console.log('checking if user is verified...')
			try {
				await this.checkIfUserVerified()
			} catch (e) {
				console.error('Error checking if the user is verified...')
			}
		}, 5 * 1000)
	}

	/**
	 * Helper function to check the API to see if they've successfully verified on another device.
	 */
	async checkIfUserVerified() {
		const user = await this.auth.getFreshUser(true)
		if (!user.inactive) {
			clearInterval(this.checkVerificationInterval)
			this.nav.navigateRoot('home')
		}
	}

	async sendActivationEmail() {
		// if coming from the parent, use that one instead
		if (this.resend) {
			return this.resend()
		}
		// const res = await this.userService.resendInvite(this.user).toPromise()
		const res = await this.auth.sendActivationEmail()
		console.log('-- the res = ', res)
		const toast = await this.toastCtl.create({ message: 'Activation Email Sent', duration: 3000, position: 'top' })
		await toast.present()
	}

	async activateAccount() {
		alert('Fix me')
		// const res = await this.auth.activateAccount(this.code)
		// console.log('-- and the activation account = ', res)
		// this.nav.navigateForward('home')
	}

	async updateEmail() {
		const email = this.form.get('email').value
		if (this.form.get('email').invalid) {
			const toast = await this.toastCtl.create({ message: 'Please provide valid email and try again.', duration: 3000, position: 'top' })
			return await toast.present()
		}
		this.loadingUpdate = true
		try {
			const res = await this.userService.updateEmail(email, null)
			const toast = await this.toastCtl.create({ message: `Activation sent to updated email ${email}`, duration: 3000, position: 'top' })
			await toast.present()
			console.warn(' -- here we need to update the user now!')
			if (this.onEmailUpdate) {
				await this.onEmailUpdate(res)
			}
			this.user = res
			this.form.setValue({email: ''})
			this.updatingEmail = false
		} catch (e) {
			console.error(`Error updating email: `, e)
			const toast = await this.toastCtl.create({ message: 'Error sending activation email. Try again.', duration: 3000, position: 'top' })
			await toast.present()
		}
		this.loadingUpdate = false
	}
}