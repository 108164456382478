import { Component, Input } from "@angular/core";
import { ModalController, NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'stripe-checkout-modal',
  templateUrl: './stripe-checkout-modal.html',
  styleUrls: ['./stripe-checkout-modal.scss']
})
export class StripeCheckoutModal {
  @Input("checkoutUrl") checkoutUrl;

  constructor(
    public viewCtrl: ModalController, 
    public params: NavParams, 
    private sanitizer: DomSanitizer
  ) {}

  checkout() {
    location.href = this.checkoutUrl
  }
}