import { Component } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";

@Component({
	selector: 'force-upgrade',
	templateUrl: 'force-upgrade.html',
	styleUrls: ['force-upgrade.scss']
})

export class ForceUpgrade {
	private tier: string
	private submitting = false
	private userId

	constructor(
		private toastCtl: ToastController,
		private userService: UserAdminService,
		private authService: AuthService
	) {
		this.userId = this.authService.getUser()._id
	}

	setTier = t => this.tier = t

	/**
	 * Send a subscription upgrade command - note, it needs to be charged immediately.
	 */
	upgrade = async () => {
		this.submitting = true
		try {
			const res = await this.userService.setSub(this.userId, this.tier)
			console.log('res is: ', res)
			await this.authService.getFreshUser(true)
			alert('Upgrade successful. Reloading to update your session status.')
			window.location.reload() // this will force it to refresh as dumb as this is
		} catch (e) {
			console.error('Error upgrading subscription: ', e)
			this.toastCtl.create({ message: 'Error upgrading. Contact support@valuclick.co for assistance.', position: 'top' }).then(t => t.present())
		}
		this.submitting = false
	}
}