import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ToastController } from "@ionic/angular";
import * as moment from "moment";
import { MESSAGE_2 } from "src/app/components/share-valu-modal/share-valu-constants";
import { IProperty } from "src/app/interfaces/property.interface";
import { IUser, UserLeadgenTemplate } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { ValidationService } from "src/app/services/validation.service";
import { fmtPrice } from "src/app/util/util";

@Component({
  selector: "leads",
  templateUrl: "leads.component.html",
  styleUrls: ["leads.component.scss"],
})
export class Leads implements OnInit {
  @Input() property: IProperty;
  @Input() shareProperty: (
    email: string,
    subject: string,
    message: string,
    ccMyEmail: boolean
  ) => void;

  // public hasShared = false;
  public sending = false;
  public emailForm = this.fb.group({
    // for now, only ONE email. does support multiple
    email: [
      "",
      {
        validators: [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), // Validator.email allows a@b
        ],
        updateOn: "change",
      },
    ],
    ccMyEmail: [false, { validators: [Validators.required] }],
    subject: ["", { validators: [Validators.required] }],
    message: [
      MESSAGE_2(this.auth.getUser()),
      { validators: [Validators.required] },
    ],
  });

  // shareproperty leadgen member vars
  displayTemplateEditor = false;
  expanded = false;
  template: UserLeadgenTemplate;
  templates: UserLeadgenTemplate[];

  constructor(
    public fb: FormBuilder,
    public validationService: ValidationService,
    public auth: AuthService,
    public toastCtl: ToastController,
    public userService: UserAdminService
  ) {}

  async ngOnInit() {
    this.templates = await this.userService.getLeadgenTemplates(
      this.auth.getUser()._id,
      true
    );
    // there will always be one default, regardless of prior leadgen state
    this.selectTemplate(this.templates[0]);
    console.log("Templates are: ", this.templates);
  }

  get displayedTemplates() {
    return this.templates ? this.templates.filter((t) => t._id) : [];
  }

  async showToast(message) {
    const toast = await this.toastCtl.create({
      message,
      position: "top",
      duration: 3000,
    });
    await toast.present();
  }

  get hasShared() {
    return this.property.eccovals.length > 1;
  }

  async onShareProperty() {
    this.sending = true;
    try {
      const { email, subject, message, ccMyEmail } = this.emailForm.value;
      await this.shareProperty(email, subject, message, ccMyEmail);
    } catch (e) {
      console.error("Error sharing property: ", e);
      await this.showToast("Error sharing property.");
    }
    this.sending = false;
  }

  get guestValuation() {
    return this.hasShared ? this.property.eccovals[1] : null;
  }

  get totalViews() {
    return this.guestValuation
      ? (this.guestValuation.guestviews as number)
      : "n/a";
  }
  get totalAdjustments() {
    return this.guestValuation ? this.guestValuation.guestclicks : "n/a";
  }
  get guestValu() {
    return this.guestValuation &&
      typeof this.totalViews == "number" &&
      this.totalViews > 0
      ? fmtPrice(this.guestValuation.priceadjustment, true)
      : "n/a";
  }
  get guestDOM() {
    return this.guestValuation && typeof this.guestValu === "number"
      ? fmtPrice(this.guestValuation.priceadjustment, true)
      : "n/a";
  }

  get timeline() {
    const events = [];
    if (this.hasShared) {
      events.push({
        date: moment(this.property.eccovals[1].created_on).format("M/D/YY"),
        event: `Property shared with ${this.guestValuation.emailed_to[0].email}`,
      });
    }
    return events;
  }

  // ------------- shareproperty leadgen member vars
  toggleTemplateEditor = () => {
    this.displayTemplateEditor = !this.displayTemplateEditor;
    const resetFromCustom = !this.displayTemplateEditor && !this.template._id;
    if (resetFromCustom) {
      this.selectTemplate(this.templates[0]);
    }
  };

  onTemplateSave = async (template: UserLeadgenTemplate) => {
    // await this.auth.getFreshUser(true);
    // this.emailForm.setValue(template);
    // this.emailForm.setValue({
    //   ...this.emailForm.value,
    //   subject: template.subject,
    //   message: template.content,
    // });
    this.templates = await this.userService.getLeadgenTemplates(
      this.auth.getUser()._id,
      true
    );
    this.selectTemplate(template);
    this.toggleTemplateEditor();
  };

  toggleMessageExpand = () => {
    this.expanded = !this.expanded;
  };

  selectTemplate = (tpl) => {
    this.template = tpl;
    this.emailForm.patchValue({
      subject: tpl.subject,
      message: tpl.content,
    });
  };

  createNewTemplate = () => {
    this.template = this.templates.find((d) => !d._id);
    this.toggleTemplateEditor();
  };
}
