import { Component, NgModule, OnInit } from "@angular/core";
import { NavController, PopoverController, ModalController, MenuController } from '@ionic/angular';
import { PropertyService } from "../../services/property.service";
import { UserAdminService } from "../../services/user.service";
import { ISearchParams, SourcedataService } from "../../services/sourcedata.service";
import { LocationService } from "../../services/location.service";
import { PropertyDetailsModal } from "../property-details-modal/property-details-modal.component";
import { Title } from "@angular/platform-browser";
import { Events } from '@ionic/angular';
import { AppInfoService } from "src/app/services/appinfo.service";
import { HttpClient } from "@angular/common/http";
import { CardClaimedProperty } from '../../components/card-claimed-property/card-claimed-property.component';
import { IAddress, IProperty } from "src/app/interfaces/property.interface";
import { AuthService } from "src/app/services/auth.service";
import { IUser } from "src/app/interfaces/user.interface";
import { CheckoutRequiredModal } from "../modal/checkout-required-modal/checkout-required.component";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from 'rxjs/operators';
import { TITLES } from "src/app/util/constants";

@Component({
  selector: 'page-home',
  templateUrl: 'home.html',
  styleUrls: ['./home.scss'],
})

export class HomePage implements OnInit {
  // search condition
  public search = {
    name: "New York, United States",
    date: new Date().toISOString()
  }

  public searchText: string = "";
  public location: any;
  public properties: any;
  public searchProperties: any;
  public searchparams: ISearchParams = { 'empty': { display: '', type: '', key: '', value: '' } };
  public staticsearch = {
    streetname: "empty",
    streetnumber: "empty",
    city: "empty",
    postalcode: "empty",
    listingid: "empty"
  };
  public addressObj: IAddress;

  loadstatus = 'Loading your properties...';

  public refreshingProperty: IProperty;

  public currUser: IUser;

  constructor(public nav: NavController, public popoverCtrl: PopoverController,
    public propertyService: PropertyService, public userService: UserAdminService,
    public sourcedataService: SourcedataService, public locationService: LocationService, public modalController: ModalController,
    public menuCtrl: MenuController, public titleService: Title,
    private readonly events: Events,
    public appInfoService: AppInfoService,
    private readonly httpClient: HttpClient,
    public authService: AuthService, router: Router) {

    this.currUser = this.authService.getUser()

    // does this do anything?
    // commented out 5.11.23
    // sourcedataService.getParamFields().subscribe(
    //   (params: ISearchParams) => {
    //     this.staticsearch = {
    //       streetname: "StreetName",
    //       streetnumber: "StreetNumberNumeric",
    //       city: "City",
    //       postalcode: "PostalCode",
    //       listingid: "ListingId"
    //     };

    //     if (params) {
    //       this.searchparams = params;
    //     }
    //   });

      /**
       * Whenever the user navigates to this page, they're refreshed.
       */
      router.events.pipe(
        filter(event => event instanceof NavigationEnd)  
      ).subscribe((event: NavigationEnd) => {
        if (event.url === '/home') {
          document.title = TITLES.HOME
          $('#home-tab').trigger('click')
          const input = document.getElementById("autosearch") as HTMLInputElement
          if (input) { // no input if trial expired
            input.style.border = 'solid 1px #ced4da'
            input.value = ''
          }
          this.setProperty(null)
          this.setAddressObj(null)
        }
      });
    
  }

  ngOnInit(): void {
    this.loadProperties();
    this.blockAppIfNoPaymentInfo()
    // this.authService.refreshtoken().then(console.log).catch(console.error)
    // handle the tab border so we can see distinction between the 3 tab buttons
    $('.nav-link').on('click', function () {
      // console.log('--- NAV LINK CLICKED: ', this)
      if (this.id === 'home-tab') {
        $('#profile-tab').css('border-top-right-radius', 0)
        $('#profile-tab').css('border-bottom-right-radius', 0)
        $('#profile-tab').css('border-right', 'solid 1px rgba(0, 0, 0, 0.15)')
        $(this).css('border-top-right-radius', 0)
        $(this).css('border-bottom-right-radius', 0)
      }
      else if (this.id === 'leadgen-tab') {
        $('#profile-tab').css('border-top-left-radius', 0)
        $('#profile-tab').css('border-bottom-left-radius', 0)
        $('#profile-tab').css('border-left', 'solid 1px rgba(0, 0, 0, 0.15)')
        $(this).css('border-top-left-radius', 0)
        $(this).css('border-bottom-left-radius', 0)
      }
      else {
        $(this).css('border-radius', '0')
        $(this).css('border', 'none')
      }
    })
  }

  loadProperties = async () => {
    this.loadstatus = 'Loading your properties...';
    // this.propertyService.findAll().subscribe(
    //   (properties: any) => {
    //     // console.log(properties);
    //     properties.reverse();
    //     this.properties = properties;
    //   }, err => {
    //     this.loadstatus = 'Error loading your properties.';
    //     this.events.publish('service.exception', err);
    //     this.properties = undefined;
    //   });
    try {
      const props = await this.propertyService.findAll().toPromise()
      props.reverse()
      this.properties = props
    } catch (err) {
      console.error('Error loading properties', err)
      this.loadstatus = 'Error loading your properties.';
      this.events.publish('service.exception', err);
      this.properties = undefined;
    }
  }

  async blockAppIfNoPaymentInfo() {
    if (this.currUser.account_lead && !this.currUser.stripe_customer_id) {
      this.modalCtrl = await this.modalController.create({
        component: CheckoutRequiredModal,
        componentProps: { email: this.currUser.local.email, disableCloseBtn: true },
        backdropDismiss: false
      })
      this.modalCtrl.present()
    }
  }

  setProperty(property: IProperty) {
    console.log("PROPERTY EVENT: ", property)
    this.searchProperties = property ? [property] : []
  }

  setAddressObj(addressObj: IAddress) {
    console.log("ADDRESS EVENT: ", addressObj)
    this.addressObj = addressObj;
  }

  // ionViewWillEnter() {
  //   // this.search.pickup = "Rio de Janeiro, Brazil";
  //   // this.search.dropOff = "Same as pickup";
  //   this.storage.get('pickup').then((val) => {
  //     if (val === null) {
  //       this.search.name = "";
  //     } else {
  //       this.search.name = val;
  //     }
  //   }).catch((err) => {
  //     console.log(err)
  //   });
  // }

  /**
   * Refresh the card data when we come back from updating our claimed properies
   */
  ionViewWillEnter() {
    this.loadProperties()
  }

  // go to result page
  doSearch() {
    //  this.nav.push(PropertylistPage);
  }

  // choose place
  choosePlace(from) {
    this.nav.navigateForward('search');
  }


  // to go account page
  goToAccount() {
    this.nav.navigateForward('settings');
  }

  /*presentNotifications(myEvent) {
    console.log(myEvent);
    let popover = this.popoverCtrl.create(NotificationsPage);
    popover.then(ctl => {
      ctl.present({
        ev: myEvent
      });
    })
  }*/

  // view trip detail
  viewDetail(id, event) {
    if (event.srcElement.id !== "delete") {
      this.nav.navigateForward(['property', id]);
    }
  }

  deleteProperty(id) {
    this.propertyService.delete(id).subscribe(
      (deleted: any) => {
        this.propertyService.findAll().subscribe(
          (properties: any) => {
            this.properties = properties;
          })
      })
  }

  public searchSourceData() {
    this.sourcedataService.getProperties(this.searchparams).subscribe(
      (property: any[]) => {
        this.searchProperties = property;
      });
  }

  public async searchTovoData() {
    alert("hit");
    this.httpClient.get<any>('sourcedata/tovoSearchProp')
      .subscribe(
        async (data) => {
          console.log("0--- DATA IS")
          console.log(data)
          alert("DATA IS: " + JSON.stringify(data))
        }
      )
  }

  private formatAddress(element: any) {
    if (element.formatted_address) {
      return element.formatted_address;
    } else {
      return element.StreetNumberNumeric + ' ' + element.StreetName + ' ' + element.City + ', ' + element.StateOrProvince + ' ' + element.PostalCode;
    }
  }

  private modalCtrl: any;

  public async presentPropertyDetailsModal(property: any) {

    this.modalCtrl = await this.modalController.create(
      {
        component: PropertyDetailsModal,
        componentProps: { property: property }
      });

    this.modalCtrl.onDidDismiss().then((detail: any) => {
      if (detail !== null) {
        this.loadProperties();
      }
    });

    return await this.modalCtrl.present();
  }

  createProperty() {
    this.nav.navigateForward('create');
  }

  getDescription(property) {
    return (property.PublicRemarks && property.PublicRemarks.left ? property.PublicRemarks.left(25) + '...' : property.PropertySubType);
  }

  async refreshData(event, property) {
    console.log("EVENT STOPPED - property = ", property)
    this.refreshingProperty = property;
    const res = await this.propertyService.refreshTovoData(property._id).subscribe(
      (r) => {
        console.log("--- RES: ", r)
        this.properties = this.properties.map(p => p._id === r._id ? r : p)
        this.refreshingProperty = null
      },
      (e) => {
        console.error('..... E ', e)
        this.refreshingProperty = null
      }
    )
    event.stopPropagation();
  }


  /**
   * Eccovals have moved from a single value to an array, allowing multiple
   * users to claim one property. These functions simplify extracting the VALU & CMA
   * for display
   */
  getVALU(property) {
    const eccoval = property.eccovals.find(e => e.created_by === this.currUser._id);
    if (!eccoval) {
      throw new Error("Can't get VALU for 'claimed' property");
    }
    // return eccoval.priceadjustment ? eccoval.priceadjustment : property.ListPrice
    return eccoval.priceadjustment ? eccoval.priceadjustment : 0;
  }

  getCMA(property) {
    const eccoval = property.eccovals.find(e => e.created_by === this.currUser._id);
    if (!eccoval) {
      throw new Error("Can't get CMA for 'claimed' property");
    }
    return eccoval.cmaprice ? eccoval.cmaprice : 0
  }

  isClaimed(property) {
    return !!this.getCMA(property)
  }

  getSqFt(property: IProperty) {
    if (property.LivingArea) {
      return property.LivingArea.toLocaleString()
    }
    try {
      return property.tovoData.results.details.sqFt.toLocaleString()
    } catch (e) {
      console.log("Error finding sq for property ", property)
      return 'N/A'
    }
  }

  get isLargerThanPhone() {
    return window.innerWidth > 600
  }
}
//
