/**
 * note we're not really using this component, we're mostly using the styles and the options object
 */
import { Options } from "@angular-slider/ngx-slider";
import { Component } from "@angular/core";
import { vcSliderOptions } from "./slider-options";

@Component({
  selector: "slider",
  templateUrl: "slider.component.html",
  styleUrls: ["slider.component.scss"],
})
export class Slider {
  options: Options = vcSliderOptions;
  constructor() {}

  onSliderChange(e) {
    console.log("--- slider change value", e);
  }
}
