import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { UserAdminService } from "src/app/services/user.service";
import { fmtPrice } from "src/app/util/util";

@Component({
  // selector: "paywall",
  templateUrl: "paywall.html",
  styleUrls: ["paywall.scss"],
})
export class PaywallModal implements OnInit {
  @Input() checkoutSessionUrl;
  @Input() needsNewSubscription;
  @Input() latestInvoice;
  @Input() paymentMethods;
  defaultPaymentMethod;
  addingCard = false;

  constructor(
    private viewCtl: ModalController,
    private userService: UserAdminService
  ) {}

  ngOnInit(): void {
    console.log("--- latestInvoice", this.latestInvoice);
    console.log("--- paymentMethods", this.paymentMethods);
    this.defaultPaymentMethod = this.paymentMethods[0]; // testing
  }

  toggleAddingCard() {
    this.addingCard = !this.addingCard;
  }

  async payAndSync() {
    console.log(this.latestInvoice, this.defaultPaymentMethod);
    const res = await this.userService.payInvoiceAndSyncSubscriptionPayment(
      this.latestInvoice,
      this.defaultPaymentMethod
    );
    console.log("--- res = ", res);
  }

  get owed() {
    console.log(this.latestInvoice.amount_due / 1000);
    return fmtPrice("" + Math.round(this.latestInvoice.amount_due / 100));
  }

  get paid() {
    return fmtPrice("" + Math.round(this.latestInvoice.amount_paid / 100));
  }

  get remain() {
    return fmtPrice("" + Math.round(this.latestInvoice.amount_remaining / 100));
  }
}
