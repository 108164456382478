import { Component, ViewChild, Input, OnInit } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import * as _ from 'lodash'
import { AdminUserDetailsModal } from "../admin-user-details-modal/admin-user-details-modal";
import * as moment from "moment";
import { getCreatedDate } from "src/app/util/util";

const fmtDate = (d, fmt = 'MM/DD/YYYY hh:mma') => moment(d).format(fmt)

interface Table { cols: ColDef[], defColDef: ColDef }
const userTable: Table = {
	cols:  [
		{ field: 'firstname', checkboxSelection: true, headerCheckboxSelection: true },
		{ field: 'lastname' },
		{ field: 'local.email', headerName: 'Email' },
		{ field: 'createdAt', valueFormatter: (p) => fmtDate(p) },
		{ field: 'account_lead', headerName: 'Is Account Lead' },
		{ field: 'tier', headerName: 'Tier' }
	],
	defColDef: {
		sortable: true,
		filter: true,
		flex: 1,
		resizable: true,
	},
}

@Component({
  selector: "admin-user-mgt",
  templateUrl: "./admin-user-mgt.html",
  styleUrls: ["./admin-user-mgt.scss"],
})
export class AdminUserMgtPage implements OnInit {
	users: IUser[]

	// grid props
	cols: ColDef[] = userTable.cols
	defColDef: ColDef = userTable.defColDef
	@ViewChild(AgGridAngular) agGrid!: AgGridAngular

	selected: IUser[] = []
	loading = false
	displayedRows = 0

	// freeTrialCode users
	freeTrialCodeUsers_DD14D62319 = []
	freeTrialCodeUsers_CF14A62314 = []

	constructor(
    public userService: UserAdminService,
    public auth: AuthService,
		public toastCtl: ToastController,
		public modalCtl: ModalController
  ) {}

  async ngOnInit() {
		// this.load()
		// // $('#user-tbl').DataTable()
		// const tbl = $('#user-tbl')
		// console.log('.... ok im doing something')
		// console.log('--- tbl = ', tbl)
		// // tbl.DataTable()
  }

	// ------------------------------------ Button Events
	async load() {
		this.loading = true
		try {
			// load the users and append a createdAt timestamp
			this.users = await this.userService.adminFindAll()
			console.log('-- users = ', this.users)
			this.users = this.users.map(u => ({ ...u, createdAt: getCreatedDate(u._id) }))
			this.users.reverse()
			this.agGrid.api.setRowData(this.users)
			this.displayedRows = this.users.length

			// for free trial reporting purposes
			this.freeTrialCodeUsers_DD14D62319 = this.users.filter(u => u.freeTrialCode === 'DD14D62319')
			this.freeTrialCodeUsers_CF14A62314 = this.users.filter(u => u.freeTrialCode === 'CF14A62314')
		} catch (e) {
			console.error(`Error loading users`, e)
			const toast = await this.toastCtl.create({ message: 'Error loading users' })
			await toast.present()
		}
		this.loading = false
	}

	async deleteUsers() {
		const msg = `Are you sure you'd like to delete ${this.selected.length} users?\n`
			+ this.selected.map(u => `  - ${u.firstname} ${u.lastname} (${u.local.email})?`).join('\n')
		if (confirm(msg)) {
			this.loading = true
			// delete users in parallel, display a toast for any that error
			const deletedUsers = await Promise.all(this.selected.map(u => {
				const res = this.userService.delete(u._id).toPromise().catch(
					async (e) => {
						console.error(`Error deleting user: `, u, e)
						const toast = await this.toastCtl.create({message: `Error deleting user ${u.firstname} ${u.lastname} (${u.local.email})`, position: 'top'})
						await toast.present()
						return null
					}
				)
				return res
			}))
			const successfullyDeletedCount = deletedUsers.filter(u => u)
			const toast = await this.toastCtl.create({ position: 'top', message: `Successfully deleted ${successfullyDeletedCount.length} / ${this.selected.length}` })
			await toast.present()
			this.selected = []
			this.loading = false
			await this.load()
			this.displayedRows = this.agGrid.api.getDisplayedRowCount()
		}
	}

	// ------------------------------------ Grid Methods
	async onGridReady(params: GridReadyEvent) {
		await this.load()
		this.agGrid.api.sizeColumnsToFit()	
		// not sure why I can't fit this 
		const me = this.auth.getUser()
		this.agGrid.gridOptions.isRowSelectable = (node) => node.data._id !== me._id
		this.agGrid.gridOptions.pagination = true
		this.agGrid.gridOptions.paginationAutoPageSize = true
	}

	async onSelectionChanged(params: SelectionChangedEvent) {
		const selected = params.api.getSelectedNodes()
		this.selected = selected.map(row => row.data)
	}

	async onCellClicked(e: CellClickedEvent) {
		const modal = await this.modalCtl.create({
			component: AdminUserDetailsModal,
			componentProps: { user: e.data }
		})
		await modal.present()
	}

	// ------------------------------------ Other
	filterUsers(event) {
		const value = event.detail.value.toLowerCase()
		const fields = this.cols.map(def => def.field)
		this.agGrid.api.setRowData(this.users.filter(u => {
			return fields.find(field => typeof _.get(u, field) === 'string' ? _.get(u, field).includes(value) : false)
			// return fields.find(field => field !== 'createdAt' ? _.get(u, field).includes(value) : fmtDate(u[field]).includes(value))
		}))
		this.displayedRows = this.agGrid.api.getDisplayedRowCount()
	}

	getUserCreatedAt(user: IUser) {
		return moment(getCreatedDate(user._id)).format('MM/DD/YY hh:mma')
	}
}