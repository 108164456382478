import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core";
import { NavController } from "@ionic/angular";
import { IAddress, IProperty } from "src/app/interfaces/property.interface";
import { PropertyService } from "src/app/services/property.service";
import { PropertyService2 } from "src/app/services/property2.service";

@Component({
  selector: "autocomplete-address",
  templateUrl: "./autocomplete-address.component.html",
  styleUrls: ["./autocomplete-address.component.scss"],
  providers: [PropertyService],
})
export class AutocompleteAddress {
  formattedAddress = "";
  options = {};
  property = {};
  searchTxt = ""; // keep track of when to display the clear button
  errorMsg = "";
  loading = false;

  //
  @Output() propertyEvent = new EventEmitter<IProperty>();
  @Output() addressEvent = new EventEmitter<IAddress>();
  @Output() addressEvent2 = new EventEmitter<any>();

  constructor(
    // public propertyService: PropertyService
    public propertyService: PropertyService2,
    public nav: NavController // this is added for v2
  ) {}

  public getInput() {
    return document.getElementById("autosearch") as HTMLInputElement;
  }

  public async AddressChange(address: any) {
    this.formattedAddress = address.formatted_address;
    console.log("AUTOCOMPLETE: AddressChange: ", address);

    // phase one: get the street address, address 2 (if needed), city, state, postal code
    const streetNum = address.address_components.find((a) =>
      a.types.includes("street_number")
    );
    const streetName = address.address_components.find((a) =>
      a.types.includes("route")
    );
    const apt = address.address_components.find((a) =>
      a.types.includes("subpremise")
    );
    const city = address.address_components.find(
      (a) => a.types.includes("locality") && a.types.includes("political")
    );
    const state = address.address_components.find((a) =>
      a.types.includes("administrative_area_level_1")
    );
    const country = address.address_components.find((a) =>
      a.types.includes("country")
    );
    const zip = address.address_components.find((a) =>
      a.types.includes("postal_code")
    );

    console.log("FORMATTED ADDRESS: ", address.formatted_address);
    console.log("STREET NUMBER: ", streetNum);
    console.log("STREET NAME: ", streetName);
    console.log("APT: ", apt);
    console.log("CITY: ", city);
    console.log("STATE: ", state);
    console.log("COUNTRY: ", country);
    console.log("ZIP: ", zip);

    const addressObj =
      streetNum && streetName && apt && city && state && country && zip
        ? {
            addressLine1: `${streetNum.short_name} ${streetName.short_name}`,
            addressLine2: apt ? apt.short_name : "",
            city: city.short_name,
            state: state.short_name,
            zip: zip.short_name,
            formatted_address: address.formatted_address,
          }
        : {};

    // new approach lets prcessing happen on parent
    this.addressEvent2.emit(address);

    // original implementation
    // this.loading = true;
    // try {
    //   console.log("AutoCompleteAddress:trying TovoData");
    //   if (window.location.href.includes("/v2/add")) {
    //     console.warn("find way to fit this in propertyEvent emitter");
    //     const { zpid } = await this.propertyService.searchZillow(addressObj);
    //     this.loading = false;
    //     this.nav.navigateForward(`/v2/add/${zpid}`);
    //     return;
    //   }
    //   // const d = await this.propertyService.searchTovoData(addressObj).toPromise()
    //   const d = await this.propertyService.search(addressObj);
    //   console.log("--- the property data = ", d);
    //   if (d.property.zillowData.zpid) {
    //     this.nav.navigateForward(`add/${d.property.zillowData.zpid}`);
    //   }
    //   this.propertyEvent.emit(d.property);
    //   this.addressEvent.emit(addressObj);
    //   this.loading = false;
    //   return;
    // } catch (err) {
    //   console.log("Error searching through Tovo: ", err);
    //   console.error(err, err.error.message);
    //   this.errorMsg = err.error.message;
    //   const input = this.getInput();
    //   input.style.border = "solid 1px #cc0000";
    //   this.loading = false;
    // }

    // will get valid properties... some quirks but the know-how is there,
    // will want to rethink and
    // try {
    //   console.log("AutoCompleteAddress:trying Realty")
    //   const d2 = await this.propertyService.searchRealty(address.formatted_address)
    //   console.log("--- D2 = ", d2)
    //   this.propertyEvent.emit(d2)
    //   this.addressEvent.emit(addressObj)
    //   this.loading = false
    //   return
    // } catch (e) {
    //   console.log("Error searching through Realty: ", e)
    // }
  }

  public resetInput() {
    const input = this.getInput();
    input.style.border = "solid 1px #ced4da";
    this.errorMsg = "";
  }

  // helper to display the clear button
  public onChange(event) {
    this.searchTxt = event.target.value;
    if (this.errorMsg) {
      this.resetInput();
    }
  }

  public clear() {
    this.getInput().value = "";
    this.searchTxt = "";
    this.resetInput();
    this.propertyEvent.emit(null);
    this.addressEvent.emit(null);
  }
}
