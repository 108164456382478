import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ToastController } from "@ionic/angular";
import { linkValidator } from "src/app/components/leadgen-email-form/leadgen-email-form.component";
import { MESSAGE_2 } from "src/app/components/share-valu-modal/share-valu-constants";
import { IUser, UserLeadgenTemplate } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import _ from "lodash";

const DEFAULT_TEMPLATE = (user: IUser): UserLeadgenTemplate => ({
  _id: null,
  name: "Default",
  subject: `See your home's value!`,
  content: MESSAGE_2(user),
  isDefault: true,
});

@Component({
  selector: "template-editor",
  templateUrl: "template-editor.component.html",
  styleUrls: ["template-editor.component.scss"],
})
export class TemplateEditor implements OnInit {
  @Input() initialTemplate: UserLeadgenTemplate;
  @Input() templates: UserLeadgenTemplate[];
  @Input() onSave: (tpl: UserLeadgenTemplate) => Promise<void>;

  public templateForm = this.formBuilder.group({
    _id: [""],
    name: ["", Validators.required],
    subject: ["", Validators.required],
    content: ["", [Validators.required, linkValidator()]],
    isDefault: [true],
  });
  public loading = {
    saveAndUpdate: false,
    saveAsNew: false,
    saveAsOneTime: false,
  };

  public template: UserLeadgenTemplate;

  constructor(
    public authService: AuthService,
    public formBuilder: FormBuilder,
    public userService: UserAdminService,
    public toastCtl: ToastController
  ) {}

  async ngOnInit() {
    this.selectTemplate(this.initialTemplate);
    setTimeout(() => {
      const item = $(`#tpl-editor-btn-${this.initialTemplate._id}`);
      item.parent().scrollLeft(item.offset().left);
    }, 100);
  }

  async showToast(message) {
    const toast = await this.toastCtl.create({
      message,
      position: "top",
      duration: 3000,
    });
    await toast.present();
  }

  selectTemplate(template: UserLeadgenTemplate) {
    this.template = template;
    this.templateForm.setValue(template);
    this.templateForm.updateValueAndValidity();
  }

  get formIsSavable() {
    if (this.templateForm.invalid) {
      return false;
    }
    return (
      this.template.name === "Custom" ||
      !_.isEqual(this.template, this.templateForm.value)
    );
  }

  async saveTemplate() {
    this.loading = { ...this.loading, saveAndUpdate: true };
    try {
      const res = await this.userService.saveTemplate(
        this.authService.getUser()._id,
        this.templateForm.value
      );
      console.log("res: ", res);
      if (res.template) {
        await this.onSave(res.template);
      }
      // await this.onSave(res.);
    } catch (e) {
      console.error("Error saving template: ", e);
      await this.showToast("Error saving template");
    }
    this.loading = { ...this.loading, saveAndUpdate: false };
  }
}
