import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastController } from "@ionic/angular";
import * as moment from "moment";
import * as _ from "lodash";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { UserTable } from "../../components/user-table/user-table.component";
import { FormBuilder, Validators } from "@angular/forms";
import { ValidationService } from "src/app/services/validation.service";

@Component({
  selector: "admin-user-mgmt",
  templateUrl: "admin-user-mgmt.page.html",
  styleUrls: ["admin-user-mgmt.page.scss"],
})
export class AdminUserMgmt implements OnInit {
  @ViewChild("userTable") userTable: UserTable;
  adminInvite = this.fb.group({
    firstname: ["", Validators.required],
    lastname: ["", Validators.required],
    email: [
      "",
      {
        validators: [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), // Validator.email allows a@b
        ],
        asyncValidators: [this.validationService.emailAvailability()],
        updateOn: "change",
      },
    ],
    phone: [""],
    brokerage: [""],
    tier: ["LAUNCH", [Validators.required]],
    // coupon: [null],
    // not sure if i want these here.. but it works
    account_lead: [true, [Validators.required]],
    brokerage_code: [null],
    freeTrialDays: [30, [Validators.required]],
    requireCreditCard: [false],
  });
  sendingInvite = false;

  constructor(
    public userService: UserAdminService,
    public auth: AuthService,
    public toastCtl: ToastController,
    public fb: FormBuilder,
    public validationService: ValidationService
  ) {}

  async ngOnInit() {
    $(document).on("shown.bs.modal", ".modal", function () {
      $(".modal-backdrop").before($(this));
    });
  }

  private async showToast(message) {
    const toast = await this.toastCtl.create({
      message,
      duration: 3000,
      position: "top",
    });
    toast.present();
  }

  async load() {}

  get selectedNodeCount() {
    return this.userTable.selected.length;
  }

  async inviteLead() {
    this.sendingInvite = true;
    try {
      await this.userService.inviteAccountLead(this.adminInvite.value);
      await this.showToast(
        `Successfully sent invite to ${this.adminInvite.value.email}`
      );
      $("#inviteUserBtn").trigger("click");
      await this.userTable.load();
      this.adminInvite.reset();
    } catch (e) {
      console.error("Error sending invite: ", e);
      await this.showToast("Error sending invite");
    }
    this.sendingInvite = false;
  }
}
