import { Component, ViewChild } from "@angular/core";

import {
  Platform,
  NavController,
  Events,
  ToastController,
  MenuController,
  IonNav,
  ModalController,
} from "@ionic/angular";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Pages } from "./interfaces/pages";

import { AuthService } from "./services/auth.service";
import { AppService } from "./services/appservice.service";

import _ from "lodash";
import { ScriptService } from "./services/script.service";
import { Subscriber } from "rxjs";

// managed by build scripts.
import { AppInfoService } from "src/app/services/appinfo.service";
import { PaywallModal } from "./v2/paywall/paywall";

/**
 * @NOTE the application automatic rerouting happens in this page
 */

export interface SecurePages extends Pages {
  roles?: Array<string>;
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @ViewChild(IonNav) nav: IonNav;

  showMenu = false;
  v2 = window.location.href.includes("/v2");
  needsPaymentUpdate = false;

  public appPages: Array<SecurePages> = [];
  public user: any = {
    firstname: "none",
    lastname: "none",
    roles: [],
  };

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    public auth: AuthService,
    public events: Events,
    public toastController: ToastController,
    public appService: AppService,
    public menuCtrl: MenuController,
    public scriptService: ScriptService,
    public appInfoService: AppInfoService,
    // only for the paywall
    public modalController: ModalController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(
      () => {
        this.statusBar.styleDefault();
        this.menuCtrl.close();
        this.menuCtrl.enable(false, "main");
        // commented out 2-22-23: not using square anymore
        // this.auth.getSquareWebToken().subscribe(webToken => {
        //   this.scriptService.load('square-' + webToken.squareEnv);
        // });
        this.splashScreen.hide();
      },
      (err) => {
        console.log("error bootstraping application: ", err);
      }
    );

    this.events.subscribe("user:logout", () => {
      this.appPages = [];
      this.navCtrl.navigateRoot("");
    });

    this.events.subscribe("user:profile", (user: any) => {
      this.user = user;
      // do this after login so we get the security applied.
      this.loadMenu(user);
    });

    //// events.publish('service.exception', err);
    this.events.subscribe("service.exception", (err: any) => {
      // this.error = err;
      if (this.appInfoService.svcVersion.online) {
        return this.presentErrors(this.getErrMsg(err));
      }
    });

    if (window.location.pathname.includes("guest/")) {
      return console.log("Not checking authentication");
    }
    this.auth.isAuthenticated().subscribe(async (loggedin: Boolean) => {
      if (loggedin) {
        // this may need to be moved to initializeApp
        const { status, checkoutSession, latestInvoice, paymentMethods } =
          await this.auth.getPaymentStatus();
        this.needsPaymentUpdate = !!checkoutSession;

        if (this.needsPaymentUpdate) {
          // window.location = checkoutSession.url;
          const paywall = await this.modalController.create({
            component: PaywallModal,
            backdropDismiss: false,
            componentProps: {
              checkoutSessionUrl: checkoutSession.url,
              needsNewSubscription: !status,
              latestInvoice,
              paymentMethods: paymentMethods.data,
            },
          });
          await paywall.present();
        }

        if (window.location.href.includes("/success")) {
          // alert("here!!")
          await this.auth.refreshtoken();
          return;
        }

        // NOTE: if you want to hide the menu you can do that here, if the user is logged in but not active
        if (this.user.inactive) {
          console.log("- not loading the menu for unauthorized user...");
        }
        // TESTING: new property page should not have the nav bar forced in them
        // if (window.location.href.includes("/property")) {
        //   return this.menuCtrl.enable(false);
        // } else {
        //   this.menuCtrl.enable(true, "main");
        // }
        // update 12-18-23: enable the menu if the user is logged in so they don't get stuck in loading state
        this.menuCtrl.enable(true, "main");

        const url = window.location.pathname;
        const user = this.auth.getUser();
        // const user = await this.auth.reloadUser()
        if (
          url.includes("/property/") ||
          url.includes("edit-profile") ||
          url.includes("subscription") ||
          url.includes("activate") ||
          url.includes("guest") ||
          url.includes("/add")
        ) {
          console.log("Not rerouting page");
        } else if (url.includes("v2")) {
          // alert("im here!");
          this.menuCtrl.enable(false, "main");
        } else if (user.inactive) {
          // todo: find the step that's not completed and go there
          this.navCtrl.navigateForward("register?resume=true");
        } else {
          console.log("Rerouting back home");
          this.navCtrl.navigateRoot("home");
        }
      } else {
        this.menuCtrl.enable(false, "main");

        console.log("--- HREF = ", window.location.href);
        // if (!window.location.href.includes('/success')) {
        if (!window.location.href.includes("/activate")) {
          console.log("-- logging out");
          this.auth.signOut();
        } else {
          console.log("--- do oyur thing");
        }
      }
    });
  }

  private getErrMsg(error) {
    if (error.name === "HttpErrorResponse") {
      const err = error.error ? error.error : error;
      return err.message.error ? err.message.error : err.message;
    } else if (error.statusCode != null) {
      return error.message.error ? error.message.error : error.message;
    } else {
      return error.message ? error.message : error.error;
    }
  }

  goToEditProgile() {
    this.navCtrl.navigateForward("edit-profile");
  }

  logout() {
    this.menuCtrl.enable(false, "main");
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.navCtrl.navigateRoot("");
  }

  async presentErrors(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 6000,
      position: "top",
    });
    toast.present();
  }

  applyMenuSecurity(user, menuItems: Array<SecurePages>) {
    return menuItems.filter(function (menuItem) {
      return (
        !menuItem.roles ||
        menuItem.roles.length < 1 ||
        _.intersection(menuItem.roles, user.roles).length > 0
      );
    });
  }

  loadMenu(user) {
    if (user.inactive) {
      return;
    }
    this.menuCtrl.enable(true, "main");

    const tabs = [
      {
        title: "Home",
        url: "/home",
        direct: "root",
        icon: "home",
      },
      {
        title: "Add Property",
        url: "/add",
        direct: "forward",
        icon: "add",
      },
      {
        title: "Organization Admin",
        url: "/admin/org",
        direct: "forward",
        icon: "person",
        roles: ["admin"],
      },
      {
        title: "Feature VALUmodifier Admin",
        url: "/admin/lboadmin",
        direct: "forward",
        icon: "cog",
        roles: ["admin"],
      },
      {
        title: "Admin Property Repair",
        url: "/admin/property",
        direct: "forward",
        icon: "hammer",
        roles: ["admin"],
      },
      {
        title: "Admin User Management",
        url: "/admin/users",
        direct: "forward",
        icon: "body",
        roles: ["admin"],
      },
    ];

    // update 3.28.23 - transition into team account is natural
    // if (user.account_lead && !['BETA', 'INDIVIDUAL'].includes(user.tier)) {
    // update 12.27.23 - turning off the multi accounts for now
    // if (user.account_lead) {
    //   tabs.push({
    //     title: "Team Management",
    //     url: "/team",
    //     direct: "forward",
    //     icon: "people",
    //   });
    // }

    // ensure it's the last tab
    tabs.push({
      title: "Quick Calc",
      url: "simulator",
      direct: "forward",
      icon: "calculator",
    });

    this.appPages = this.applyMenuSecurity(user, tabs);
  }

  disableMenu(url) {
    return window.location.pathname.startsWith(url);
  }

  reload() {
    return window.location.href;
  }
}
