import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'input-cma-modal',
	templateUrl: 'input-cma-modal.component.html',
	styleUrls: ['input-cma-modal.component.html']
})

export class InputCmaModal implements OnInit {
	constructor() { }

	ngOnInit() { }
}