import { Component, Input, NgModule } from "@angular/core";
import { NavParams, ModalController } from '@ionic/angular';
import { PropertyService } from "../../services/property.service";
import { ToastController } from '@ionic/angular';
import { IProperty } from "src/app/interfaces/property.interface";
import { element } from "protractor";

@Component({
  selector: 'property-details-modal',
  templateUrl: 'property-details-modal.html',
  styleUrls: ['./property-details-modal.scss'],
})

export class PropertyDetailsModal {

  public property: IProperty;
  private address = {
    address: null,
    state: null,
    country: null,
    city: null,
    postalCode: null
  };

  constructor(
    public viewCtrl: ModalController,
    public params: NavParams,
    public propertyService: PropertyService,
    public toastCtrl: ToastController
  ) {
    this.property = params.get("property");
    try {
      if (this.property.tovoData.results.propertyAddress) {
        const tovoAddress = this.property.tovoData.results.propertyAddress
        this.address = {
          address: tovoAddress.fullAddress,
          state: tovoAddress.stateProvince,
          country: 'USA', // excited for when this hardcoding becomes a problem
          city: tovoAddress.city,
          postalCode: tovoAddress.zipCode5
        }
      }
    } catch (e) {
      console.error("error formatting address: ", e)
      this.address = {
        address: this.property.StreetNumberNumeric + ' ' + this.property.StreetName + ' ' + this.property.City + ', ' + this.property.StateOrProvince + ' ' + this.property.PostalCode,
        state: this.property.StateOrProvince,
        country: 'USA',
        city: this.property.City,
        postalCode: this.property.PostalCode
      }
    }
  }

  addProperty() {
    console.log('params: ', this.property);

    this.propertyService.save(this.property).subscribe((property) => {
      this.close();
      this.toastCtrl.create({
        message: 'Property has been added Successfully!',
        duration: 3000,
        position: 'top'
      }).then((ctl) => {
        ctl.present();
      });
    });
  }

  close() {
    this.viewCtrl.dismiss({
      'dismissed': true
    });
  }

  getDescription(property) {
    return (property.PublicRemarks && property.PublicRemarks.left ? property.PublicRemarks.left(25) + '...' : property.PropertySubType);
  }

  claim() {
    this.propertyService.claim(this.property).subscribe((property) => {
      this.close();
      this.toastCtrl.create({
        message: 'Property has been added Successfully!',
        duration: 3000,
        position: 'top'
      }).then((ctl) => {
        ctl.present();
      });
    });
  }

}
